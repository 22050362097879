import { ParticipantRoles } from '@breathelife/types';

import { DynamicOptions } from '../../structure';
import FieldBlueprintBuilder, {
  ButtonFieldBuilder,
  ConsentFieldBuilder,
  DateFieldBuilder,
  InformationFieldBuilder,
  MoneyFieldBuilder,
  NumberFieldBuilder,
  PhoneFieldBuilder,
  TextFieldBuilder,
  YearMonthFieldBuilder,
  SignatureFieldBuilder,
  RadioFieldBuilder,
  DropdownFieldBuilder,
  CheckboxGroupFieldBuilder,
  DynamicDropdownFieldBuilder,
} from './FieldBuilder';
import QuestionBuilder from './QuestionBuilder';
import BlueprintBuilder from './BlueprintBuilder';
import SectionBuilder from './SectionBuilder';
import { SeedBlueprintProviders } from './SeedProviders';
import SubsectionBuilder from './SubsectionBuilder';
import SectionGroupBuilder from './SectionGroupBuilder';

export type BlueprintBuilderFunctions = {
  aBlueprint: () => BlueprintBuilder;
  aSectionGroup: () => SectionGroupBuilder;
  aSection: () => SectionBuilder;
  aStep: () => SectionBuilder;
  aSubsection: () => SubsectionBuilder;
  aQuestion: () => QuestionBuilder;
  aRepeatableQuestion: ({ nodeId }: { nodeId: string }) => QuestionBuilder;
  aTextField: () => TextFieldBuilder;
  aDateField: () => DateFieldBuilder;
  aYearMonthField: () => YearMonthFieldBuilder;
  aConsentField: () => ConsentFieldBuilder;
  aNumberField: () => NumberFieldBuilder;
  aPhoneField: () => PhoneFieldBuilder;
  aMoneyField: () => MoneyFieldBuilder;
  aDropdownField: () => DropdownFieldBuilder;
  aRadioField: () => RadioFieldBuilder;
  aCheckboxGroupField: () => CheckboxGroupFieldBuilder;
  aDynamicDropdownField: (dynamicOptions: DynamicOptions) => FieldBlueprintBuilder;
  anInformationField: () => InformationFieldBuilder;
  aButtonField: () => ButtonFieldBuilder;
  aSignatureField: (participantRole: ParticipantRoles) => SignatureFieldBuilder;
};

export function generateBlueprintBuilders(SeedBlueprintProviders: SeedBlueprintProviders): BlueprintBuilderFunctions {
  return {
    aBlueprint(): BlueprintBuilder {
      return new BlueprintBuilder(SeedBlueprintProviders);
    },

    aSectionGroup(): SectionGroupBuilder {
      return new SectionGroupBuilder(SeedBlueprintProviders);
    },

    aSection(): SectionBuilder {
      return new SectionBuilder(SeedBlueprintProviders);
    },

    aStep(): SectionBuilder {
      return new SectionBuilder(SeedBlueprintProviders);
    },

    aSubsection(): SubsectionBuilder {
      return new SubsectionBuilder(SeedBlueprintProviders);
    },

    aQuestion(): QuestionBuilder {
      return new QuestionBuilder(SeedBlueprintProviders);
    },

    aRepeatableQuestion({ nodeId }: { nodeId: string }): QuestionBuilder {
      return new QuestionBuilder(SeedBlueprintProviders).makeRepeatable({ nodeId });
    },

    aTextField(): TextFieldBuilder {
      return new TextFieldBuilder(SeedBlueprintProviders);
    },

    aDateField(): DateFieldBuilder {
      return new DateFieldBuilder(SeedBlueprintProviders);
    },
    aYearMonthField(): YearMonthFieldBuilder {
      return new YearMonthFieldBuilder(SeedBlueprintProviders);
    },

    // TODO @questionnaireBuilder Should this be type AgreeField?
    aConsentField(): ConsentFieldBuilder {
      return new ConsentFieldBuilder(SeedBlueprintProviders);
    },

    aNumberField(): NumberFieldBuilder {
      return new NumberFieldBuilder(SeedBlueprintProviders);
    },

    aPhoneField(): PhoneFieldBuilder {
      return new PhoneFieldBuilder(SeedBlueprintProviders);
    },

    aMoneyField(): MoneyFieldBuilder {
      return new MoneyFieldBuilder(SeedBlueprintProviders);
    },

    aRadioField(): RadioFieldBuilder {
      return new RadioFieldBuilder(SeedBlueprintProviders);
    },

    aDropdownField(): DropdownFieldBuilder {
      return new DropdownFieldBuilder(SeedBlueprintProviders);
    },

    aCheckboxGroupField(): CheckboxGroupFieldBuilder {
      return new CheckboxGroupFieldBuilder(SeedBlueprintProviders);
    },

    aDynamicDropdownField(dynamicOptions: DynamicOptions): FieldBlueprintBuilder {
      return new DynamicDropdownFieldBuilder(SeedBlueprintProviders).withDynamicOptions(dynamicOptions);
    },

    anInformationField(): InformationFieldBuilder {
      return new InformationFieldBuilder(SeedBlueprintProviders);
    },

    aButtonField(): ButtonFieldBuilder {
      return new ButtonFieldBuilder(SeedBlueprintProviders);
    },

    aSignatureField(participantRole: ParticipantRoles): SignatureFieldBuilder {
      return new SignatureFieldBuilder(SeedBlueprintProviders, participantRole);
    },
  };
}

export { blueprintBuilderFunctions } from './BuildersWithSeed';
