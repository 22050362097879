import _ from 'lodash';

import { DataLabel } from '@breathelife/meta-cruncher';
import {
  Conditions,
  PdfDocumentType,
  EngineEffects,
  ParticipantRoles,
  FieldLayout,
  IconName,
  InsuranceModule,
  IsoCountryCode,
  Localizable,
  OptionSize,
  PlatformType,
  Query,
  RenderingType,
  SubsectionVariant,
  FieldTypes,
  ApplicationMode,
  QuestionnaireBlueprintCopyableOption,
  NodeId,
} from '@breathelife/types';

import { ValidityRule } from './nodeEvaluation';
import { DefaultIfRule } from './nodeEvaluation/defaultIf/defaultIfRule';
import { Validations } from './validations';
import { Localized } from './locale';

export type QuestionnairePartNode = SectionGroup | RepeatableSectionGroup | Section | Subsection | Question | Field;

export type QuestionnaireDefinition = SectionGroup[];

export type LocalizedQuestionnaire = Localized<SectionGroup>[];

export type VisibleIf = {
  visibleIf?: Conditions;
};

type ReadOnly = {
  readOnly?: boolean;
};

export type WithEffects = {
  effects?: EngineEffects;
};

export type BaseNode = VisibleIf & {
  id: string;
  referenceLabel?: string;
  platformTypeFilter?: PlatformType[];
  dataLabel?: DataLabel;
  title?: Localizable;
  text?: Localizable;
};

type RepeatableNode = BaseNode & {
  nodeId: string;
  blueprintId: string;
  // When readOnly is true, parent visibility (or other related logic) will be unaffected by logic defined at this node
} & ReadOnly;

export type SectionGroup = BaseNode &
  WithEffects & {
    index: string;
    blueprintId: string;
    title: Localizable;
    renderingTypeFilter?: RenderingType[];
    sections: Section[];
    options?: RepeatableOptions;
    referenceLabel?: string;
  };

export type Section = BaseNode & {
  index: string;
  blueprintId: string;
  title: Localizable;
  renderingTypeFilter?: RenderingType[];
  // TODO: insuranceModulesFilter should be changed to mandatory
  insuranceModuleFilter?: InsuranceModule[];
  subsections: Subsection[];
  documentTypes: PdfDocumentType[];
  iconName?: IconName;
};

type BaseSubsection = BaseNode & {
  index: string;
  blueprintId: string;
  title: Localizable;
  variant?: SubsectionVariant;
  renderingTypeFilter?: RenderingType[];
  questions: Question[];
  iconName?: IconName;
  nextStepButtonText?: Localizable;
  showInNavigation?: boolean;
  pageBreakSubSectionInPdf?: boolean;
};

// TODO: Refactor the quoter step in the consumer flow to replace the hardcoded node ids with the following properties for nodeIds below, to be read in the questionnaire itself
type QuoterVariantOptions =
  | {
      simpleQuoter: false;
      coverageAmountNodeId: string;
      productNodeId: string;
    }
  | {
      simpleQuoter: true;
      coverageAmountNodeId: string;
      productNodeId: string;
      useNeedsAnalysisNodeId: string;
    };

export type QuoterSubsection = BaseSubsection & {
  variant: SubsectionVariant.quoter;
  selfServeBlockedIf: Conditions;
  variantOptions: QuoterVariantOptions;
};

export type BlockingSubsection = BaseSubsection & {
  blockedIf: Conditions;
};

export enum SubmissionSubsectionLayout {
  flow = 'flow',
}

type SubmissionSubsection = BaseSubsection & {
  variant: SubsectionVariant.submission;
  variantOptions: {
    layout?: SubmissionSubsectionLayout;
    hideHeader?: boolean;
  };
};

export type Subsection = BaseSubsection | QuoterSubsection | BlockingSubsection | SubmissionSubsection;

export type SubsectionVariantOptions = QuoterSubsection['variantOptions'] | SubmissionSubsection['variantOptions'];

export type BaseQuestion = BaseNode & {
  blueprintId: string;
  options?: RepeatableOptions;
  renderingTypeFilter?: RenderingType[];
  fields: Field[];
  showInPdfWithoutFields?: boolean;
  displayAsCard?: boolean;
};

export type RepeatableSectionGroup = SectionGroup &
  WithEffects &
  RepeatableNode & {
    options: RepeatableOptions;
  };

export type RepeatableQuestion = BaseQuestion & WithEffects & RepeatableNode & RepeatableQuestionOptions;

export type RepeatableQuestionOptions = {
  options: RepeatableOptions;
  addQuestionButtonText: Localizable;
  removeQuestionButtonText: Localizable;
};

export type Question = BaseQuestion | RepeatableQuestion;

export type ComputedValueNodeWithReadOnlyProperty = {
  computedValue: Query;
  readOnly: true; // computedValue fields must always be readOnly.
};

// This ensures that defining a computedValue requires the "readOnly" property to be defined as well
type ComputedValueNode = ComputedValueNodeWithReadOnlyProperty | { computedValue?: undefined };

export type InfoSupplement = {
  title?: Localizable;
  text: Localizable;
  image?: {
    name: string;
    alt?: string;
  };
  modalOptions?: {
    forceModal?: boolean;
    fullSizeOnMobile?: boolean;
  };
  iconNumber?: 1 | 2;
};

export type BaseField = BaseNode &
  WithEffects &
  ComputedValueNode & {
    nodeId?: string;
    blueprintId: string;
    relatesTo?: string;
    type: FieldTypes;
    validation: { type: Validations };
    info?: InfoSupplement;
    label?: Localizable;
    layout?: FieldLayout;
    optional?: boolean;
    disabled?: boolean;
    validIf?: ValidityRule<Localizable>[];
    defaultIf?: DefaultIfRule[];
    defaultValue?: any;
    renderingTypeFilter?: RenderingType[];
    PHI?: boolean;
    encrypted?: boolean;
    iconName?: IconName;
    triggerStepNavigation?: boolean;
    displayInCardPreview?: boolean;
    applicationModes?: ApplicationMode[];
    copyable?: QuestionnaireBlueprintCopyableOption;
  };

type ReadOnlyField = BaseField & ReadOnly;

export type PlaceholderField = BaseField & {
  placeholder?: Localizable;
};

export type TextField = PlaceholderField & {
  symbol?: FieldSymbol;
};

export type NumberField = TextField & {
  type: FieldTypes.number;
  numericalDataType?: NumericalDataType;
};

export type NumericalDataType = 'float' | 'integer';

export type OptionWidth = keyof typeof OptionSize;

export type OptionField = PlaceholderField & {
  options: SelectOption[];
  optionsFromApplicationContext?: ApplicationContextSelectOptionsConfig;
  displayOnlySelected?: boolean;
  searchable?: boolean;
};

export type DynamicOptionField = OptionField & {
  dynamicOptions: DynamicOptions;
};

export type DynamicOptions = VisibleIf & {
  collection: NodeId;
  select: NodeId[];
  useLocalIdentifiers?: boolean;
};

export type RadioField = OptionField & {
  optionSize?: OptionWidth;
};

export type AgreeField = BaseField & {
  title: Localizable;
  label: Localizable;
  confirmedLabel: Localizable;
  modalHeader: Localizable;
  modalText: Localizable;
};

export type ButtonField = BaseField & {
  buttonText: Localizable;
};

export type AutocompleteField = PlaceholderField & {
  countryCode: IsoCountryCode;
  useAutocompleteGeolocalization?: boolean;
  nodeIdsToUpdate: {
    autocomplete?: string;
    streetAddress?: string;
    city?: string;
    provinceOrState?: string;
    postalOrZipCode?: string;
  };
};

type CurrencyCardField = BaseField & { readOnly: true; computedValue: Query };

export type InformationField = BaseField &
  VisibleIf &
  ReadOnly & {
    variant: InfoVariants;
  };

export type SignatureField = BaseField &
  Required<ReadOnly> & {
    participantRole: ParticipantRoles;
  };

export type Field =
  | TextField
  | NumberField
  | RadioField
  | OptionField
  | DynamicOptionField
  | AgreeField
  | ButtonField
  | AutocompleteField
  | InformationField
  | CurrencyCardField
  | SignatureField;

export function isTextFieldType(type: FieldTypes): boolean {
  const textFieldTypes = [
    FieldTypes.input,
    FieldTypes.number,
    FieldTypes.money,
    FieldTypes.phone,
    FieldTypes.textarea,
    FieldTypes.date,
    FieldTypes.yearMonth,
  ];
  return textFieldTypes.includes(type);
}

export function isField(field: BaseNode | Localized<BaseNode>): field is BaseField {
  return !!(field as BaseField).type;
}

export function isOptionField(field: Field): field is OptionField;
export function isOptionField(field: Localized<Field>): field is Localized<OptionField>;
export function isOptionField(field: Field | Localized<Field>): boolean {
  return (
    !_.isEmpty((field as OptionField).options) &&
    (field.type === FieldTypes.radio || field.type === FieldTypes.checkboxGroup || field.type === FieldTypes.dropdown)
  );
}

export function isOptionFieldFromApplicationContext(field: Field): field is OptionField {
  return !!(field as OptionField).optionsFromApplicationContext;
}

export function isDropdownOptionField(field: Field | Localized<Field>): field is OptionField {
  return !_.isEmpty((field as OptionField).options) && field.type === FieldTypes.dropdown;
}

export function isDynamicOptionField(field: Field | Localized<Field>): field is DynamicOptionField {
  return !!(field as DynamicOptionField).dynamicOptions;
}

export function isReadOnlyField(field: Field | Localized<Field>): field is ReadOnlyField {
  return (field as ReadOnlyField).readOnly === true;
}

export function isAgreeField(field: Field | Localized<Field>): field is AgreeField {
  return (field as AgreeField).type === FieldTypes.agree;
}

export function isButtonField(field: Field | Localized<Field>): field is ButtonField {
  return (field as ButtonField).type === FieldTypes.button;
}

export function isPlaceholderField(field: Field | Localized<Field>): field is PlaceholderField {
  return !!(field as PlaceholderField).placeholder;
}

export function isBlockingSubsection(subsection: Subsection | Localized<Subsection>): subsection is BlockingSubsection {
  return !!(subsection as BlockingSubsection).blockedIf;
}

export function isSectionGroupRepeatable(
  sectionGroup: SectionGroup | Localized<SectionGroup>,
): sectionGroup is RepeatableSectionGroup {
  return !!sectionGroup.options?.repeatable;
}

export function isQuestion(question: BaseNode | Localized<BaseNode>): question is Question {
  return !!(question as Question).fields;
}

export function isQuestionRepeatable(question: Localized<Question>): question is Localized<RepeatableQuestion>;
export function isQuestionRepeatable(question: Question): question is RepeatableQuestion;
export function isQuestionRepeatable(question: Question | Localized<Question>): boolean {
  return isQuestion(question) && !!question.options?.repeatable;
}

export function isQuoterSubsection(subsection: Subsection): subsection is QuoterSubsection {
  return subsection.variant === SubsectionVariant.quoter;
}

export type SelectOption = BaseNode & {
  text: Localizable;
  iconName?: string;
  checked?: boolean;
  disabled?: boolean;
  info?: InfoSupplement;
  orderingIndex?: number;
};

export type ApplicationContextSelectOptionsConfig = {
  tag: string;
  labelKey: Partial<Localizable>;
  valuePath: string;
};

export type RepeatableOptions = RepeatableOptionsWithLimits | RepeatableOptionsBasedOnCollection;

export type RepeatableOptionsWithLimits = {
  repeatable: true;
  minRepetitions: number;
  maxRepetitions: number;
};

export type RepeatableOptionsBasedOnCollection = {
  repeatable: true;
  expandToCollectionLength: string;
  selectTitle?: string[];
  selectText?: string[];
};

export function isRepeatableOptionsWithLimits(
  repeatableOptions: RepeatableOptions,
): repeatableOptions is RepeatableOptionsWithLimits {
  return (repeatableOptions as RepeatableOptionsWithLimits).minRepetitions !== undefined;
}

export function isRepeatableOptionsBasedOnCollection(
  repeatableOptions: RepeatableOptions,
): repeatableOptions is RepeatableOptionsBasedOnCollection {
  return !!(repeatableOptions as RepeatableOptionsBasedOnCollection).expandToCollectionLength;
}

export type RepeatableQuestionnaireNode = RepeatableNode & {
  id: string;
  options: RepeatableOptions;
};

export enum FieldValueTypes {
  string = 'string',
  boolean = 'boolean',
  array = 'array',
}

export type FieldSymbol = {
  position?: 'start' | 'end';
  name: string;
};

// NOTE: Please update the README.md in shared/questionnaire-engine if this is modified.
export enum InfoVariants {
  warning = 'warning',
  error = 'error',
  success = 'success',
  info = 'info',
  paragraph = 'paragraph',
}

// readOnlyFieldsTypes refer to FieldTypes that can only be used as readOnly field.
// readOnly property needs to be set to true on the field when any of these FieldTypes is specified.
export const readOnlyFieldTypes = [FieldTypes.information, FieldTypes.currencyCard, FieldTypes.signature];
// "supportedReadOnlyFieldTypes" refer to FieldTypes that support the readOnly property set to true.
// For example, FieldTypes.input is allowed to be displayed as a readOnly field but also as a regular field and
// the field generator will display the input field as disabled when readOnly is set to true.
export const supportedReadOnlyFieldTypes = [...readOnlyFieldTypes, FieldTypes.input];
