import _ from 'lodash';

import { evaluateConditions, evaluateQuery } from '@breathelife/condition-engine';
import {
  BooleanOperator,
  InstanceScope,
  IAnswerResolver,
  Timezone,
  Conditions,
  BothNodeInstance,
} from '@breathelife/types';

import { ExpandedRepetitionsVisitor } from '../expandedContext/ExpandedRepetitionsVisitor';
import { VisibilityDependencyMap } from '../nodeEvaluation/visibleIf/dependencyMap';
import { filterVisibleAnswers } from '../nodeEvaluation/visibleIf/filterVisibleAnswers';
import { Field, QuestionnaireDefinition } from '../structure';
import { Localized } from '../locale';

class ComputedAnswersVisitor extends ExpandedRepetitionsVisitor {
  private visibilityDependencyMap: VisibilityDependencyMap;
  private changedNodeInstances: BothNodeInstance[] = [];
  private timezone: Timezone;
  readonly answersResolver: IAnswerResolver; // This is shadowing the base class property because it requires more permission here.
  private readonly currentDateOverride: string | null;

  constructor(
    answersResolver: IAnswerResolver,
    visibilityDependencyMap: VisibilityDependencyMap,
    timezone: Timezone,
    currentDateOverride: string | null,
  ) {
    super(answersResolver);
    this.answersResolver = answersResolver;
    this.visibilityDependencyMap = visibilityDependencyMap;
    this.timezone = timezone;
    this.currentDateOverride = currentDateOverride;
  }

  public setComputedAnswers(questionnaire: Localized<QuestionnaireDefinition>): void {
    this.visitQuestionnaire(questionnaire);
    return;
  }

  public getChangedNodeInstances(): BothNodeInstance[] {
    return _.uniqWith(this.changedNodeInstances, _.isEqual);
  }

  protected visitField(field: Localized<Field>): void {
    const { blueprintId, computedValue } = field;
    if (!computedValue) {
      return;
    }

    const scopes = this.repeatedInstanceIdentifiers();
    if (!this.isFieldVisible(field, scopes.byNodeId)) {
      return;
    }

    const computedFieldValue = evaluateQuery(
      computedValue,
      this.answersResolver,
      scopes.byNodeId,
      this.timezone,
      {},
      this.currentDateOverride,
    );

    const currentAnswer = this.answersResolver.getAnswer(blueprintId, scopes.byBlueprintId);
    if (computedFieldValue === currentAnswer) {
      return;
    }

    this.answersResolver.setAnswer(computedFieldValue, blueprintId, scopes.byBlueprintId);
    this.changedNodeInstances.push({
      byBlueprintId: {
        blueprintId: blueprintId,
        blueprintIdScope: scopes.byBlueprintId,
      },
      byNodeId: {
        nodeId: field.nodeId,
        nodeIdScope: scopes.byNodeId,
      },
    });
  }

  private isFieldVisible(field: Localized<Field>, scope: InstanceScope): boolean {
    let fieldVisibilityConditions = undefined;
    if (field.nodeId) {
      fieldVisibilityConditions = this.visibilityDependencyMap.getVisibilityConditions(field.nodeId);
    }

    let visibilityConditions: Conditions[] | undefined = undefined;
    if (fieldVisibilityConditions) {
      visibilityConditions = fieldVisibilityConditions?.field;
    }

    const isFieldVisible =
      !visibilityConditions ||
      evaluateConditions(
        { conditions: visibilityConditions, operator: BooleanOperator.or },
        this.answersResolver,
        scope,
        this.timezone,
        this.currentDateOverride,
      ).isValid;
    return isFieldVisible;
  }
}

export function computedQuestionnaireAnswers(
  questionnaire: Localized<QuestionnaireDefinition>,
  answersResolver: IAnswerResolver,
  dependencyMap: VisibilityDependencyMap,
  timezone: Timezone,
  currentDateOverride: string | null,
): void {
  const computedAnswersVisitor = new ComputedAnswersVisitor(
    answersResolver,
    dependencyMap,
    timezone,
    currentDateOverride,
  );

  computedAnswersVisitor.setComputedAnswers(questionnaire);

  // Setting computed answers may affect the visibility of other answers.
  for (const nodeInstance of computedAnswersVisitor.getChangedNodeInstances()) {
    if (nodeInstance.byNodeId.nodeId) {
      filterVisibleAnswers(
        nodeInstance.byNodeId.nodeId,
        dependencyMap,
        answersResolver,
        nodeInstance.byNodeId.nodeIdScope,
        timezone,
        currentDateOverride,
      );
    }
  }
}
