import _ from 'lodash';

import { Timezone, IAnswerResolver, ApplicationContext } from '@breathelife/types';

import { computedQuestionnaireAnswers } from './computedAnswers';
import { processSetValueEffects } from './effects';
import { formatAnswers } from './formatAnswers';
import { VisibilityDependencyMap } from './nodeEvaluation/visibleIf/dependencyMap';
import { filterVisibleAnswers } from './nodeEvaluation/visibleIf/filterVisibleAnswers';
import { QuestionnaireDefinition } from './structure';
import { Localized } from './locale';
import { AnswerChangeItem } from './answers';
import { defaultQuestionnaireAnswers } from './defaultAnswers';

export function updateAnswer(
  questionnaire: Localized<QuestionnaireDefinition>,
  answersResolver: IAnswerResolver,
  dependencyMap: VisibilityDependencyMap,
  item: AnswerChangeItem,
  timezone: Timezone,
  currentDateOverride: string | null,
  applicationContext: ApplicationContext = {},
): void {
  processSetValueEffects(
    item.effects?.beforeValueUpdate,
    item.value,
    answersResolver,
    dependencyMap,
    item.nodeIdScope, // Effects use nodeId because they use conditions.
    timezone,
    applicationContext,
    currentDateOverride,
  );

  if (item.value === undefined) {
    if (item.tag === 'blueprintId') {
      answersResolver.unsetAnswer(item.blueprintId, item.blueprintIdScope);
    } else {
      answersResolver.usingNodeId().unsetAnswer(item.nodeId, item.nodeIdScope);
    }
  } else {
    if (item.tag === 'blueprintId') {
      answersResolver.setAnswer(item.value, item.blueprintId, item.blueprintIdScope);
    } else {
      answersResolver.usingNodeId().setAnswer(item.value, item.nodeId, item.nodeIdScope);
    }
  }

  formatAnswers(questionnaire, answersResolver);

  // Changing this answer could affect the visibility of other answers.
  if (item.nodeId) {
    filterVisibleAnswers(item.nodeId, dependencyMap, answersResolver, item.nodeIdScope, timezone, currentDateOverride);
  }

  processSetValueEffects(
    item.effects?.afterValueUpdate,
    item.value,
    answersResolver,
    dependencyMap,
    item.nodeIdScope,
    timezone,
    applicationContext,
    currentDateOverride,
  );

  defaultQuestionnaireAnswers(questionnaire, answersResolver, dependencyMap, timezone, currentDateOverride);

  computedQuestionnaireAnswers(questionnaire, answersResolver, dependencyMap, timezone, currentDateOverride);
}
