import {
  InstanceIndex,
  InstanceScope,
  IAnswerResolver,
  NodeInstance,
  QuestionnaireBlueprint,
  RepeatedAnswersBySurrogateId,
  SurrogateId,
  VersionedAnswers,
  BlueprintId,
  BlueprintIdInstanceScope,
  NodeId,
  NodeIdInstanceScope,
  Answers,
  AnswersResolverLogger,
} from '@breathelife/types';
import { NodeIdAnswersResolver } from './NodeIdAnswersResolver';
import { BlueprintIdAnswersResolver } from './BlueprintIdAnswersResolver';
import { AnswerPath } from './AnswerPath';
import { Result } from '@breathelife/result';

// It writes in both answers format, but it can read from either one.
export class PivotAnswerResolver implements IAnswerResolver {
  private readonly answerResolverV1: NodeIdAnswersResolver; //{insured-first: {firstname: 'bob'}}}} // v1.usingNodeIds.getAnswer
  private readonly answerResolverV2: BlueprintIdAnswersResolver; // {uuid: {uuid {uuid: {uuid: 'bob'}}}} // v2.usingNodeIds.getAnswer

  private answersToUse: 'v1' | 'v2';

  constructor(
    answerResolverV1: NodeIdAnswersResolver,
    answerResolverV2: BlueprintIdAnswersResolver,
    answersToUse: 'v1' | 'v2',
  ) {
    this.answerResolverV1 = answerResolverV1;
    this.answerResolverV2 = answerResolverV2;
    this.answersToUse = answersToUse;
  }

  static from(
    versionedAnswers: VersionedAnswers,
    nodeIdToAnswerPath: Map<string, AnswerPath>,
    blueprint: QuestionnaireBlueprint,
    answersToUse: 'v1' | 'v2',
    logger?: AnswersResolverLogger,
  ): IAnswerResolver {
    const answerResolverV1 = NodeIdAnswersResolver.from(nodeIdToAnswerPath, blueprint, versionedAnswers.v1, logger);
    const answerResolverV2 = BlueprintIdAnswersResolver.from(blueprint, versionedAnswers.v2, logger);

    return new PivotAnswerResolver(answerResolverV1, answerResolverV2, answersToUse);
  }

  clone(withAnswers?: VersionedAnswers): IAnswerResolver {
    const clonedAnswerResolverV1 = this.answerResolverV1.clone(withAnswers);
    const clonedAnswerResolverV2 = this.answerResolverV2.clone(withAnswers);

    return new PivotAnswerResolver(clonedAnswerResolverV1, clonedAnswerResolverV2, this.answersToUse);
  }

  dump(): VersionedAnswers {
    return new VersionedAnswers({
      v1: this.answerResolverV1.dump().v1,
      v2: this.answerResolverV2.dump().v2,
    });
  }

  public knowsId(blueprintId: BlueprintId): boolean {
    if (this.answersToUse === 'v2') {
      return this.answerResolverV2.knowsId(blueprintId);
    } else {
      return this.answerResolverV1.knowsId(blueprintId);
    }
  }

  public getAnswer(
    blueprintId: BlueprintId,
    blueprintIdInstanceIdentifiers?: BlueprintIdInstanceScope,
    skipLeafIdentifier?: boolean,
  ): any | undefined {
    if (this.answersToUse === 'v2') {
      return this.answerResolverV2.getAnswer(blueprintId, blueprintIdInstanceIdentifiers, skipLeafIdentifier);
    } else {
      return this.answerResolverV1.getAnswer(blueprintId, blueprintIdInstanceIdentifiers, skipLeafIdentifier);
    }
  }

  public getCollection(
    blueprintId: BlueprintId,
    blueprintIdInstanceIdentifiers?: BlueprintIdInstanceScope,
  ): any[] | undefined {
    if (this.answersToUse === 'v2') {
      return this.answerResolverV2.getCollection(blueprintId, blueprintIdInstanceIdentifiers);
    } else {
      return this.answerResolverV1.getCollection(blueprintId, blueprintIdInstanceIdentifiers);
    }
  }

  public getRepeatedAnswers<T extends BlueprintId>(
    collectionBlueprintId: BlueprintId,
    blueprintIds: T[],
    blueprintIdInstanceIdentifiers: BlueprintIdInstanceScope,
  ): RepeatedAnswersBySurrogateId<T> | undefined {
    if (this.answersToUse === 'v2') {
      return this.answerResolverV2.getRepeatedAnswers(
        collectionBlueprintId,
        blueprintIds,
        blueprintIdInstanceIdentifiers,
      );
    } else {
      return this.answerResolverV1.getRepeatedAnswers(
        collectionBlueprintId,
        blueprintIds,
        blueprintIdInstanceIdentifiers,
      );
    }
  }

  public getRepetitionCount(
    collectionBlueprintId: BlueprintId,
    blueprintIdInstanceIdentifiers: BlueprintIdInstanceScope,
  ): number | undefined {
    if (this.answersToUse === 'v2') {
      return this.answerResolverV2.getRepetitionCount(collectionBlueprintId, blueprintIdInstanceIdentifiers);
    } else {
      return this.answerResolverV1.getRepetitionCount(collectionBlueprintId, blueprintIdInstanceIdentifiers);
    }
  }

  public setAnswer(
    value: unknown,
    blueprintId: BlueprintId,
    blueprintIdInstanceIdentifiers?: BlueprintIdInstanceScope,
    answersOverride?: Answers,
  ): void {
    this.answerResolverV1.setAnswer(value, blueprintId, blueprintIdInstanceIdentifiers, answersOverride);
    this.answerResolverV2.setAnswer(value, blueprintId, blueprintIdInstanceIdentifiers, answersOverride);
  }

  public unsetAnswer(blueprintId: BlueprintId, blueprintIdInstanceIdentifiers?: BlueprintIdInstanceScope): boolean {
    const hasRemovedSomethingV2 = this.answerResolverV2.unsetAnswer(blueprintId, blueprintIdInstanceIdentifiers);
    const hasRemovedSomethingV1 = this.answerResolverV1.unsetAnswer(blueprintId, blueprintIdInstanceIdentifiers);

    return hasRemovedSomethingV2 || hasRemovedSomethingV1;
  }

  public unsetAnswerSelectOptionId(
    blueprintId: BlueprintId,
    optionId: string,
    blueprintIdInstanceIdentifiers?: BlueprintIdInstanceScope,
  ): boolean {
    const hasUnsetSomethingInV2 = this.answerResolverV2.unsetAnswerSelectOptionId(
      blueprintId,
      optionId,
      blueprintIdInstanceIdentifiers,
    );
    const hasUnsetSomethingInV1 = this.answerResolverV1.unsetAnswerSelectOptionId(
      blueprintId,
      optionId,
      blueprintIdInstanceIdentifiers,
    );
    return hasUnsetSomethingInV2 || hasUnsetSomethingInV1;
  }

  public withCollectionIdentifier(
    identifiers: BlueprintIdInstanceScope,
    collectionBlueprintId: BlueprintId,
    collectionInstanceIndex: InstanceIndex,
  ): InstanceScope {
    if (this.answersToUse === 'v2') {
      return this.answerResolverV2.withCollectionIdentifier(
        identifiers,
        collectionBlueprintId,
        collectionInstanceIndex,
      );
    } else {
      return this.answerResolverV1.withCollectionIdentifier(
        identifiers,
        collectionBlueprintId,
        collectionInstanceIndex,
      );
    }
  }

  public removeUndefinedAnswersFromCollection(
    blueprintId: BlueprintId,
    blueprintIdInstanceIdentifiers?: BlueprintIdInstanceScope,
  ): boolean {
    const hasRemovedSomethingV2 = this.answerResolverV2.removeUndefinedAnswersFromCollection(
      blueprintId,
      blueprintIdInstanceIdentifiers,
    );

    const hasRemovedSomethingV1 = this.answerResolverV1.removeUndefinedAnswersFromCollection(
      blueprintId,
      blueprintIdInstanceIdentifiers,
    );

    return hasRemovedSomethingV2 || hasRemovedSomethingV1;
  }

  getInstanceId(
    blueprintId: BlueprintId,
    blueprintIdInstanceIdentifiers: BlueprintIdInstanceScope,
  ): Result<string, SurrogateId | undefined> {
    if (this.answersToUse === 'v2') {
      return this.answerResolverV2.getInstanceId(blueprintId, blueprintIdInstanceIdentifiers);
    } else {
      return this.answerResolverV1.getInstanceId(blueprintId, blueprintIdInstanceIdentifiers);
    }
  }

  setInstanceId(
    blueprintId: BlueprintId,
    blueprintIdInstanceIdentifiers: BlueprintIdInstanceScope,
    forceInstanceId?: SurrogateId | undefined,
  ): Result<string, SurrogateId> {
    if (this.answersToUse === 'v2') {
      return this.answerResolverV2
        .setInstanceId(blueprintId, blueprintIdInstanceIdentifiers, forceInstanceId)
        .andThen((surrogateIdReturned) => {
          return this.answerResolverV1.setInstanceId(blueprintId, blueprintIdInstanceIdentifiers, surrogateIdReturned);
        });
    } else {
      return this.answerResolverV1
        .setInstanceId(blueprintId, blueprintIdInstanceIdentifiers, forceInstanceId)
        .andThen((surrogateIdReturned) => {
          return this.answerResolverV2.setInstanceId(blueprintId, blueprintIdInstanceIdentifiers, surrogateIdReturned);
        });
    }
  }

  setAnswers(
    items: { blueprintId: BlueprintId; value: unknown }[],
    blueprintIdInstanceIdentifiers?: BlueprintIdInstanceScope | undefined,
  ): void {
    for (const item of items) {
      this.answerResolverV2.setAnswer(item.value, item.blueprintId, blueprintIdInstanceIdentifiers);
      this.answerResolverV1.setAnswer(item.value, item.blueprintId, blueprintIdInstanceIdentifiers);
    }
  }

  unsetAnswers(nodeInstancesToRemove: NodeInstance[]): NodeInstance[] {
    const removedNodeInstances: NodeInstance[] = [];

    for (const nodeInstance of nodeInstancesToRemove) {
      const { id, scope } = nodeInstance;
      const wasRemovedV2 = this.answerResolverV2.unsetAnswer(id, scope);
      const wasRemovedV1 = this.answerResolverV1.unsetAnswer(id, scope);

      if (wasRemovedV2 || wasRemovedV1) {
        removedNodeInstances.push(nodeInstance);
      }
    }

    return removedNodeInstances;
  }

  //#region usingNodeId
  private knowsIdByNodeId(nodeId: NodeId): boolean {
    if (this.answersToUse === 'v2') {
      return this.answerResolverV2.usingNodeId().knowsId(nodeId);
    } else {
      return this.answerResolverV1.usingNodeId().knowsId(nodeId);
    }
  }

  private getAnswerByNodeId(
    nodeId: NodeId,
    nodeIdInstanceIdentifiers?: NodeIdInstanceScope,
    skipLeafIdentifier?: boolean,
  ): any | undefined {
    if (this.answersToUse === 'v2') {
      return this.answerResolverV2.usingNodeId().getAnswer(nodeId, nodeIdInstanceIdentifiers, skipLeafIdentifier);
    } else {
      return this.answerResolverV1.usingNodeId().getAnswer(nodeId, nodeIdInstanceIdentifiers, skipLeafIdentifier);
    }
  }

  private getCollectionByNodeId(nodeId: NodeId, nodeIdInstanceIdentifiers?: NodeIdInstanceScope): any[] | undefined {
    if (this.answersToUse === 'v2') {
      return this.answerResolverV2.usingNodeId().getCollection(nodeId, nodeIdInstanceIdentifiers);
    } else {
      return this.answerResolverV1.usingNodeId().getCollection(nodeId, nodeIdInstanceIdentifiers);
    }
  }

  private getRepeatedAnswersByNodeId<T extends NodeId>(
    collectionNodeId: NodeId,
    nodeIds: T[],
    nodeIdInstanceIdentifiers: NodeIdInstanceScope,
  ): RepeatedAnswersBySurrogateId<T> | undefined {
    if (this.answersToUse === 'v2') {
      return this.answerResolverV2
        .usingNodeId()
        .getRepeatedAnswers(collectionNodeId, nodeIds, nodeIdInstanceIdentifiers);
    } else {
      return this.answerResolverV1
        .usingNodeId()
        .getRepeatedAnswers(collectionNodeId, nodeIds, nodeIdInstanceIdentifiers);
    }
  }

  private getRepetitionCountByNodeId(
    collectionNodeId: NodeId,
    nodeIdInstanceIdentifiers: NodeIdInstanceScope,
  ): number | undefined {
    if (this.answersToUse === 'v2') {
      return this.answerResolverV2.usingNodeId().getRepetitionCount(collectionNodeId, nodeIdInstanceIdentifiers);
    } else {
      return this.answerResolverV1.usingNodeId().getRepetitionCount(collectionNodeId, nodeIdInstanceIdentifiers);
    }
  }

  private setAnswerByNodeId(
    value: unknown,
    nodeId: NodeId,
    nodeIdInstanceIdentifiers?: NodeIdInstanceScope,
    answersOverride?: Answers,
  ): void {
    this.answerResolverV1.usingNodeId().setAnswer(value, nodeId, nodeIdInstanceIdentifiers, answersOverride);
    this.answerResolverV2.usingNodeId().setAnswer(value, nodeId, nodeIdInstanceIdentifiers, answersOverride);
  }

  private unsetAnswerByNodeId(nodeId: NodeId, nodeIdInstanceIdentifiers?: NodeIdInstanceScope): boolean {
    const hasRemovedSomethingV2 = this.answerResolverV2.usingNodeId().unsetAnswer(nodeId, nodeIdInstanceIdentifiers);
    const hasRemovedSomethingV1 = this.answerResolverV1.usingNodeId().unsetAnswer(nodeId, nodeIdInstanceIdentifiers);

    return hasRemovedSomethingV2 || hasRemovedSomethingV1;
  }

  private unsetAnswerSelectOptionIdByNodeId(
    nodeId: NodeId,
    optionId: string,
    nodeIdInstanceIdentifiers?: NodeIdInstanceScope,
  ): boolean {
    const hasUnsetSomethingInV2 = this.answerResolverV2
      .usingNodeId()
      .unsetAnswerSelectOptionId(nodeId, optionId, nodeIdInstanceIdentifiers);
    const hasUnsetSomethingInV1 = this.answerResolverV1
      .usingNodeId()
      .unsetAnswerSelectOptionId(nodeId, optionId, nodeIdInstanceIdentifiers);
    return hasUnsetSomethingInV2 || hasUnsetSomethingInV1;
  }

  private withCollectionIdentifierByNodeId(
    identifiers: NodeIdInstanceScope,
    collectionNodeId: NodeId,
    collectionInstanceIndex: InstanceIndex,
  ): NodeIdInstanceScope {
    if (this.answersToUse === 'v2') {
      return this.answerResolverV2
        .usingNodeId()
        .withCollectionIdentifier(identifiers, collectionNodeId, collectionInstanceIndex);
    } else {
      return this.answerResolverV1
        .usingNodeId()
        .withCollectionIdentifier(identifiers, collectionNodeId, collectionInstanceIndex);
    }
  }

  private removeUndefinedAnswersFromCollectionByNodeId(
    nodeId: NodeId,
    nodeIdInstanceIdentifiers?: NodeIdInstanceScope,
  ): boolean {
    const hasRemovedSomethingV2 = this.answerResolverV2
      .usingNodeId()
      .removeUndefinedAnswersFromCollection(nodeId, nodeIdInstanceIdentifiers);

    const hasRemovedSomethingV1 = this.answerResolverV1
      .usingNodeId()
      .removeUndefinedAnswersFromCollection(nodeId, nodeIdInstanceIdentifiers);

    return hasRemovedSomethingV2 || hasRemovedSomethingV1;
  }

  private getInstanceIdByNodeId(
    nodeId: NodeId,
    nodeIdInstanceIdentifiers: NodeIdInstanceScope,
  ): Result<string, SurrogateId | undefined> {
    if (this.answersToUse === 'v2') {
      return this.answerResolverV2.usingNodeId().getInstanceId(nodeId, nodeIdInstanceIdentifiers);
    } else {
      return this.answerResolverV1.usingNodeId().getInstanceId(nodeId, nodeIdInstanceIdentifiers);
    }
  }

  private setInstanceIdByNodeId(
    nodeId: NodeId,
    nodeIdInstanceIdentifiers: NodeIdInstanceScope,
    forceInstanceId?: SurrogateId | undefined,
  ): Result<string, SurrogateId> {
    if (this.answersToUse === 'v2') {
      return this.answerResolverV2
        .usingNodeId()
        .setInstanceId(nodeId, nodeIdInstanceIdentifiers, forceInstanceId)
        .andThen((surrogateIdReturned) => {
          return this.answerResolverV1
            .usingNodeId()
            .setInstanceId(nodeId, nodeIdInstanceIdentifiers, surrogateIdReturned);
        });
    } else {
      return this.answerResolverV1
        .usingNodeId()
        .setInstanceId(nodeId, nodeIdInstanceIdentifiers, forceInstanceId)
        .andThen((surrogateIdReturned) => {
          return this.answerResolverV2
            .usingNodeId()
            .setInstanceId(nodeId, nodeIdInstanceIdentifiers, surrogateIdReturned);
        });
    }
  }

  private setAnswersByNodeId(
    items: { nodeId: NodeId; value: unknown }[],
    nodeIdInstanceIdentifiers?: NodeIdInstanceScope | undefined,
  ): void {
    for (const item of items) {
      this.answerResolverV2.usingNodeId().setAnswer(item.value, item.nodeId, nodeIdInstanceIdentifiers);
      this.answerResolverV1.usingNodeId().setAnswer(item.value, item.nodeId, nodeIdInstanceIdentifiers);
    }
  }

  private unsetAnswersByNodeId(nodeInstancesToRemove: NodeInstance[]): NodeInstance[] {
    const removedNodeInstances: NodeInstance[] = [];

    for (const nodeInstance of nodeInstancesToRemove) {
      const { id: nodeId, scope: nodeIdInstanceIdentifiers } = nodeInstance;
      const wasRemovedV2 = this.answerResolverV2.usingNodeId().unsetAnswer(nodeId, nodeIdInstanceIdentifiers);
      const wasRemovedV1 = this.answerResolverV1.usingNodeId().unsetAnswer(nodeId, nodeIdInstanceIdentifiers);

      if (wasRemovedV2 || wasRemovedV1) {
        removedNodeInstances.push(nodeInstance);
      }
    }

    return removedNodeInstances;
  }
  //#endregion

  usingNodeId(): ReturnType<IAnswerResolver['usingNodeId']> {
    return {
      setAnswers: (...args) => this.setAnswersByNodeId(...args),
      getInstanceId: (...args) => this.getInstanceIdByNodeId(...args),
      setInstanceId: (...args) => this.setInstanceIdByNodeId(...args),
      knowsId: (...args) => this.knowsIdByNodeId(...args),
      getAnswer: (...args) => this.getAnswerByNodeId(...args),
      getCollection: (...args) => this.getCollectionByNodeId(...args),
      getRepeatedAnswers: (...args) => this.getRepeatedAnswersByNodeId(...args),
      getRepetitionCount: (...args) => this.getRepetitionCountByNodeId(...args),
      setAnswer: (...args) => this.setAnswerByNodeId(...args),
      unsetAnswer: (...args) => this.unsetAnswerByNodeId(...args),
      unsetAnswers: (...args) => this.unsetAnswersByNodeId(...args),
      unsetAnswerSelectOptionId: (...args) => this.unsetAnswerSelectOptionIdByNodeId(...args),
      withCollectionIdentifier: (...args) => this.withCollectionIdentifierByNodeId(...args),
      removeUndefinedAnswersFromCollection: (...args) => this.removeUndefinedAnswersFromCollectionByNodeId(...args),
    };
  }
}
