export const ENGINE_VERSION = 1;

export {
  QuestionnaireEngine,
  QuestionnaireEngineConfig,
  AnswersChangedSubscriber,
} from './external/QuestionnaireEngine';

export {
  QuestionnaireDefinition,
  SectionGroup,
  QuoterSubsection,
  SubmissionSubsectionLayout,
  SubsectionVariantOptions,
  RepeatableSectionGroup,
  RepeatableQuestion,
  RepeatableQuestionOptions,
  Section,
  Subsection,
  Question,
  ComputedValueNodeWithReadOnlyProperty,
  InfoSupplement,
  PlaceholderField,
  NumberField,
  NumericalDataType,
  OptionWidth,
  DynamicOptionField,
  DynamicOptions,
  RadioField,
  AgreeField,
  ButtonField,
  AutocompleteField,
  InformationField,
  SignatureField,
  Field,
  isTextFieldType,
  isOptionField,
  isDynamicOptionField,
  isOptionFieldFromApplicationContext,
  isQuoterSubsection,
  SelectOption,
  ApplicationContextSelectOptionsConfig,
  FieldSymbol,
  InfoVariants,
  RepeatableOptions,
  RepeatableOptionsWithLimits,
  RepeatableQuestionnaireNode,
  isRepeatableOptionsBasedOnCollection,
  LocalizedQuestionnaire,
} from './structure';
export {
  getRepeatedQuestionIndex,
  findSubsectionInQuestionnaire,
  getAllSections,
  getAllSubsections,
  getAllQuestions,
  getAllFields,
  getFieldTitlesByNodeId,
  getSubsectionInsuranceModules,
  getInitialFieldValue,
} from './questionnaire';

export { setQuestionnaireCompletion } from './completion';

export { OnAnswerChange, OnBulkAnswerClear, AnswerChangeItem, hasBeenAnswered } from './answers';

export { evaluateConditions, evaluateQuery } from './conditions';
export {
  AnswerPath,
  NodeIdAnswersResolver,
  PivotAnswerResolver,
  NodeIdToAnswerPathMap,
  makeNodeIdToAnswerPathMap,
  makeAnswerPathTreeFromBlueprint,
  AnswerPathTreeNode,
  verifyNodeIdToAnswerPathMap,
  verifyAllNodeIdsAreUsed,
  BlueprintIdAnswersResolver,
  translateNodeIdToBlueprintId,
} from './answersResolver';

export {
  ValidityRule,
  transformItemsVisibility,
  evaluateVisibility,
  transformItemsValidity,
  evaluateRules,
  buildValidationErrorMessage,
} from './nodeEvaluation';

export { copyAnswers } from './copyAnswers/copyAnswers';

export {
  toCustomField,
  toCustomFieldGroup,
  toCustomSubsection,
  toCustomSection,
} from './questionnaireHelpers/toCustomBlueprint';

export { yesNoOptions, emptyTitle } from './questionnaireHelpers/field';
export {
  valueAboveMinimum,
  valueBelowMaximum,
  valueDoesNotExceedCharacterLimit,
  agreeFieldIsAccepted,
} from './questionnaireHelpers/validIfs';

export { localizeQuestionnaire, Localized, TextGetter } from './locale';

export { getNodeIdsFromBlueprints } from './questionnaireHelpers/getNodeIdsFromBlueprints';

export { defaultOutcomeSchema, OutcomeDeterminator } from './decisionEngine/OutcomeDeterminator';

export {
  phoneNumberRegex,
  emailRegex,
  phoneAreaCodeList,
  Validations,
  ValidationError,
  isValidPhoneNumberAreaCode,
  fieldValidation,
  getFieldValidations,
  makeFieldValidationSchemas,
  FieldValidationSchemas,
} from './validations';

export {
  RenderingQuestionnaire,
  RenderingRepeatedSectionGroup,
  RenderingSection,
  RenderingSubsection,
  RenderingQuestion,
  RenderingRepeatedQuestion,
  RenderingField,
  RenderingSectionGroup,
  RenderingFieldOption,
  RenderingNumberField,
  RenderingAutocompleteField,
  RenderingOptionField,
  RenderingAgreeField,
  RenderingSignatureField,
  TransitionQuestionnaire,
  isRenderingTextField,
  isRenderingRadioField,
  isRenderingAgreeField,
  isRenderingButtonField,
  isRenderingOptionField,
  isRenderingPlaceholderField,
  isRenderingCheckboxGroupField,
  isRenderingRepeatedSectionGroup,
  isRenderingRepeatedQuestion,
  isRenderingInformationField,
  isRenderingQuestionnaireComplete,
  getSectionById,
  findField as findRenderingField,
  QuoterRenderingSubsection,
  PriceUpdateWarningRenderingSubsection,
  SubmissionRenderingSubsection,
  RenderingInfoSupplement,
  orderSelectOptions,
  findField,
  asExpandedQuestionnaire,
  appendRepeatableInstancesToId,
  formatRepeatableQuestionTitle,
  getDynamicOptions,
  getAppendToKeyValueForFieldWithDefaultIf,
  getAppendToKeyValue,
  FieldWithValue,
  setFieldValues,
  populateApplicationContextSelectOptions,
  transformSubsections,
  transformQuestions,
  transformFields,
  setValidationFields,
  getQuestionnaireNodes,
  IncompleteFieldIdentifier,
} from './renderingTransforms';

export { formatHeightToFeetAndCm, formatWeightToLbsAndKg } from './unitFormatter';

export { AnswersEncryption, EncryptionConfig } from './crypto';
export { moveAnswersToNewPaths } from './utils/moveAnswersToNewPaths';
export { sortOptionsAlphabetically } from './utils/sortOptionsAlphabetically';
export {
  serializeNodeIdToAnswerPathMap,
  deserializeNodeIdToAnswerPathMap,
  SerializedNodeIdToAnswerPathMap,
} from './utils/apiUtils';
export { generateAnswersDataDescriptor } from './answerDataDescriptor/generate';

export {
  Builders,
  generateBlueprintBuilders,
  blueprintBuilderFunctions,
  SeedProviders,
  SeedBlueprintProviders,
} from './builders';

export { TestUtils } from './utils/testUtils';

export { overrideQuestion } from './questionnaireHelpers/overrideQuestionnaireProperties';

export { orderByRepeatedIndex } from './answers/externalHelpers';
