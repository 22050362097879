import _ from 'lodash';
import { v4 as UUID } from 'uuid';

import BaseBuilder from './BaseBuilder';
import FieldBuilder from './FieldBuilder';
import QuestionBuilder from './QuestionBuilder';
import SectionBuilder from './SectionBuilder';
import SectionGroupBuilder from './SectionGroupBuilder';
import { SeedBlueprintProviders } from './SeedProviders';
import SubsectionBuilder from './SubsectionBuilder';
import { QuestionnaireBlueprint } from '@breathelife/types';

export default class BlueprintBuilder extends BaseBuilder<QuestionnaireBlueprint> {
  private readonly seedProviders: SeedBlueprintProviders;
  private blueprint: QuestionnaireBlueprint;

  constructor(seedProviders: SeedBlueprintProviders) {
    super();
    this.seedProviders = seedProviders;
    this.blueprint = {
      sectionBlueprints: [],
      sectionGroupBlueprints: {
        insuredPeople: {
          id: UUID(),
          partName: 'insuredPeople',
          title: { en: 'SectionGroup Title', fr: 'Titre du groupe de sections' },
          repeatable: { minRepeatable: 1, maxRepeatable: 6, repeatableAnswerNodeId: 'insured-people' },
        },
        contract: { id: UUID(), partName: 'contract' },
      },
    };
  }

  public withSectionGroups(sectionGroupBuilders?: SectionGroupBuilder[] | SectionGroupBuilder): this {
    if (!sectionGroupBuilders) {
      return this;
    }

    delete this.blueprint.sectionGroupBlueprints.contract;
    delete this.blueprint.sectionGroupBlueprints.insuredPeople;

    if (Array.isArray(sectionGroupBuilders)) {
      for (const sectionGroupBuilder of sectionGroupBuilders) {
        const key = sectionGroupBuilder.getSectionGroupKey();
        if (key) {
          const sectionGroup = sectionGroupBuilder.build();
          this.blueprint.sectionGroupBlueprints[key] = sectionGroup;
        }
      }
    } else {
      const key = sectionGroupBuilders.getSectionGroupKey();
      if (key) {
        const sectionGroup = sectionGroupBuilders.build();
        this.blueprint.sectionGroupBlueprints[key] = sectionGroup;
      }
    }

    return this.clone();
  }

  public withSections(sectionBuilders?: SectionBuilder[] | SectionBuilder): this {
    if (!sectionBuilders) {
      return this;
    }

    if (Array.isArray(sectionBuilders)) {
      const builtSections = [];
      for (const sectionBuilder of sectionBuilders) {
        builtSections.push(sectionBuilder.build());
      }
      this.blueprint.sectionBlueprints = this.blueprint.sectionBlueprints.concat(builtSections);
    } else {
      this.blueprint.sectionBlueprints.push(sectionBuilders.build());
    }

    return this.clone();
  }

  public withSubsections(subsectionBuilders?: SubsectionBuilder[] | SubsectionBuilder): this {
    this.blueprint.sectionBlueprints.push(this.childBuilder().withSubsections(subsectionBuilders).build());

    return this.clone();
  }

  public withQuestions(questionBuilders?: QuestionBuilder[] | QuestionBuilder): this {
    this.blueprint.sectionBlueprints.push(this.childBuilder().withQuestions(questionBuilders).build());

    return this.clone();
  }

  public withFields(fieldBuilders?: FieldBuilder[] | FieldBuilder): this {
    this.blueprint.sectionBlueprints.push(this.childBuilder().withFields(fieldBuilders).build());

    return this.clone();
  }

  public build(): QuestionnaireBlueprint {
    return _.cloneDeep(this.blueprint);
  }

  private childBuilder(): SectionBuilder {
    return new SectionBuilder(this.seedProviders);
  }
}
