import _ from 'lodash';

import { Conditions, EngineEffects, Localizable, ParticipantRoles, FieldTypes } from '@breathelife/types';

import {
  ButtonField,
  DynamicOptionField,
  DynamicOptions,
  Field,
  OptionField,
  SelectOption,
  SignatureField,
  TextField,
} from '../../structure';
import { validateField } from '../../structureValidations';
import { Validations } from '../../validations';
import NodeBuilder from './NodeBuilder';
import { SeedProviders } from './SeedProviders';

export default abstract class FieldBuilder<TFieldType extends Field = Field> extends NodeBuilder<Field, TFieldType> {
  protected constructor(nodeSeed: Partial<TFieldType>) {
    super(nodeSeed);
  }

  public withProperties(properties: Partial<TFieldType>): this {
    Object.assign(this.node, properties);
    return this.clone();
  }

  public withId(id: string): this {
    this.node.id = id;
    return this.clone();
  }

  public withNodeId(nodeId: string): this {
    this.node.nodeId = nodeId;
    return this.clone();
  }

  public withTitle(title: Localizable): this {
    this.node.title = title;
    return this.clone();
  }

  public withEffects(effects: EngineEffects): this {
    this.node.effects = effects;
    return this.clone();
  }

  public makeDisabled(): this {
    this.node.disabled = true;
    return this.clone();
  }

  public makeEncrypted(): this {
    this.node.encrypted = true;
    return this.clone();
  }

  public makePhi(): this {
    this.node.PHI = true;
    return this.clone();
  }

  public withDefaultValue(defaultValue: unknown): this {
    this.node.defaultValue = defaultValue;
    return this.clone();
  }

  public visibleIf(condition: Conditions): this {
    this.node.visibleIf = condition;

    return this.clone();
  }

  public validate(field: TFieldType): field is TFieldType {
    return validateField(field);
  }
}

export class TextFieldBuilder extends FieldBuilder<TextField> {
  constructor(seedProviders: SeedProviders) {
    const seed = Object.assign(seedProviders.forInputField(), {
      type: FieldTypes.input,
      validation: { type: Validations.string },
    });
    super(seed);
  }
}

export class DateFieldBuilder extends FieldBuilder {
  constructor(seedProviders: SeedProviders) {
    const seed = Object.assign(seedProviders.forInputField(), {
      type: FieldTypes.date,
      validation: Validations.date,
    });
    super(seed);
  }
}

export class YearMonthFieldBuilder extends FieldBuilder {
  constructor(seedProviders: SeedProviders) {
    const seed = Object.assign(seedProviders.forInputField(), {
      type: FieldTypes.yearMonth,
      validation: Validations.yearMonth,
    });
    super(seed);
  }
}

// TODO @QuestionnaireBuilder : Should this be an AgreeField type?
export class ConsentFieldBuilder extends FieldBuilder {
  constructor(seedProviders: SeedProviders) {
    const seed = Object.assign(seedProviders.forInputField(), {
      type: FieldTypes.agree,
      validation: { type: Validations.booleanTrue },
    });
    super(seed);
  }
}

export class NumberFieldBuilder extends FieldBuilder {
  constructor(seedProviders: SeedProviders) {
    const seed = Object.assign(seedProviders.forInputField(), {
      type: FieldTypes.number,
      validation: { type: Validations.integer },
    });
    super(seed);
  }
}

export class PhoneFieldBuilder extends FieldBuilder {
  constructor(seedProviders: SeedProviders) {
    const seed = Object.assign(seedProviders.forInputField(), {
      type: FieldTypes.phone,
      validation: { type: Validations.phoneNumber },
    });
    super(seed);
  }
}

export class InformationFieldBuilder extends FieldBuilder {
  constructor(seedProviders: SeedProviders) {
    const seed = Object.assign(seedProviders.forInputField(), {
      type: FieldTypes.information,
    });
    super(seed);
  }
}
export class ButtonFieldBuilder extends FieldBuilder<ButtonField> {
  constructor(seedProviders: SeedProviders) {
    const seed = Object.assign(seedProviders.forInputField(), {
      type: FieldTypes.button,
    });
    super(seed);
  }
}

export class MoneyFieldBuilder extends FieldBuilder {
  constructor(seedProviders: SeedProviders) {
    const seed = Object.assign(seedProviders.forInputField(), {
      type: FieldTypes.money,
      validation: { type: Validations.integer },
    });
    super(seed);
  }
}

export class OptionFieldBuilder extends FieldBuilder<OptionField> {
  constructor(seedProviders: SeedProviders) {
    super(seedProviders.forOptionField());
  }

  public withOptions(options: SelectOption[]): this {
    this.node.options = options;
    return this.clone();
  }

  public withType(type: FieldTypes.checkbox | FieldTypes.radio | FieldTypes.dropdown | FieldTypes.checkboxGroup): this {
    this.node.type = type;
    return this.clone();
  }
}

export class DynamicOptionFieldBuilder extends FieldBuilder<DynamicOptionField> {
  constructor(seedProviders: SeedProviders, dynamicOptions: DynamicOptions) {
    super(seedProviders.forDynamicOptionField(dynamicOptions));
  }

  public withOptions(options: SelectOption[]): this {
    this.node.options = options;
    return this.clone();
  }

  public withType(type: FieldTypes.checkbox | FieldTypes.radio | FieldTypes.dropdown | FieldTypes.checkboxGroup): this {
    this.node.type = type;
    return _.cloneDeep(this);
  }
}

export class SignatureFieldBuilder extends FieldBuilder<SignatureField> {
  constructor(seedProviders: SeedProviders, participantRole: ParticipantRoles) {
    super(seedProviders.forSignatureField(participantRole));
  }
}
