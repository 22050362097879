import {
  evaluateConditions as conditionEngineEvaluateConditions,
  evaluateQuery as conditionEngineEvaluateQuery,
} from '@breathelife/condition-engine';

import {
  Conditions,
  OperatorResult,
  Query,
  InstanceScope,
  Timezone,
  IReadOnlyAnswerResolver,
} from '@breathelife/types';

export function evaluateConditions(
  conditions: Conditions,
  answersResolver: IReadOnlyAnswerResolver,
  repeatedInstanceIdentifiers: InstanceScope,
  timezone: Timezone,
  currentDateOverride: string | null,
): boolean {
  return conditionEngineEvaluateConditions(
    conditions,
    answersResolver,
    repeatedInstanceIdentifiers,
    timezone,
    currentDateOverride,
  ).isValid;
}

export function evaluateQuery(
  query: Query,
  answersResolver: IReadOnlyAnswerResolver,
  repeatedInstanceIdentifiers: InstanceScope,
  timezone: Timezone,
  currentDateOverride: string | null,
): OperatorResult {
  return conditionEngineEvaluateQuery(
    query,
    answersResolver,
    repeatedInstanceIdentifiers,
    timezone,
    {},
    currentDateOverride,
  );
}
