import { generateBlueprintBuilders } from './BlueprintBuilderFunctions';
import { SeedBlueprintProviders } from './SeedProviders';
import { v4 as uuid } from 'uuid';
import {
  ParticipantRoles,
  FieldTypes,
  SectionBlueprint,
  SectionGroupBlueprint,
  SubsectionBlueprint,
  QuestionBlueprint,
  InputFieldBlueprint,
  RadioFieldBlueprint,
  SelectOptionFieldBlueprint,
  CheckboxFieldBlueprint,
  SignatureFieldBlueprint,
  InputFieldValidation,
  StringFieldValidation,
  BooleanFieldValidation,
  AlwaysValid,
} from '@breathelife/types';

const testSeedProviders: SeedBlueprintProviders = {
  forSectionGroup: (): SectionGroupBlueprint => ({
    id: uuid(),
    partName: 'sectionGroupPartName',
    title: { en: 'SectionGroup Title', fr: 'Titre du groupe de sections' },
  }),

  forSection: (): SectionBlueprint => ({
    id: uuid(),
    partName: 'sectionPartName',
    sectionGroupKey: 'insuredPeople',
    title: { en: 'Section Title', fr: 'Titre de la section' },
    subsections: [],
  }),

  forSubsection: (): SubsectionBlueprint => ({
    id: uuid(),
    partName: 'subsectionPartName',
    title: { en: 'Subsection Title', fr: 'Titre de la sous-section' },
    questions: [],
  }),

  forQuestion: (): QuestionBlueprint => ({
    id: uuid(),
    partName: 'questionPartName',
    title: { en: 'Question Title', fr: 'Titre de la question' },
    text: { en: 'Question Text', fr: 'Texte de la question' },
    fields: [],
  }),

  forInputField: (): Partial<InputFieldBlueprint> => ({
    id: uuid(),
    partName: 'inputFieldPartName',
    title: { en: 'Field Title', fr: 'Titre du champ texte' },
    text: { en: 'Field Text', fr: 'Texte du champ texte' },
    fieldType: FieldTypes.input,
    validateAs: InputFieldValidation.string,
  }),

  forRadioField: (): Partial<RadioFieldBlueprint> => ({
    id: uuid(),
    partName: 'radioFieldPartName',
    title: { en: 'Option Field Title', fr: 'Titre du champ option' },
    text: { en: 'Option Field Text', fr: 'Texte du champ option' },
    fieldType: FieldTypes.radio,
    validateAs: StringFieldValidation.string,
    selectOptions: [{ partName: 'id0', text: { en: 'Text', fr: 'Text ' } }],
  }),

  forDropdownField: (): Partial<SelectOptionFieldBlueprint> => ({
    id: uuid(),
    partName: 'dropdownFieldPartName',
    title: { en: 'Option Field Title', fr: 'Titre du champ option' },
    text: { en: 'Option Field Text', fr: 'Texte du champ option' },
    fieldType: FieldTypes.dropdown,
    validateAs: StringFieldValidation.string,
    selectOptions: [{ partName: 'id0', text: { en: 'Text', fr: 'Text ' } }],
  }),

  forCheckboxField: (): Partial<CheckboxFieldBlueprint> => ({
    id: uuid(),
    partName: 'checkboxFieldPartName',
    title: { en: 'Option Field Title', fr: 'Titre du champ option' },
    text: { en: 'Option Field Text', fr: 'Texte du champ option' },
    fieldType: FieldTypes.checkbox,
    validateAs: BooleanFieldValidation.boolean,
  }),

  forRepeatableQuestionOptions: (): Partial<QuestionBlueprint> => ({
    id: uuid(),
    repeatable: {
      repeatableAnswerNodeId: 'missingNodeId',
      minRepeatable: 1,
      maxRepeatable: 3,
      addButtonText: { en: 'Add', fr: 'Ajouter' },
      removeButtonText: { en: 'Remove', fr: 'Supprimer' },
    },
  }),

  forSignatureField: (participantRole: ParticipantRoles): Partial<SignatureFieldBlueprint> => {
    return {
      id: 'signatureFieldId',
      partName: 'signatureFieldPartName',
      title: { en: 'Field Title', fr: 'Titre du champ texte' },
      text: { en: 'Field Text', fr: 'Texte du champ texte' },
      fieldType: FieldTypes.signature,
      validateAs: AlwaysValid.signature,
      participantRole,
    };
  },
};

export const blueprintBuilderFunctions = generateBlueprintBuilders(testSeedProviders);
export const aBlueprint = blueprintBuilderFunctions.aBlueprint;
export const aSectionGroup = blueprintBuilderFunctions.aSectionGroup;
export const aSection = blueprintBuilderFunctions.aSection;
export const aSubsection = blueprintBuilderFunctions.aSubsection;
export const aQuestion = blueprintBuilderFunctions.aQuestion;
export const aRepeatableQuestion = blueprintBuilderFunctions.aRepeatableQuestion;
export const aTextField = blueprintBuilderFunctions.aTextField;
export const aDateField = blueprintBuilderFunctions.aDateField;
export const aYearMonthField = blueprintBuilderFunctions.aYearMonthField;
export const aConsentField = blueprintBuilderFunctions.aConsentField;
export const aNumberField = blueprintBuilderFunctions.aNumberField;
export const aPhoneField = blueprintBuilderFunctions.aPhoneField;
export const aMoneyField = blueprintBuilderFunctions.aMoneyField;
export const aRadioField = blueprintBuilderFunctions.aRadioField;
export const aDropdownField = blueprintBuilderFunctions.aDropdownField;
export const aCheckboxGroupField = blueprintBuilderFunctions.aCheckboxGroupField;
export const aDynamicDropdownField = blueprintBuilderFunctions.aDynamicDropdownField;
export const anInformationField = blueprintBuilderFunctions.anInformationField;
export const aButtonField = blueprintBuilderFunctions.aButtonField;
export const aSignatureField = blueprintBuilderFunctions.aSignatureField;
