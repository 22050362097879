import _ from 'lodash';

import { isConditions } from '@breathelife/condition-engine';
import { Condition, Conditions, Localizable, IAnswerResolver, InstanceScope } from '@breathelife/types';

import { DynamicOptions, SelectOption } from '../structure';
import { TransitionNode, TransitionNodeWithMetadata } from './Questionnaire';
import { RenderingFieldOption } from './RenderingQuestionnaire';

export const REPLACE_WITH_OPTION_ID = 'REPLACE_WITH_OPTION_ID';

type QuestionnaireWithOptionType<TOptionType> = (TransitionNodeWithMetadata & {
  sections: (TransitionNode & {
    subsections: (TransitionNode & {
      questions: (TransitionNodeWithMetadata & {
        fields: (TransitionNodeWithMetadata & { options?: TOptionType[] })[];
      })[];
    })[];
  })[];
})[];

type PopulatedDynamicOption = TransitionNode & SelectOption & Pick<RenderingFieldOption, 'text'>;

export type PopulatedDynamicOptionsQuestionnaire = QuestionnaireWithOptionType<PopulatedDynamicOption>;

export function getDynamicOptions(
  dynamicOptions: DynamicOptions,
  repeatedInstanceIdentifiers: InstanceScope,
  answersResolver: IAnswerResolver,
): SelectOption[] {
  const collectionNodeId: string = dynamicOptions.collection;
  const selectNodeIds: string[] = dynamicOptions.select;
  const optionVisibleIf = dynamicOptions.visibleIf;

  const answersBySurrogateId = answersResolver
    .usingNodeId()
    .getRepeatedAnswers(collectionNodeId, selectNodeIds, repeatedInstanceIdentifiers);

  let options: SelectOption[] = [];
  if (typeof answersBySurrogateId !== 'undefined') {
    options = Object.entries(answersBySurrogateId)
      .map(([surrogateId, { answersByNodeId, repeatedIndex }]) => {
        const nodeIdScope = dynamicOptions.useLocalIdentifiers
          ? { ...repeatedInstanceIdentifiers }
          : answersResolver
              .usingNodeId()
              .withCollectionIdentifier(repeatedInstanceIdentifiers, collectionNodeId, repeatedIndex);

        return {
          id: surrogateId,
          text: formatOptionText(selectNodeIds, answersByNodeId),
          metadata: {
            repeatedInstanceIdentifierContext: { byNodeId: nodeIdScope, byBlueprintId: {} },
          },
          visibleIf: optionVisibleIf && replaceWithCurrentOptionValue(surrogateId, _.cloneDeep(optionVisibleIf)),
        };
      })
      .filter((option) => option.text.en || option.text.fr);
  }

  return options;
}

/** If a condition `value` property is `REPLACE_WITH_OPTION_ID` replace it with `currentOptionId` */
function replaceWithCurrentOptionValue(currentOptionId: string, conditions?: Conditions): Conditions | undefined {
  if (!conditions || !conditions.conditions.length) return conditions;

  conditions.conditions = conditions.conditions.map((condition: Condition) => {
    if (isConditions(condition)) {
      // Recurse if this condition contains other conditions.
      const subConditions = replaceWithCurrentOptionValue(currentOptionId, condition);
      if (!subConditions) {
        throw Error('subConditions cannot be undefined');
      }

      return subConditions;
    }

    if (condition.value === REPLACE_WITH_OPTION_ID) {
      condition.value = currentOptionId;
    }

    return condition;
  });

  return conditions;
}

// formatOptionText currently concatenates all non-empty answers together.
// This may be replaced by a more advanced formatting system.
function formatOptionText(nodeIds: string[], answersByNodeId: { [nodeId: string]: any }): Localizable {
  const definedValues = nodeIds
    .map((nodeId) => answersByNodeId[nodeId])
    .filter((value) => typeof value !== 'undefined' && value !== '');

  const text = definedValues.join(' ');

  // In a more advanced formatting system these values may differ (right now they are in this form so the option's type is correct).
  return { en: text, fr: text };
}
