import _ from 'lodash';
import { Answers } from '@breathelife/types';

export function hasBeenAnswered(answer: unknown): boolean {
  return !_.isEmpty(answer) || _.isNumber(answer) || answer === true;
}

// Similar to https://github.com/hughsk/flat, but will not flatten arrays when they're leaves in the answers structure
export function flattenAnswers(
  answers: Answers | Answers[],
  delimiter: string,
  currentPath?: string,
  flatAnswers: { [fieldId: string]: any } = {},
): { [fieldId: string]: any } {
  if (areAnswersForRepeatedQuestions(answers)) {
    answers.forEach((obj: Answers, index: number) => {
      const newPath: string = currentPath ? `${currentPath}${delimiter}${index}` : `${index}`;
      flattenAnswers(obj, delimiter, newPath, flatAnswers);
    });
    return flatAnswers;
  }

  if (Array.isArray(answers)) {
    // leaf is an array, so we do not flatten it
    // answers are related to a multi-choice type, e.g. checkboxGroup
    _.set(flatAnswers, [currentPath ?? ''], answers);
    return flatAnswers;
  }

  Object.entries(answers).forEach(([key, value]) => {
    const newPath: string = currentPath ? `${currentPath}${delimiter}${key}` : key;
    if (typeof value === 'object' && value !== null) {
      flattenAnswers(value, delimiter, newPath, flatAnswers);
    } else {
      _.set(flatAnswers, [newPath], value);
    }
  });

  return flatAnswers;
}

export function areAnswersForRepeatedQuestions(answers: Answers | Answers[]): boolean {
  return Array.isArray(answers) && answers.every((answer: any) => answer && typeof answer === 'object');
}
