import { RenderingType, InstanceScope, Timezone, IAnswerResolver } from '@breathelife/types';

import { EvaluatedVisibilityNode } from '../../renderingTransforms/RenderingQuestionnaire';
import { QuestionnairePartNode, VisibleIf } from '../../structure';
import { EvaluationVisitor, VisitNodeFunction } from '../EvaluationVisitor';
import { evaluateVisibility } from './visibility';

type VisibilityVisitorOutputNode = Omit<QuestionnairePartNode, 'visibleIf'> &
  (
    | ({
        visible?: undefined;
      } & VisibleIf)
    | ({
        visibleIf?: undefined;
      } & EvaluatedVisibilityNode)
  );

export type VisibilityNodeVisitorOutput = {
  repeatedInstanceIdentifiers: InstanceScope;
  isVisible: boolean;
  node: VisibilityVisitorOutputNode;
  childrenVisibility?: VisibilityNodeVisitorOutput[];
};

export type VisibilityExtension<TCompleteOut> = {
  answersResolver: IAnswerResolver;
  processOutput: (props: VisibilityNodeVisitorOutput) => void;
  complete: () => TCompleteOut;
  renderingType?: RenderingType;
  timezone: Timezone;
  currentDateOverride: string | null;
};

export function createVisibilityVisitor<TCompleteOut>({
  answersResolver,
  processOutput,
  complete,
  renderingType,
  timezone,
  currentDateOverride,
}: VisibilityExtension<TCompleteOut>): EvaluationVisitor<VisibilityNodeVisitorOutput, TCompleteOut> {
  let isInHiddenSubtree: boolean = false;

  const enterHiddenSubtree = (callback: () => void): void => {
    if (isInHiddenSubtree) {
      callback();
    } else {
      isInHiddenSubtree = true;
      callback();
      isInHiddenSubtree = false;
    }
  };

  const nodeVisitor =
    (options?: { hideIfChildrenHidden: boolean }): VisitNodeFunction<any, VisibilityNodeVisitorOutput> =>
    (navigation, { node, rule, repeatedInstanceIdentifiers }): VisibilityNodeVisitorOutput => {
      let isVisible =
        !isInHiddenSubtree &&
        evaluateVisibility(
          node,
          renderingType ?? RenderingType.web,
          answersResolver,
          repeatedInstanceIdentifiers,
          timezone,
          currentDateOverride,
          rule,
        );

      if (!isVisible) {
        enterHiddenSubtree(() => navigation.visitChildren());
      } else {
        navigation.visitChildren();

        const shouldCheckChildVisibility =
          options?.hideIfChildrenHidden &&
          // dynamicOptions fields can be empty for reasons other than visibility. For consistency do not hide them even if empty.
          !node.dynamicOptions;

        if (shouldCheckChildVisibility) {
          const childrenEvaluation = navigation.childrenResults();
          if (childrenEvaluation.length !== 0 && childrenEvaluation.every(({ isVisible }) => !isVisible)) {
            isVisible = false;
          }
        }
      }
      const out: VisibilityNodeVisitorOutput = {
        isVisible,
        repeatedInstanceIdentifiers,
        node,
        childrenVisibility: navigation.childrenResults(),
      };
      processOutput(out);
      return out;
    };

  return {
    visitDefault: nodeVisitor(),
    visitQuestion: nodeVisitor({ hideIfChildrenHidden: true }),
    visitField: nodeVisitor({ hideIfChildrenHidden: true }),
    complete,
  };
}
