import _ from 'lodash';

import { evaluateConditions } from '@breathelife/condition-engine';
import {
  EngineEffects,
  InsuranceModule,
  Language,
  PlatformType,
  RenderingType,
  IAnswerResolver,
  QuestionnaireScreenConfig,
  Timezone,
  VersionedAnswers,
  ApplicationMode,
  ApplicationContext,
  InstanceScope,
  RepeatedAnswersBySurrogateId,
  NodeId,
  NodeIdInstanceScope,
  BlueprintIdInstanceScope,
} from '@breathelife/types';

import { AnswerChangeItem } from '../answers';
import { Localized, TextGetter } from '../locale';
import { VisibilityDependencyMap } from '../nodeEvaluation/visibleIf/dependencyMap';
import { calculateProgress } from '../progress';
import { getAllSubsections } from '../questionnaire';
import { filterNodesByApplicationMode } from '../questionnaire/filterNodesByApplicationMode';
import { filterNodesByPlatformType } from '../questionnaire/filterNodesByPlatformType';
import { filterQuestionnaireSectionsByInsuranceModule } from '../questionnaire/filterQuestionnaireSectionsByInsuranceModule';
import { extendQuestionnaireWithScreen } from '../questionnaireHelpers/questionnaireScreenExtenderVisitor';
import {
  ActiveSectionId,
  DEFAULT_CONFIG,
  RenderingField,
  RenderingQuestionnaire,
  RenderingQuestionnaireGeneratorConfig,
} from '../renderingTransforms';
import {
  BlockingSubsection,
  isBlockingSubsection,
  LocalizedQuestionnaire,
  QuestionnaireDefinition,
  SectionGroup,
  Subsection,
} from '../structure';
import { updateAnswer } from '../updateAnswer';
import { FieldValidationSchemas, areAllFieldsValidAndComplete, makeFieldValidationSchemas } from '../validations';
import { shareQuestionnaireReferences } from './shareQuestionnaireReferences';
import {
  RenderingQuestionnaireGenerator,
  QuestionnaireNodeItem,
  getQuestionnaireNodes,
} from '../renderingTransforms/RenderingQuestionnaireGenerator';
import { defaultQuestionnaireAnswers } from '../defaultAnswers';
import { computedQuestionnaireAnswers } from '../computedAnswers';

type QuestionnaireContext = {
  platformTypes?: PlatformType[];
  insuranceModules?: InsuranceModule[];
  applicationModes?: ApplicationMode[];
};

export type QuestionnaireEngineConfig = RenderingQuestionnaireGeneratorConfig & {
  screenConfig?: QuestionnaireScreenConfig;
};

type RenderingOpts = {
  renderingType: RenderingType;
  displayErrors: boolean;
  loadingFields?: boolean;
  readonly?: boolean;
  activeSectionId?: ActiveSectionId;
};

export type AnswersChangedSubscriber = (instance: QuestionnaireEngine, nodeIds: NodeId[]) => Promise<void>;

export const DEFAULT_RENDERING_OPTS: RenderingOpts = {
  activeSectionId: undefined,
  readonly: false,
  displayErrors: false,
  loadingFields: false,
  renderingType: RenderingType.web,
};

export class QuestionnaireEngine {
  private readonly questionnaire: Localized<QuestionnaireDefinition>;
  private readonly questionnaireNodes: Map<string, QuestionnaireNodeItem>;
  private answerResolver: IAnswerResolver;
  private readonly visibilityDependencyMap: VisibilityDependencyMap;
  private readonly config: QuestionnaireEngineConfig;
  private readonly timezone: Timezone;
  public renderingQuestionnaire: RenderingQuestionnaire;
  private renderingQuestionnaireGenerator: RenderingQuestionnaireGenerator | null = null;
  private readonly currentDateOverride: string | null = null;
  private readonly applicationContext: ApplicationContext;
  private readonly fieldsSchemas: FieldValidationSchemas;
  private readonly onRenderingQuestionnaireChanged: (rq: RenderingQuestionnaire) => void;
  private readonly answerChangedSubscribers: AnswersChangedSubscriber[];
  private text: TextGetter;
  private renderingOptions: RenderingOpts;
  public isLoading: boolean = false;
  private readonly cloningStrategy: 'CacheSafe' | 'Fast';

  private constructor(
    questionnaire: LocalizedQuestionnaire,
    questionnaireNodes: Map<string, QuestionnaireNodeItem>,
    answerResolver: IAnswerResolver,
    visibilityDependencyMap: VisibilityDependencyMap,
    config: QuestionnaireEngineConfig,
    timezone: Timezone,
    applicationContext: ApplicationContext,
    fieldsSchemas: FieldValidationSchemas,
    onRenderingQuestionnaireChanged: (rq: RenderingQuestionnaire) => void,
    answerChangedSubscribers: AnswersChangedSubscriber[],
    text: TextGetter,
    renderingOptions: RenderingOpts,
    currentDateOverride: string | null,
    cloningStrategy: 'CacheSafe' | 'Fast' = 'CacheSafe',
  ) {
    this.questionnaire = questionnaire;
    this.questionnaireNodes = questionnaireNodes;
    this.answerResolver = answerResolver;
    this.visibilityDependencyMap = visibilityDependencyMap;
    this.config = config;
    this.timezone = timezone;
    this.renderingQuestionnaireGenerator = null;
    this.applicationContext = applicationContext;
    this.fieldsSchemas = fieldsSchemas;
    this.onRenderingQuestionnaireChanged = onRenderingQuestionnaireChanged;
    this.answerChangedSubscribers = answerChangedSubscribers;
    this.cloningStrategy = cloningStrategy;
    this.text = text;
    this.renderingOptions = renderingOptions;
    this.currentDateOverride = currentDateOverride;

    if (this.renderingOptions.readonly !== true) {
      defaultQuestionnaireAnswers(
        this.questionnaire,
        answerResolver,
        this.visibilityDependencyMap,
        this.timezone,
        null,
      );

      computedQuestionnaireAnswers(
        this.questionnaire,
        answerResolver,
        this.visibilityDependencyMap,
        this.timezone,
        null,
      );
    }

    const rq = this.generateRenderingQuestionnaire();
    this.renderingQuestionnaire = rq;
    this.onRenderingQuestionnaireChanged(rq);
    if (this.renderingOptions.readonly !== true) {
      this.notifyAnswerSubscribers();
    }
  }

  setDisplayError(value: boolean): void {
    this.renderingOptions.displayErrors = value;
    const rq = this.generateRenderingQuestionnaire();
    this.renderingQuestionnaire = rq;
    this.onRenderingQuestionnaireChanged(rq);
  }

  static from(args: {
    questionnaire: LocalizedQuestionnaire;
    answerResolver: IAnswerResolver;
    timezone: Timezone;
    currentDateOverride: string | null;
    config?: QuestionnaireEngineConfig;
    context?: QuestionnaireContext;
    additionalTextProcessing?: TextGetter;
    applicationContext?: ApplicationContext;
    onRenderingQuestionnaireChanged?: (rq: RenderingQuestionnaire) => void;
    answerChangedSubscribers?: AnswersChangedSubscriber[];
    text: TextGetter;
    renderingOptions?: RenderingOpts;
    cloningStrategy?: 'CacheSafe' | 'Fast';
  }): QuestionnaireEngine {
    const config: QuestionnaireEngineConfig = args.config || DEFAULT_CONFIG;
    const context = args.context || {};
    const additionalTextProcessing = args.additionalTextProcessing || ((text: string) => text);
    const applicationContext: ApplicationContext = args.applicationContext || {};

    let questionnaire = args.questionnaire;
    const questionnaireNodes = getQuestionnaireNodes(args.questionnaire);

    const { platformTypes, insuranceModules, applicationModes } = context;
    if (insuranceModules) {
      questionnaire = filterQuestionnaireSectionsByInsuranceModule(questionnaire, insuranceModules);
    }

    if (platformTypes) {
      questionnaire = filterNodesByPlatformType(questionnaire, platformTypes);
    }

    if (applicationModes) {
      questionnaire = filterNodesByApplicationMode(questionnaire, applicationModes);
    }

    if (config.screenConfig) {
      questionnaire = extendQuestionnaireWithScreen(questionnaire, config.screenConfig);
    }

    const timezone = args.timezone;

    const visibilityDependencyMap = new VisibilityDependencyMap(questionnaire);

    const fieldsSchemas = makeFieldValidationSchemas(additionalTextProcessing, args.timezone);

    const questionnaireEngine = new QuestionnaireEngine(
      questionnaire,
      questionnaireNodes,
      args.answerResolver,
      visibilityDependencyMap,
      config,
      timezone,
      applicationContext,
      fieldsSchemas,
      args.onRenderingQuestionnaireChanged || (() => {}),
      args.answerChangedSubscribers || [],
      args.text,
      args.renderingOptions || DEFAULT_RENDERING_OPTS,
      args.currentDateOverride,
      args.cloningStrategy,
    );

    return questionnaireEngine;
  }

  clone(versionedAnswers?: VersionedAnswers): QuestionnaireEngine {
    const answerResolverToUse = this.answerResolver.clone(versionedAnswers);

    return new QuestionnaireEngine(
      this.questionnaire,
      this.questionnaireNodes,
      answerResolverToUse,
      this.visibilityDependencyMap,
      this.config,
      this.timezone,
      this.applicationContext,
      this.fieldsSchemas,
      this.onRenderingQuestionnaireChanged,
      this.answerChangedSubscribers,
      this.text,
      this.renderingOptions,
      this.currentDateOverride,
      this.cloningStrategy,
    );
  }

  getAnswerResolverInstance = (): IAnswerResolver => this.answerResolver;

  public getAnswer(nodeId: NodeId, nodeIdScope?: NodeIdInstanceScope): unknown {
    return this.answerResolver.usingNodeId().getAnswer(nodeId, nodeIdScope || {});
  }

  public getRepeatedAnswers<T extends string>(
    collectionNodeId: string,
    nodeIds: T[],
    scope: InstanceScope,
  ): RepeatedAnswersBySurrogateId<T> | undefined {
    return this.answerResolver.usingNodeId().getRepeatedAnswers(collectionNodeId, nodeIds, scope);
  }

  public getAnswersWithDefaultValues(): VersionedAnswers {
    const answerResolverWithDefaults = this.answerResolver.clone();

    defaultQuestionnaireAnswers(
      this.questionnaire,
      answerResolverWithDefaults,
      this.visibilityDependencyMap,
      this.timezone,
      null,
    );

    computedQuestionnaireAnswers(
      this.questionnaire,
      answerResolverWithDefaults,
      this.visibilityDependencyMap,
      this.timezone,
      null,
    );

    return answerResolverWithDefaults.dump();
  }

  // TODO: (long-term) The questionnaire-engine should not have to expose this function.
  public findSectionGroup(sectionGroupId: string): Localized<SectionGroup> | undefined {
    return this.questionnaire.find((sectionGroup) => sectionGroup.id === sectionGroupId);
  }

  public getRepetitionCount(nodeId: string, scope: InstanceScope): number | undefined {
    return this.answerResolver.usingNodeId().getRepetitionCount(nodeId, scope);
  }

  public unsetAnswer(
    nodeId: string,
    scope?: InstanceScope,
    // answersForPath?: Answers, // TODO: <---- Check how answersForPath was used.
  ): boolean {
    if (this.renderingOptions.readonly === true) {
      return false;
    }

    const hasUnsettedAnswer = this.answerResolver.usingNodeId().unsetAnswer(nodeId, scope);

    if (hasUnsettedAnswer) {
      this.generateRenderingQuestionnaire();
      this.onRenderingQuestionnaireChanged(this.renderingQuestionnaire);
      this.notifyAnswerSubscribers([nodeId]);
    }
    return hasUnsettedAnswer;
  }

  public removeUndefinedAnswersFromCollection(nodeId: string, scope?: InstanceScope): boolean {
    if (this.renderingOptions.readonly === true) {
      return false;
    }

    const removed = this.answerResolver.usingNodeId().removeUndefinedAnswersFromCollection(nodeId, scope);

    if (removed) {
      this.generateRenderingQuestionnaire();
      this.onRenderingQuestionnaireChanged(this.renderingQuestionnaire);
      this.notifyAnswerSubscribers([nodeId]);
    }
    return removed;
  }

  /**
   * Returns a new `Answers` object, with the `newAnswerValue` set at the path that's associated with the `nodeId`.
   * Note: `nodeId` should be used instead of answerPath (Legacy), the latter taking the form of an explicit full path to the answer's key, e.g. `insuredPeople.2.personalInfo.firstName`
   *
   * @example
   * const answers = { name: 'Joe', province: 'QC' };
   * const updatedAnswers = engine.updateAnswer(answers, 'province', 'ON'); // => { name: 'Joe', province: 'ON' }
   */
  public updateAnswer(item: AnswerChangeItem): void {
    if (this.renderingOptions.readonly === true) {
      return;
    }

    updateAnswer(
      this.questionnaire,
      this.answerResolver,
      this.visibilityDependencyMap,
      item,
      this.timezone,
      this.currentDateOverride,
      this.applicationContext,
    );

    this.generateRenderingQuestionnaire();
    this.onRenderingQuestionnaireChanged(this.renderingQuestionnaire);
    if (item.tag === 'nodeId') {
      this.notifyAnswerSubscribers([item.nodeId]);
    } else {
      const updatedQuestionIds = [item.blueprintId];
      if (item.nodeId) {
        updatedQuestionIds.push(item.nodeId);
      }
      this.notifyAnswerSubscribers(updatedQuestionIds);
    }

    // // Make sure surrogateID of v1 matches surrogateID of V2
    // const surrogateId = answersResolverV1.getInstanceId(nodeId, repeatedIndices || {});
    // if (surrogateId.success && surrogateId.value) {
    //   answersResolverV2.setInstanceId(nodeId, repeatedIndices || {}, surrogateId.value);
    // }
  }

  /**
   * Returns a `RenderingQuestionnaire` object which can be described as a questionnaire that has been evaluated with the given `answers`.
   * This method will decorate each questionnaire's node with additional properties holding the results of the following evaluations:
   * - validity of answers
   * - visibility of sections, subsections, questions, fields
   * - completion of the questionnaire
   *
   * @example
   * const answers = { name: 'Joe', province: 'QC' };
   * const renderingOptions = {
   *    renderingType: 'web',
   *    displayErrors: true,
   * };
   * const renderingQuestionnaire = engine.generateRenderingQuestionnaire(answers, renderingOptions);
   */
  private generateRenderingQuestionnaire(): RenderingQuestionnaire {
    const { renderingType, displayErrors, readonly } = this.renderingOptions;

    this.renderingQuestionnaireGenerator = new RenderingQuestionnaireGenerator(
      {
        questionnaire: this.questionnaire,
        questionnaireNodes: this.questionnaireNodes,
        answersResolver: this.answerResolver,
        applicationContext: this.applicationContext,
        language: Language.en,
        text: this.text,
        displayErrors,
        fieldValidationSchemas: this.fieldsSchemas,
        readonly: readonly,
        renderingType,
        timezone: this.timezone,
        isLoadingFields: this.isLoading,
        currentDateOverride: this.currentDateOverride,
      },
      this.cloningStrategy,
    );

    const nextRenderingQuestionnaire = this.renderingQuestionnaireGenerator.get();

    if (this.renderingQuestionnaire) {
      const merged = shareQuestionnaireReferences(this.renderingQuestionnaire, nextRenderingQuestionnaire);
      this.renderingQuestionnaire = merged;

      return merged;
    } else {
      this.renderingQuestionnaire = nextRenderingQuestionnaire;
      return nextRenderingQuestionnaire;
    }
  }

  setReadonly = (value: boolean): void => {
    if (this.renderingOptions.readonly == value) {
      return;
    }
    this.renderingOptions.readonly = value;

    if (this.renderingOptions.readonly !== true) {
      defaultQuestionnaireAnswers(
        this.questionnaire,
        this.answerResolver,
        this.visibilityDependencyMap,
        this.timezone,
        null,
      );

      computedQuestionnaireAnswers(
        this.questionnaire,
        this.answerResolver,
        this.visibilityDependencyMap,
        this.timezone,
        null,
      );
    }

    const rq = this.generateRenderingQuestionnaire();
    this.renderingQuestionnaire = rq;
    this.onRenderingQuestionnaireChanged(rq);

    if (this.renderingOptions.readonly !== true) {
      this.notifyAnswerSubscribers();
    }
  };

  /**
   * Returns a boolean indicating whether the step associated to the stepId is blocking
   */
  public isStepBlocking(stepId: string): boolean {
    const allSubsections: Localized<Subsection>[] = getAllSubsections(this.questionnaire);
    const relevantStep: Localized<BlockingSubsection> | null =
      (allSubsections.find((subsection) => subsection.id === stepId) as Localized<BlockingSubsection>) ?? null;

    if (!relevantStep) throw Error(`Step not found for id ${stepId}`);

    if (typeof relevantStep.blockedIf !== 'undefined') {
      const isBlocked = evaluateConditions(
        relevantStep.blockedIf,
        this.answerResolver,
        {},
        this.timezone,
        this.currentDateOverride,
      ).isValid;
      return isBlocked;
    }

    return false;
  }

  /**
   * Returns true if the questionnaire provided with the passed answers is valid and complete, false otherwise
   * @param answers Answers
   * @returns boolean
   */
  public isQuestionnaireComplete(answers: VersionedAnswers, opts?: { enableBlockedIfConditions: boolean }): boolean {
    const allFieldAnswersAreValidAndComplete = areAllFieldsValidAndComplete(
      this.questionnaire,
      this.answerResolver,
      {
        ...this.config,
        validateAllAnswers: false,
      },
      this.timezone,
      this.currentDateOverride,
    );

    // TODO: DEV-13035 remove the check on blocking rules now that SSQ is deprecated
    if (!opts?.enableBlockedIfConditions) {
      return allFieldAnswersAreValidAndComplete;
    }

    const allSubsections = getAllSubsections(this.questionnaire);
    const noBlockingAnswers = allSubsections.every((subsection) => {
      if (isBlockingSubsection(subsection)) {
        const isBlocked = evaluateConditions(
          subsection.blockedIf,
          this.answerResolver,
          {},
          this.timezone,
          this.currentDateOverride,
        ).isValid;
        return !isBlocked;
      }
      return true;
    });

    return allFieldAnswersAreValidAndComplete && noBlockingAnswers;
  }

  /**
   * Returns the percentage of progress for the questionnaire provided with the passed answers
   * @param answers Answers
   * @returns number
   */
  public calculateProgress(isCompleted?: boolean, progressOffset?: number, landingStepId?: string): number {
    return calculateProgress(
      this.questionnaire,
      this.answerResolver,
      progressOffset,
      this.timezone,
      this.currentDateOverride,
      isCompleted,
      landingStepId,
    );
  }

  updateAnswers(items: AnswerChangeItem[]): void {
    if (this.renderingOptions.readonly === true) {
      return;
    }

    if (items.length <= 0) {
      return;
    }

    const updatedIds = [];
    for (const item of items) {
      updateAnswer(
        this.questionnaire,
        this.answerResolver,
        this.visibilityDependencyMap,
        item,
        this.timezone,
        this.currentDateOverride,
        this.applicationContext,
      );

      if (item.tag === 'nodeId') {
        updatedIds.push(item.nodeId);
      } else {
        updatedIds.push(item.blueprintId);
        if (item.nodeId) {
          updatedIds.push(item.nodeId);
        }
      }
    }

    const rq = this.generateRenderingQuestionnaire();
    this.onRenderingQuestionnaireChanged(rq);
    this.notifyAnswerSubscribers(updatedIds);
  }

  createInstanceByNodeId(nodeId: NodeId, scope: NodeIdInstanceScope): void {
    if (this.renderingOptions.readonly === true) {
      return;
    }

    this.answerResolver.usingNodeId().setInstanceId(nodeId, scope);

    const rq = this.generateRenderingQuestionnaire();
    this.onRenderingQuestionnaireChanged(rq);
    this.notifyAnswerSubscribers([nodeId]);
  }

  onBulkAnswerClear = (
    fields: RenderingField[],
    blueprintIdScope: BlueprintIdInstanceScope,
    nodeIdScope: NodeIdInstanceScope,
    effects?: EngineEffects,
  ): void => {
    if (this.renderingOptions.readonly === true) {
      return;
    }

    const updateIds = [];
    for (const field of fields) {
      const item: AnswerChangeItem = {
        tag: 'blueprintId',
        blueprintId: field.blueprintId,
        blueprintIdScope: blueprintIdScope || {},
        nodeIdScope: nodeIdScope,
        value: undefined,
        effects,
      };

      updateAnswer(
        this.questionnaire,
        this.answerResolver,
        this.visibilityDependencyMap,
        item,
        this.timezone,
        this.currentDateOverride,
        this.applicationContext,
      );
      updateIds.push(field.nodeId || field.blueprintId);
    }

    const rq = this.generateRenderingQuestionnaire();
    this.onRenderingQuestionnaireChanged(rq);
    this.notifyAnswerSubscribers(updateIds);
  };

  removeItemFromCollection(surrogateId: string, collectionNodeId: string, collectionSurrogateIdNodeId: string): void {
    if (this.renderingOptions.readonly === true) {
      return;
    }

    const repeatedCollectionAnswers = this.getRepeatedAnswers(collectionNodeId, [collectionSurrogateIdNodeId], {});

    const collectionItem = repeatedCollectionAnswers?.[surrogateId];
    if (!collectionItem) {
      throw new Error(`Could not find item ${surrogateId} from collection '${collectionNodeId}'.`);
    }

    const hasRemovedItem = this.answerResolver.usingNodeId().unsetAnswer(collectionNodeId, {
      [collectionNodeId]: collectionItem.repeatedIndex,
    });

    if (!hasRemovedItem) {
      throw new Error(
        `Unable to remove item '${surrogateId}' at index '${collectionItem.repeatedIndex}' from collection '${collectionNodeId}'.`,
      );
    }

    this.answerResolver.usingNodeId().removeUndefinedAnswersFromCollection(collectionNodeId);

    const rq = this.generateRenderingQuestionnaire();
    this.onRenderingQuestionnaireChanged(rq);
    this.notifyAnswerSubscribers([collectionSurrogateIdNodeId, collectionNodeId]);
  }

  private notifyAnswerSubscribers(nodeIds: string[] = []): void {
    for (const subscriber of this.answerChangedSubscribers) {
      void subscriber(this, nodeIds);
    }
  }
}
