import _ from 'lodash';

import { RenderingType, Answers, Timezone, QuestionnaireBlueprint } from '@breathelife/types';

import { AnswerPath, NodeIdAnswersResolver } from '../answersResolver';
import { QuestionnaireEngine } from '../external/QuestionnaireEngine';
import { Localized, TextGetter } from '../locale';
import { RenderingQuestionnaire } from '../renderingTransforms';
import { QuestionnaireDefinition } from '../structure';

function mockText(key: string): string {
  const mapping: { [key: string]: string } = {
    'validation.pastDate': 'Please enter a date earlier than today',
    'validation.canadianPostalCode': 'Invalid postal code',
    'validation.zipCode': 'Invalid zip code',
    'validation.invalidEmail': 'Invalid email',
    'validation.phoneNumber': 'Invalid phone number',
    'validation.phoneNumberAreaCode': 'Invalid area code',
    'validation.decimal': 'Needs to be a decimal',
    'validation.isTrue': 'Needs to be true',
  };
  return mapping[key];
}

function asRenderingQuestionnaire(
  questionnaire: Localized<QuestionnaireDefinition>,
  answers: Answers,
  nodeIdToAnswerPathMap: Map<string, AnswerPath> = new Map(),
  text: TextGetter = mockText,
  validateAllAnswers: boolean = false,
  blueprint: QuestionnaireBlueprint,
  timezone: Timezone,
  currentDateOverride: string | null,
): RenderingQuestionnaire {
  const answerResolver = NodeIdAnswersResolver.from(nodeIdToAnswerPathMap, blueprint, answers);

  const questionnaireEngine = QuestionnaireEngine.from({
    questionnaire,
    answerResolver,
    timezone,
    text,
    renderingOptions: {
      displayErrors: validateAllAnswers,
      renderingType: RenderingType.web,
    },
    currentDateOverride,
  });

  return questionnaireEngine.renderingQuestionnaire;
}

export const TestUtils = {
  asRenderingQuestionnaire,
};
