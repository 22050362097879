import _ from 'lodash';

import { IAnswerResolver, FieldTypes } from '@breathelife/types';

import { ExpandedContextVisitor, RepetitionIntervalBoundary } from '../expandedContext/ExpandedContextVisitor';
import { parseNumericValue } from '../modelHelper';
import { Field, QuestionnaireDefinition, RepeatableQuestionnaireNode } from '../structure';
import { Localized } from '../locale';

class FormatAnswersVisitor extends ExpandedContextVisitor {
  private static NUMERIC_FIELD_TYPES = [FieldTypes.number, FieldTypes.money];

  private answersResolver: IAnswerResolver;

  constructor(answersResolver: IAnswerResolver) {
    super(RepetitionIntervalBoundary.maxRepetitions);
    this.answersResolver = answersResolver;
  }

  protected numberOfRepetitions(repeatableNode: RepeatableQuestionnaireNode): number {
    return (
      this.answersResolver.getRepetitionCount(
        repeatableNode.blueprintId,
        this.repeatedInstanceIdentifiers().byBlueprintId,
      ) ?? 0
    );
  }

  protected visitField(field: Localized<Field>): void {
    if (FormatAnswersVisitor.NUMERIC_FIELD_TYPES.includes(field.type)) {
      const repeatedInstanceIdentifiers = this.repeatedInstanceIdentifiers();
      const answer = this.answersResolver.getAnswer(field.blueprintId, repeatedInstanceIdentifiers.byBlueprintId);

      if (typeof answer !== 'string') {
        return;
      }

      const numberAnswer = parseNumericValue(answer);
      if (isNaN(numberAnswer)) {
        // Unset NaN answers so we never save NaN when a numeric field answer is an empty or invalid string
        // Also required as part of workaround of issue with Yup rejecting optional number inputs when left empty
        const nodeInstancesToRemove = [{ id: field.blueprintId, scope: repeatedInstanceIdentifiers.byBlueprintId }];
        this.answersResolver.unsetAnswers(nodeInstancesToRemove);
      } else {
        // Replace the string answer by a number
        this.answersResolver.setAnswer(numberAnswer, field.blueprintId, repeatedInstanceIdentifiers.byBlueprintId);
      }
    }
  }
}

export function formatAnswers(
  questionnaire: Localized<QuestionnaireDefinition>,
  answersResolver: IAnswerResolver,
): void {
  const visitor = new FormatAnswersVisitor(answersResolver);
  visitor.visitQuestionnaire(questionnaire);
}
