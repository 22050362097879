import _ from 'lodash';

import { InsuranceModule, Language, FieldTypes, BlueprintId, NodeId } from '@breathelife/types';

import { RenderingField, RenderingRepeatedQuestion, RenderingSectionGroup } from './renderingTransforms';
import { Field, FieldValueTypes, QuestionnaireDefinition, Section, Subsection } from './structure';
import { Localized } from './locale';

export function getAllQuestions<
  FG extends { fields: unknown[] },
  SB extends { questions: FG[] },
  S extends { subsections: SB[] },
  SG extends { sections: S[] },
  Q extends SG[],
>(
  questionnaire: Q,
): Q extends Array<infer ISG>
  ? ISG extends { sections: Array<infer IS> }
    ? IS extends { subsections: Array<infer ISB> }
      ? ISB extends { questions: Array<infer IFG> }
        ? IFG[]
        : never
      : never
    : never
  : never {
  const subsections = getAllSubsections(questionnaire);
  return _.flatMap(subsections, 'questions') as any;
}

export function getAllRepeatableQuestions<
  FG extends { fields: unknown[]; options?: { repeatable: boolean } },
  SB extends { questions: FG[] },
  S extends { subsections: SB[] },
  SG extends { sections: S[] },
  Q extends SG[],
>(
  questionnaire: Q,
): Q extends Array<infer ISG>
  ? ISG extends { sections: Array<infer IS> }
    ? IS extends { subsections: Array<infer ISB> }
      ? ISB extends { questions: Array<infer IFG> }
        ? IFG[]
        : never
      : never
    : never
  : never {
  return getAllQuestions(questionnaire).filter((question: FG) => question.options?.repeatable) as any;
}

export function getAllFields<
  F extends object,
  FG extends { fields: F[] },
  SB extends { questions: FG[] },
  S extends { subsections: SB[] },
  SG extends { sections: S[] },
  Q extends SG[],
>(
  questionnaire: Q,
): Q extends Array<infer ISG>
  ? ISG extends { sections: Array<infer IS> }
    ? IS extends { subsections: Array<infer ISB> }
      ? ISB extends { questions: Array<infer IFG> }
        ? IFG extends { fields: Array<infer IF> }
          ? IF[]
          : never
        : never
      : never
    : never
  : never {
  const questions = getAllQuestions(questionnaire);
  return _.flatMap(questions, 'fields') as any;
}

export function getAllSections<S extends { subsections: unknown[] }, SG extends { sections: S[] }, Q extends SG[]>(
  questionnaire: Q,
): Q extends Array<infer ISG> ? (ISG extends { sections: Array<infer IS> } ? IS[] : never) : never {
  return _.flatMap(questionnaire, 'sections') as any;
}

export function getAllSubsections<
  SB extends { questions: unknown[] },
  S extends { subsections: SB[] },
  SG extends { sections: S[] },
  Q extends SG[],
>(
  questionnaire: Q,
): Q extends Array<infer ISG>
  ? ISG extends { sections: Array<infer IS> }
    ? IS extends { subsections: Array<infer ISB> }
      ? ISB[]
      : never
    : never
  : never {
  const sections = getAllSections(questionnaire);
  return _.flatMap(sections, 'subsections') as any;
}

export function getContainingSection(
  subsectionId: string,
  questionnaire: QuestionnaireDefinition,
): Section | undefined {
  const allSections = getAllSections(questionnaire);
  return allSections.find((section) => section.subsections.some(({ id }) => id === subsectionId));
}

export function findSection<
  S extends { id: string; subsections: unknown[] },
  SG extends { sections: S[] },
  Q extends SG[],
>(
  id: string,
  questionnaire: Q,
): Q extends Array<infer ISG> ? (ISG extends { sections: Array<infer IS> } ? IS | undefined : never) : never {
  return getAllSections(questionnaire).find((section) => section.id === id) as any;
}

function findSubsection(id: string, section: Section): Subsection | undefined {
  return section.subsections.find((subsection: Subsection): boolean => subsection.id === id);
}

export function findSubsectionInQuestionnaire(
  id: string,
  questionnaire: QuestionnaireDefinition,
): Subsection | undefined {
  let subsection: Subsection | undefined;
  questionnaire.some((sectionGroup) =>
    sectionGroup.sections.some((section) => {
      subsection = findSubsection(id, section);
      return !!subsection;
    }),
  );
  return subsection;
}

export function findField<
  F extends { id: string },
  FG extends { fields: F[] },
  SB extends { questions: FG[] },
  S extends { subsections: SB[] },
  SG extends { sections: S[] },
  Q extends SG[],
>(
  id: string,
  questionnaire: Q,
): Q extends Array<infer ISG>
  ? ISG extends { sections: Array<infer IS> }
    ? IS extends { subsections: Array<infer ISB> }
      ? ISB extends { questions: Array<infer IFG> }
        ? IFG extends { fields: Array<infer IF> }
          ? IF | undefined
          : never
        : never
      : never
    : never
  : never {
  const allFields: Field[] = getAllFields(questionnaire);
  return allFields.find((field: Field) => field.id === id) as any;
}

export function findFieldInQuestion<F extends { id: string }, FG extends { fields: F[] }>(
  id: string,
  question: FG,
): FG extends { fields: Array<infer IF> } ? IF | undefined : never {
  return question.fields.find((field: F): boolean => field.id === id) as any;
}

export function sectionGroupHasSections(sectionGroup: RenderingSectionGroup): boolean {
  return sectionGroup.sections.length > 0;
}

export function getRepeatedQuestionIndex(question: RenderingRepeatedQuestion): number {
  return question.metadata.repetitionIndex;
}

// Returns the initial 'blank' field value depending on the field value type
// This is needed because Material-UI components have display issues when given `undefined` as a value
// https://app.asana.com/0/0/1112224517643558/f
export function getInitialFieldValue(field: RenderingField | Field | Localized<Field>): any {
  const answerType: FieldValueTypes = getFieldValueType(field);
  switch (answerType) {
    case FieldValueTypes.string:
      return '';
    case FieldValueTypes.boolean:
      return false;
    case FieldValueTypes.array:
      return [];
  }
}

// Returns the type of the value expected by the UI for a given field
// Note that this only represents the type used in the UI and is not indicative of the type of our persisted answers
// For example, a number field uses a text input (meaning the UI expects a string) but will be persisted as a number
export function getFieldValueType(field: RenderingField | Field | Localized<Field>): FieldValueTypes {
  switch (field.type) {
    case FieldTypes.agree:
    case FieldTypes.checkbox:
      return FieldValueTypes.boolean;
    case FieldTypes.checkboxGroup:
      return FieldValueTypes.array;
    default:
      return FieldValueTypes.string;
  }
}

export function getFieldTitlesByNodeId(questionnaire: QuestionnaireDefinition, lang: Language): Map<NodeId, string> {
  const fieldTitlesByNodeId = new Map<BlueprintId, string>();
  questionnaire.forEach((sectionGroup) => {
    sectionGroup.sections.forEach((section) => {
      section.subsections.forEach((subsection) => {
        subsection.questions.forEach((question) => {
          question.fields.forEach((field) => {
            if (!field.nodeId) {
              return;
            }
            if (fieldTitlesByNodeId.has(field.nodeId)) {
              return; // Already have a title for this nodeId.
            }
            let title = '';
            if (field.title?.[lang]) {
              title = field.title[lang];
            } else if (field.text?.[lang]) {
              title = field.text[lang];
            } else if (question.title?.[lang]) {
              title = question.title?.[lang];
            } else if (question.text?.[lang]) {
              title = question.text?.[lang];
            } else {
              // use the nodeId as the fallback as it will be easier to debug if we ever end up in that situation where
              // field and question do not have title/subtitle information.
              title = field.nodeId;
            }
            fieldTitlesByNodeId.set(field.nodeId, title);
          });
        });
      });
    });
  });
  return fieldTitlesByNodeId;
}

export function getSubsectionInsuranceModules(
  subsectionId: string,
  questionnaire: QuestionnaireDefinition,
): InsuranceModule[] {
  const parentSection = getContainingSection(subsectionId, questionnaire);
  return parentSection?.insuranceModuleFilter ?? [];
}
