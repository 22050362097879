import _ from 'lodash';

import {
  BlueprintConditionValue,
  Localizable,
  QuestionBlueprint,
  SubsectionBlueprint,
  isSubsectionBlueprint,
} from '@breathelife/types';

import FieldBuilder from './FieldBuilder';
import NodeBuilder from './NodeBuilder';
import QuestionBuilder from './QuestionBuilder';
import { SeedBlueprintProviders } from './SeedProviders';
import { v4 as UUID } from 'uuid';

export default class SubsectionBuilder extends NodeBuilder<SubsectionBlueprint> {
  private readonly seedProviders: SeedBlueprintProviders;

  public constructor(seedProviders: SeedBlueprintProviders) {
    super(seedProviders.forSubsection());
    this.seedProviders = seedProviders;
    this.node.id = UUID();
  }

  public with(nodeBuilder: QuestionBuilder | FieldBuilder): this {
    if (nodeBuilder instanceof QuestionBuilder) {
      return this.withQuestions(nodeBuilder);
    }
    return this.withQuestions(this.childBuilder().with(nodeBuilder));
  }

  public withQuestions(questionBuilders?: QuestionBuilder[] | QuestionBuilder): this {
    const builders = questionBuilders ? _.castArray(questionBuilders) : [];

    if (builders.length === 0) {
      builders.push(this.childBuilder());
    }

    const questions = builders.map((builder) => builder.build());
    this.addQuestions(questions);

    return this.clone();
  }

  public withFields(fieldBuilders?: FieldBuilder[] | FieldBuilder): this {
    this.addQuestions(this.childBuilder().withFields(fieldBuilders).build());

    return this.clone();
  }

  private childBuilder(): QuestionBuilder {
    return new QuestionBuilder(this.seedProviders);
  }

  private addQuestions(questions: QuestionBlueprint | QuestionBlueprint[]): void {
    if (!this.node.questions) this.node.questions = [];

    const questionList = _.castArray(questions);
    this.node.questions.push(...questionList);
  }

  public withTitle(title: Localizable): this {
    this.node.title = title;
    return this.clone();
  }

  public withPartName(partname: string): this {
    this.node.partName = partname;
    return this.clone();
  }

  public visibleIf(condition: BlueprintConditionValue): this {
    this.node.visible = condition;

    return this.clone();
  }

  public withProperties(properties: Partial<SubsectionBlueprint>): this {
    Object.assign(this.node, properties);
    return this.clone();
  }

  public validate(subsection: SubsectionBlueprint): subsection is SubsectionBlueprint {
    return isSubsectionBlueprint(subsection);
  }

  public withText(text: Localizable): this {
    this.node.text = text;
    return this.clone();
  }
}
