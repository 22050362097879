import { IReadOnlyAnswerResolver } from '@breathelife/types';

import { ExpandedContextVisitor, RepetitionIntervalBoundary } from '../expandedContext/ExpandedContextVisitor';
import { isRepeatableOptionsBasedOnCollection, RepeatableQuestionnaireNode } from '../structure';

export abstract class ExpandedRepetitionsVisitor extends ExpandedContextVisitor {
  protected readonly answersResolver: IReadOnlyAnswerResolver;

  constructor(answersResolver: IReadOnlyAnswerResolver) {
    super(RepetitionIntervalBoundary.minRepetitions);
    this.answersResolver = answersResolver;
  }

  protected numberOfRepetitions(repeatableNode: RepeatableQuestionnaireNode): number {
    const { options } = repeatableNode;
    if (isRepeatableOptionsBasedOnCollection(options)) {
      return (
        this.answersResolver
          .usingNodeId()
          .getRepetitionCount(options.expandToCollectionLength, this.repeatedInstanceIdentifiers().byNodeId) ?? 0
      );
    }
    const boundaryNumberOfRepetitions: number = super.numberOfRepetitions(repeatableNode);
    const existingNumberOfRepetitions: number = this.existingNumberOfRepetitions(repeatableNode);
    return Math.max(boundaryNumberOfRepetitions, existingNumberOfRepetitions);
  }

  private existingNumberOfRepetitions(repeatableNode: RepeatableQuestionnaireNode): number {
    return (
      this.answersResolver
        .usingNodeId()
        .getRepetitionCount(repeatableNode.nodeId, this.repeatedInstanceIdentifiers().byNodeId) ?? 0
    );
  }
}
