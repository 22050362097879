import { NodeIdAnswersResolver } from '../answersResolver';
import { ExpandedContextVisitor, RepetitionIntervalBoundary } from '../expandedContext/ExpandedContextVisitor';
import {
  Field,
  isQuestionRepeatable,
  isSectionGroupRepeatable,
  Question,
  RepeatableQuestion,
  RepeatableQuestionnaireNode,
  RepeatableSectionGroup,
  Section,
  SectionGroup,
  SelectOption,
  Subsection,
} from '../structure';
import { ExtractRuleFunction, NodeEvaluationVisit, NodeType, VisitNodeFunction } from './NodeEvaluationVisit';
import { Localized } from '../locale';

interface EvaluationVisitor<TOut, TCompleteOutput> {
  visitDefault: VisitNodeFunction<any, TOut>;
  visitSectionGroup?: VisitNodeFunction<SectionGroup, TOut>;
  visitSection?: VisitNodeFunction<Section, TOut>;
  visitSubsection?: VisitNodeFunction<Subsection, TOut>;
  visitQuestion?: VisitNodeFunction<Question, TOut>;
  visitField?: VisitNodeFunction<Field, TOut>;
  visitOption?: VisitNodeFunction<SelectOption, TOut>;
  complete: () => TCompleteOutput;
}

class EvaluationVisitorAdapter<TOut> extends ExpandedContextVisitor {
  private readonly answersResolver: NodeIdAnswersResolver;
  private readonly nodeEvaluationVisit: NodeEvaluationVisit<TOut, EvaluationVisitor<TOut, unknown>>;

  public constructor(
    answersResolver: NodeIdAnswersResolver,
    evalVisitor: EvaluationVisitor<TOut, unknown>,
    extractRule?: ExtractRuleFunction,
  ) {
    super(RepetitionIntervalBoundary.maxRepetitions);
    this.nodeEvaluationVisit = new NodeEvaluationVisit(answersResolver, evalVisitor, extractRule);
    this.answersResolver = answersResolver;
  }

  protected numberOfRepetitions(repeatableNode: RepeatableQuestionnaireNode): number {
    return (
      this.answersResolver.getRepetitionCount(
        repeatableNode.blueprintId,
        this.repeatedInstanceIdentifiers().byBlueprintId,
      ) ?? 0
    );
  }

  public visitQuestionnaire(questionnaire: Localized<SectionGroup>[]): void {
    return super.visitQuestionnaire(questionnaire);
  }

  protected visitSectionGroup(sectionGroup: Localized<SectionGroup>): void {
    if (!isSectionGroupRepeatable(sectionGroup)) {
      this.nodeEvaluationVisit.visitFor(sectionGroup, () => super.visitSectionGroup(sectionGroup), {
        nodeType: NodeType.SectionGroup,
        repeatedInstanceIdentifiers: this.repeatedInstanceIdentifiers().byBlueprintId,
      });
    } else {
      super.visitSectionGroup(sectionGroup);
    }
  }

  protected visitRepeatedSectionGroup(sectionGroup: Localized<RepeatableSectionGroup>): void {
    this.nodeEvaluationVisit.visitFor(sectionGroup, () => super.visitRepeatedSectionGroup(sectionGroup), {
      nodeType: NodeType.SectionGroup,
      repeatedInstanceIdentifiers: this.repeatedInstanceIdentifiers().byBlueprintId,
    });
  }

  protected visitSection(section: Localized<Section>): void {
    this.nodeEvaluationVisit.visitFor(section, () => super.visitSection(section), {
      nodeType: NodeType.Section,
      repeatedInstanceIdentifiers: this.repeatedInstanceIdentifiers().byBlueprintId,
    });
  }

  protected visitSubsection(subsection: Localized<Subsection>): void {
    this.nodeEvaluationVisit.visitFor(subsection, () => super.visitSubsection(subsection), {
      nodeType: NodeType.Subsection,
      repeatedInstanceIdentifiers: this.repeatedInstanceIdentifiers().byBlueprintId,
    });
  }

  protected visitQuestion(question: Localized<Question>): void {
    if (!isQuestionRepeatable(question)) {
      this.nodeEvaluationVisit.visitFor(question, () => super.visitQuestion(question), {
        nodeType: NodeType.Question,
        repeatedInstanceIdentifiers: this.repeatedInstanceIdentifiers().byBlueprintId,
      });
    } else {
      super.visitQuestion(question);
    }
  }

  protected visitRepeatedQuestion(question: Localized<RepeatableQuestion>): void {
    this.nodeEvaluationVisit.visitFor(question, () => super.visitRepeatedQuestion(question), {
      nodeType: NodeType.Question,
      repeatedInstanceIdentifiers: this.repeatedInstanceIdentifiers().byBlueprintId,
    });
  }

  protected visitField(field: Localized<Field>): void {
    this.nodeEvaluationVisit.visitFor(field, () => super.visitField(field), {
      nodeType: NodeType.Field,
      repeatedInstanceIdentifiers: this.repeatedInstanceIdentifiers().byBlueprintId,
    });
  }

  protected visitOption(option: Localized<SelectOption>): void {
    this.nodeEvaluationVisit.visitFor(option, () => super.visitOption(option), {
      nodeType: NodeType.Option,
      repeatedInstanceIdentifiers: this.repeatedInstanceIdentifiers().byBlueprintId,
    });
  }
}

export { EvaluationVisitor, EvaluationVisitorAdapter, VisitNodeFunction };
