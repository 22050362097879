import _ from 'lodash';

import { IAnswerResolver, SubsectionVariant, Timezone } from '@breathelife/types';

import { getAllSubsections } from './questionnaire';
import { QuestionnaireDefinition } from './structure';
import { Localized } from './locale';
import { QuestionnaireEngine } from './external/QuestionnaireEngine';

const INFORMATIONAL_SUBSECTION_VARIANTS = [
  SubsectionVariant.productSummary,
  SubsectionVariant.priceUpdateWarning,
  SubsectionVariant.summary,
  SubsectionVariant.submission,
];

export function calculateProgress(
  questionnaire: Localized<QuestionnaireDefinition>,
  answerResolver: IAnswerResolver,
  progressOffset = 0,
  timezone: Timezone,
  currentDateOverride: string | null,
  isCompleted?: boolean,
  landingStepId?: string,
): number {
  let progress: number;

  if (isCompleted) {
    return 100;
  }

  const questionnaireEngine = QuestionnaireEngine.from({
    questionnaire,
    answerResolver,
    timezone,
    currentDateOverride,
    text: () => '',
  });

  const renderingQuestionnaire = questionnaireEngine.renderingQuestionnaire;
  const allSubsections = getAllSubsections(renderingQuestionnaire);
  const allVisibleSubsections = allSubsections.filter((step) => step.visible);

  const allSubsectionsContributingToProgress = allVisibleSubsections.filter(
    (subsection) => !INFORMATIONAL_SUBSECTION_VARIANTS.includes(subsection.variant ?? SubsectionVariant.form),
  );
  const completedSubsections = allSubsectionsContributingToProgress.filter((step) => step.completed);

  if (!progressOffset) {
    progress = Math.round((completedSubsections.length / allSubsectionsContributingToProgress.length) * 100);
  } else {
    const isLandingStepIdCompleted = completedSubsections.some((step) => step.id === landingStepId);
    const completedSubsectionsBeforeApplicationFill = !isLandingStepIdCompleted ? completedSubsections.length : 0;

    const completedSubsectionsAfterApplicationFill =
      completedSubsections.length - completedSubsectionsBeforeApplicationFill;
    const allSubsectionsAfterApplicationFill =
      allSubsectionsContributingToProgress.length - completedSubsectionsBeforeApplicationFill;

    progress = Math.round(
      (completedSubsectionsAfterApplicationFill / allSubsectionsAfterApplicationFill) * (100 - progressOffset) +
        progressOffset,
    );
  }

  if (!_.isFinite(progress)) progress = 0;
  return progress;
}
