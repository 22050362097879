import { v4 as uuid } from 'uuid';

import { evaluateConditions } from '@breathelife/condition-engine';
import { BooleanOperator, InstanceScope, BothNodeInstance, Timezone, IAnswerResolver } from '@breathelife/types';

import { ExpandedRepetitionsVisitor } from '../expandedContext/ExpandedRepetitionsVisitor';
import { getConditionalDefaultValueForField } from '../nodeEvaluation/defaultIf/defaultIf';
import { VisibilityDependencyMap } from '../nodeEvaluation/visibleIf/dependencyMap';
import { filterVisibleAnswers } from '../nodeEvaluation/visibleIf/filterVisibleAnswers';
import { getInitialFieldValue } from '../questionnaire';
import {
  Field,
  isRepeatableOptionsBasedOnCollection,
  QuestionnaireDefinition,
  RepeatableQuestion,
  RepeatableSectionGroup,
} from '../structure';
import { Localized } from '../locale';

export class DefaultAnswersVisitor extends ExpandedRepetitionsVisitor {
  private visibilityDependencyMap: VisibilityDependencyMap;
  private changedNodeInstances: BothNodeInstance[] = [];
  private readonly timezone: Timezone;
  readonly answersResolver: IAnswerResolver; // This is shadowing the base class property because it requires more permission here.
  private readonly currentDateOverride: string | null;

  constructor(
    answersResolver: IAnswerResolver,
    visibilityDependencyMap: VisibilityDependencyMap,
    timezone: Timezone,
    currentDateOverride: string | null,
  ) {
    super(answersResolver);
    this.answersResolver = answersResolver;
    this.visibilityDependencyMap = visibilityDependencyMap;
    this.timezone = timezone;
    this.currentDateOverride = currentDateOverride;
  }

  public getChangedNodeInstances(): BothNodeInstance[] {
    return [...this.changedNodeInstances];
  }

  protected visitRepeatedSectionGroup(sectionGroup: Localized<RepeatableSectionGroup>): void {
    if (!sectionGroup.readOnly) {
      this.setDefaultSurrogateId(sectionGroup.blueprintId);
      super.visitRepeatedSectionGroup(sectionGroup);
    }
  }

  protected visitRepeatedQuestion(question: Localized<RepeatableQuestion>, repetitionIndex: number): void {
    if (!question.readOnly) {
      if (isRepeatableOptionsBasedOnCollection(question.options)) {
        this.setDefaultSurrogateId(question.blueprintId, question.options.expandToCollectionLength, repetitionIndex);
      } else {
        this.setDefaultSurrogateId(question.blueprintId);
      }

      super.visitRepeatedQuestion(question, repetitionIndex);
    }
  }

  protected visitField(field: Localized<Field>): void {
    this.setDefaultFieldValue(field);
  }

  private setDefaultSurrogateId(blueprintId: string, collectionBlueprintId?: string, repetitionIndex?: number): void {
    const scope = this.repeatedInstanceIdentifiers();
    if (typeof scope.byBlueprintId[blueprintId] === 'undefined') {
      // If no index is provided we cannot create a surrogateId for a collection item.
      return;
    }

    const result = this.answersResolver.getInstanceId(blueprintId, scope.byBlueprintId);

    if (!result.success) {
      let surrogateId: string | undefined = undefined;
      if (scope && collectionBlueprintId && repetitionIndex) {
        //If we have an index and a collection ID, use the surrogateId from the same index in the collection.
        const correspondingItemInAnotherCollectionItem = this.answersResolver.getAnswer(collectionBlueprintId, {
          [collectionBlueprintId]: repetitionIndex,
        });
        surrogateId = correspondingItemInAnotherCollectionItem?.surrogateId || uuid();
      } else {
        surrogateId = uuid();
      }

      this.answersResolver.setInstanceId(blueprintId, scope.byBlueprintId, surrogateId);
    }
  }

  private setDefaultFieldValue(field: Localized<Field>): void {
    const { defaultValue: defaultFieldValue, nodeId, defaultIf, blueprintId } = field;

    if (!nodeId) {
      return;
    }

    if (typeof defaultFieldValue === 'undefined' && typeof defaultIf === 'undefined') {
      return;
    }

    const scopes = this.repeatedInstanceIdentifiers();
    if (!this.isFieldVisible(field, scopes.byNodeId)) {
      // Only set defaults if the field is visible.
      return;
    }

    const defaultValue = field.defaultIf
      ? getConditionalDefaultValueForField(
          field.defaultIf,
          this.answersResolver,
          scopes.byNodeId,
          this.timezone,
          this.currentDateOverride,
        )
      : defaultFieldValue;

    const currentAnswer = this.answersResolver.usingNodeId().getAnswer(nodeId, scopes.byNodeId);
    const initialFieldValue = getInitialFieldValue(field);

    if (
      currentAnswer === defaultValue ||
      (typeof currentAnswer !== 'undefined' && currentAnswer !== initialFieldValue)
    ) {
      return;
    }

    this.answersResolver.usingNodeId().setAnswer(defaultValue, nodeId, scopes.byNodeId);
    this.changedNodeInstances.push({
      byBlueprintId: {
        blueprintId: blueprintId,
        blueprintIdScope: scopes.byBlueprintId,
      },
      byNodeId: {
        nodeId: field.nodeId,
        nodeIdScope: scopes.byNodeId,
      },
    });
  }

  private isFieldVisible(field: Localized<Field>, scope: InstanceScope): boolean {
    let fieldVisibilityConditions = undefined;
    if (field.nodeId) {
      fieldVisibilityConditions = this.visibilityDependencyMap.getVisibilityConditions(field.nodeId);
    }
    const visibilityConditions = fieldVisibilityConditions?.field;
    const isFieldVisible =
      !visibilityConditions ||
      evaluateConditions(
        { conditions: visibilityConditions, operator: BooleanOperator.or },
        this.answersResolver,
        scope,
        this.timezone,
        this.currentDateOverride,
      ).isValid;
    return isFieldVisible;
  }
}

export function defaultQuestionnaireAnswers(
  questionnaire: Localized<QuestionnaireDefinition>,
  answersResolver: IAnswerResolver,
  dependencyMap: VisibilityDependencyMap,
  timezone: Timezone,
  currentDateOverride: string | null,
): void {
  const defaultAnswersVisitor = new DefaultAnswersVisitor(
    answersResolver,
    dependencyMap,
    timezone,
    currentDateOverride,
  );
  defaultAnswersVisitor.visitQuestionnaire(questionnaire);

  // Setting defaults may affect the visibility of other answers.
  for (const nodeInstance of defaultAnswersVisitor.getChangedNodeInstances()) {
    if (nodeInstance.byNodeId.nodeId) {
      filterVisibleAnswers(
        nodeInstance.byNodeId.nodeId,
        dependencyMap,
        answersResolver,
        nodeInstance.byNodeId.nodeIdScope,
        timezone,
        currentDateOverride,
      );
    }
  }
}
