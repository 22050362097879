import { Result, failure, success } from '@breathelife/result';
import {
  IAnswerResolver,
  InstanceScope,
  RepeatedAnswersBySurrogateId,
  Answers,
  SurrogateId,
  BlueprintId,
  NodeId,
  QuestionnaireBlueprint,
  NodeInstance,
  BlueprintIdInstanceScope,
  NodeIdInstanceScope,
  InstanceIndex,
  VersionedAnswers,
  AnswersResolverLogger,
} from '@breathelife/types';
import { AnswersStorage, TranslationError } from './AnswersStorage';
import { translateBlueprintIdToNodeId } from './translateBlueprintIdToNodeId';
import { AnswerPath } from './AnswerPath';

export class NodeIdAnswersResolver implements IAnswerResolver {
  private readonly storage: AnswersStorage;
  private readonly blueprintIdToNodeIdMap: Record<BlueprintId, NodeId | undefined>;

  private static readonly NODE_REFERENCE_PATH_PREFIX = '';
  private logger: AnswersResolverLogger | undefined;

  static buildReferencePathKey(value: string | number): string {
    return `${NodeIdAnswersResolver.NODE_REFERENCE_PATH_PREFIX}${value}`;
  }

  private constructor(
    answersStorage: AnswersStorage,
    blueprintIdToNodeIdMap: Record<BlueprintId, NodeId | undefined>,
    logger?: AnswersResolverLogger,
  ) {
    this.storage = answersStorage;
    this.blueprintIdToNodeIdMap = blueprintIdToNodeIdMap;
    this.logger = logger;
  }

  static from(
    nodeIdToAnswerPath: Map<NodeId, AnswerPath>,
    blueprint: QuestionnaireBlueprint,
    answers: Answers,
    logger?: AnswersResolverLogger,
  ): NodeIdAnswersResolver {
    const blueprintIdToNodeIdMap = translateBlueprintIdToNodeId(blueprint);

    const answersStorage = new AnswersStorage(answers, nodeIdToAnswerPath, true, logger);

    return new NodeIdAnswersResolver(answersStorage, blueprintIdToNodeIdMap, logger);
  }

  clone(withAnswers?: VersionedAnswers): NodeIdAnswersResolver {
    const clonedStorage = this.storage.clone(withAnswers?.v1);

    return new NodeIdAnswersResolver(clonedStorage, this.blueprintIdToNodeIdMap, this.logger);
  }

  dump(): VersionedAnswers {
    return new VersionedAnswers({ v1: this.storage.dump(), v2: {} });
  }

  setAnswers(
    items: { blueprintId: BlueprintId; value: unknown }[],
    blueprintIdInstanceIdentifiers?: BlueprintIdInstanceScope | undefined,
  ): void {
    for (const item of items) {
      // It is not translated in here because it delegates to the single answer function that will do the translation.
      this.setAnswer(item.value, item.blueprintId, blueprintIdInstanceIdentifiers);
    }
  }

  getInstanceId(
    blueprintId: BlueprintId,
    blueprintIdInstanceIdentifiers: BlueprintIdInstanceScope,
  ): Result<string, SurrogateId | undefined> {
    const nodeId = this.blueprintIdToNodeIdMap[blueprintId];

    if (!nodeId) {
      return failure(`Could not find a valid blueprint id for the "${nodeId}" while trying to get the surrogate id.`);
    }

    let translatedCollectionIdentifiers: NodeIdInstanceScope = {};

    if (blueprintIdInstanceIdentifiers) {
      const result = this.translateInstanceIdentifiers(blueprintIdInstanceIdentifiers);
      if (result.success === false) {
        return failure(
          `Could not translate the id "${result.error.id}" from the collection instance identifiers while trying to get the surrogate id.`,
        );
      }
      translatedCollectionIdentifiers = result.value;
    }

    return this.storage.getInstanceId(nodeId, translatedCollectionIdentifiers);
  }

  setInstanceId(
    blueprintId: BlueprintId,
    blueprintIdInstanceIdentifiers: BlueprintIdInstanceScope,
    forceInstanceId?: SurrogateId | undefined,
  ): Result<string, SurrogateId> {
    const nodeId = this.blueprintIdToNodeIdMap[blueprintId];

    if (!nodeId) {
      return failure(`Could not find a valid node id for the "${blueprintId}" while trying to set the surrogate id.`);
    }

    let translatedCollectionIdentifiers: NodeIdInstanceScope = {};

    if (blueprintIdInstanceIdentifiers) {
      const result = this.translateInstanceIdentifiers(blueprintIdInstanceIdentifiers);
      if (result.success === false) {
        return failure(
          `Could not translate the id "${result.error.id}" from the collection instance identifiers while trying to set the surrogate id.`,
        );
      }
      translatedCollectionIdentifiers = result.value;
    }

    return this.storage.setInstanceId(nodeId, translatedCollectionIdentifiers, forceInstanceId);
  }

  knowsId(blueprintId: BlueprintId): boolean {
    const nodeId = this.blueprintIdToNodeIdMap[blueprintId];

    if (!nodeId) {
      return false;
    }

    return this.storage.knowsId(nodeId);
  }

  getAnswer(
    blueprintId: BlueprintId,
    blueprintIdInstanceIdentifiers?: BlueprintIdInstanceScope,
    skipLeafIdentifier?: boolean,
  ): any | undefined {
    const nodeId = this.blueprintIdToNodeIdMap[blueprintId];

    if (!nodeId) {
      // { BLUEPRINT-ANSWERS: ASDASDSADSDA-ASDASDSA-DSADSASD.0.ADSSADAS-DASSADSADS-DSADSADSADSD.0.SDAASDASD-DSADSADSADASD-DSSA }
      return undefined; // TODO: HOT-4969 WHEN THERE'S NO CORRESPONDANCE GET IN THE BLUEPRINT WITH LODASH PATH.
    }

    let translatedCollectionIdentifiers: NodeIdInstanceScope | undefined;

    if (blueprintIdInstanceIdentifiers) {
      const result = this.translateInstanceIdentifiers(blueprintIdInstanceIdentifiers);
      if (result.success === false) {
        return undefined;
      }
      translatedCollectionIdentifiers = result.value;
    }

    return this.storage.getAnswer(nodeId, translatedCollectionIdentifiers, skipLeafIdentifier);
  }

  getCollection(
    blueprintId: BlueprintId,
    blueprintIdInstanceIdentifiers?: BlueprintIdInstanceScope,
  ): any[] | undefined {
    const nodeId = this.blueprintIdToNodeIdMap[blueprintId];

    if (!nodeId) {
      return undefined;
    }

    let translatedCollectionIdentifiers: NodeIdInstanceScope | undefined;

    if (blueprintIdInstanceIdentifiers) {
      const result = this.translateInstanceIdentifiers(blueprintIdInstanceIdentifiers);
      if (result.success === false) {
        return undefined;
      }
      translatedCollectionIdentifiers = result.value;
    }

    return this.storage.getCollection(nodeId, translatedCollectionIdentifiers);
  }

  getRepeatedAnswers<T extends string>(
    collectionBlueprintId: BlueprintId,
    blueprintIds: T[],
    blueprintIdInstanceIdentifiers: BlueprintIdInstanceScope,
  ): RepeatedAnswersBySurrogateId<T> | undefined {
    const collectionNodeId = this.blueprintIdToNodeIdMap[collectionBlueprintId];

    const translatedCollectionIdentifiers = this.translateInstanceIdentifiers(blueprintIdInstanceIdentifiers);

    const nodeIdToBlueprintId: Record<NodeId, BlueprintId> = {};
    for (const blueprintId of blueprintIds) {
      const nodeId = this.blueprintIdToNodeIdMap[blueprintId];
      if (nodeId) {
        nodeIdToBlueprintId[nodeId] = blueprintId;
      }
    }

    const translatedIds = Object.keys(nodeIdToBlueprintId);

    if (!collectionNodeId || !translatedCollectionIdentifiers.success || translatedIds.some((e) => e === undefined)) {
      return undefined;
    }

    const nodeIdRepeatedAnswers = this.storage.getRepeatedAnswers(
      collectionNodeId,
      translatedIds,
      translatedCollectionIdentifiers.value,
    );

    if (!nodeIdRepeatedAnswers) {
      return undefined;
    }

    const blueprintIdRepeatedAnswers: RepeatedAnswersBySurrogateId<T> = {};
    Object.keys(nodeIdRepeatedAnswers).forEach((surrogateId: string) => {
      const current = nodeIdRepeatedAnswers[surrogateId];

      const newAnswers: Partial<Record<string, any>> = {};
      Object.keys(current.answersByNodeId).forEach((nodeId: string) => {
        const blueprintId = nodeIdToBlueprintId[nodeId];
        newAnswers[blueprintId] = current.answersByNodeId[nodeId];
      });

      blueprintIdRepeatedAnswers[surrogateId] = {
        answersByNodeId: newAnswers,
        repeatedIndex: current.repeatedIndex,
      };
    });

    return blueprintIdRepeatedAnswers;
  }

  getRepetitionCount(
    collectionBlueprintId: BlueprintId,
    blueprintIdInstanceIdentifiers: BlueprintIdInstanceScope,
  ): number | undefined {
    const collectionNodeId = this.blueprintIdToNodeIdMap[collectionBlueprintId];

    const translatedCollectionIdentifiers = this.translateInstanceIdentifiers(blueprintIdInstanceIdentifiers);

    if (collectionNodeId && translatedCollectionIdentifiers.success) {
      return this.storage.getRepetitionCount(collectionNodeId, translatedCollectionIdentifiers.value);
    }
  }

  setAnswer(
    value: unknown,
    blueprintId: NodeId,
    blueprintIdInstanceIdentifiers?: BlueprintIdInstanceScope,
    answersOverride?: Answers,
  ): void {
    const nodeId = this.blueprintIdToNodeIdMap[blueprintId];

    if (!nodeId) {
      // { BLUEPRINT-ANSWERS: ASDASDSADSDA-ASDASDSA-DSADSASD.0.ADSSADAS-DASSADSADS-DSADSADSADSD.0.SDAASDASD-DSADSADSADASD-DSSA }
      return; // TODO: HOT-4969  WHEN THERE'S NO CORRESPONDANCE SAVE IN THE BLUEPRINT WITH LODASH PATH.
    }

    let translatedCollectionIdentifiers: NodeIdInstanceScope | undefined;

    if (blueprintIdInstanceIdentifiers) {
      const result = this.translateInstanceIdentifiers(blueprintIdInstanceIdentifiers);
      if (result.success === false) {
        return undefined;
      }
      translatedCollectionIdentifiers = result.value;
    }

    this.storage.setAnswer(value, nodeId, translatedCollectionIdentifiers, answersOverride);
  }

  unsetAnswer(blueprintId: BlueprintId, blueprintIdInstanceIdentifiers?: BlueprintIdInstanceScope): boolean {
    const nodeId = this.blueprintIdToNodeIdMap[blueprintId];

    if (!nodeId) {
      return false;
    }

    let translatedCollectionIdentifiers: NodeIdInstanceScope | undefined;

    if (blueprintIdInstanceIdentifiers) {
      const result = this.translateInstanceIdentifiers(blueprintIdInstanceIdentifiers);
      if (result.success === false) {
        return false;
      }
      translatedCollectionIdentifiers = result.value;
    }

    return this.storage.unsetAnswer(nodeId, translatedCollectionIdentifiers);
  }

  unsetAnswers(nodeInstancesToRemove: NodeInstance[]): NodeInstance[] {
    const removedNodeInstances: NodeInstance[] = [];

    for (const nodeInstance of nodeInstancesToRemove) {
      const { id, scope } = nodeInstance;

      // Does not need to translate, it's calling "unsertAnswer" which does the translation.
      const wasRemoved = this.unsetAnswer(id, scope);

      if (wasRemoved) {
        removedNodeInstances.push(nodeInstance);
      }
    }

    return removedNodeInstances;
  }

  unsetAnswerSelectOptionId(
    blueprintId: string,
    optionId: string,
    blueprintIdInstanceIdentifiers?: InstanceScope,
  ): boolean {
    const nodeId = this.blueprintIdToNodeIdMap[blueprintId];

    if (!nodeId) {
      return false;
    }

    let translatedCollectionIdentifiers: NodeIdInstanceScope | undefined;

    if (blueprintIdInstanceIdentifiers) {
      const result = this.translateInstanceIdentifiers(blueprintIdInstanceIdentifiers);
      if (result.success === false) {
        return false;
      }
      translatedCollectionIdentifiers = result.value;
    }

    return this.storage.unsetAnswerSelectOptionId(nodeId, optionId, translatedCollectionIdentifiers);
  }

  withCollectionIdentifier(
    identifiers: BlueprintIdInstanceScope,
    collectionBlueprintId: string,
    collectionIdentifierIndex: InstanceIndex,
  ): InstanceScope {
    const translatedCollectionNodeId = this.blueprintIdToNodeIdMap[collectionBlueprintId];
    const translatedIdentifiers = this.translateInstanceIdentifiers(identifiers);

    const nodeIdToBlueprintId: Record<string, string> = {};
    const blueprintIds = [...Object.keys(identifiers), collectionBlueprintId];
    for (const blueprintId of blueprintIds) {
      const nodeId = this.blueprintIdToNodeIdMap[blueprintId];
      if (nodeId) {
        nodeIdToBlueprintId[nodeId] = blueprintId;
      }
    }

    if (!translatedCollectionNodeId || translatedIdentifiers.success === false) {
      throw new Error(`Could not find a blueprintId corresponding to the nodeId ${collectionBlueprintId}`);
    }

    const nodeIdCollectionIdentifiers = this.storage.withCollectionIdentifier(
      translatedIdentifiers.value,
      collectionIdentifierIndex,
      translatedCollectionNodeId,
    );

    const blueprintIdCollectionIdentifiers: Record<string, number> = {};
    Object.keys(nodeIdCollectionIdentifiers).forEach((nodeId) => {
      blueprintIdCollectionIdentifiers[nodeIdToBlueprintId[nodeId]] = nodeIdCollectionIdentifiers[nodeId];
    });

    return nodeIdCollectionIdentifiers;
  }

  removeUndefinedAnswersFromCollection(
    blueprintId: BlueprintId,
    blueprintIdInstanceIdentifiers?: BlueprintIdInstanceScope,
  ): boolean {
    const nodeId = this.blueprintIdToNodeIdMap[blueprintId];

    if (!nodeId) {
      return false;
    }

    let translatedCollectionIdentifiers: NodeIdInstanceScope | undefined;

    if (blueprintIdInstanceIdentifiers) {
      const result = this.translateInstanceIdentifiers(blueprintIdInstanceIdentifiers);
      if (result.success === false) {
        return false;
      }
      translatedCollectionIdentifiers = result.value;
    }

    return this.storage.removeUndefinedAnswersFromCollection(nodeId, translatedCollectionIdentifiers);
  }

  //#region  usingNodeId
  private setAnswersByNodeId(items: { nodeId: NodeId; value: unknown }[], scope?: InstanceScope | undefined): void {
    for (const item of items) {
      this.setAnswerByNodeId(item.value, item.nodeId, scope);
    }
  }

  private knowsIdByNodeId(id: NodeId): boolean {
    return this.storage.knowsId(id);
  }

  private getInstanceIdByNodeId(id: NodeId, scope: InstanceScope): Result<string, SurrogateId | undefined> {
    return this.storage.getInstanceId(id, scope);
  }

  private setInstanceIdByNodeId(
    id: string,
    scope: InstanceScope,
    forceInstanceId?: string | undefined,
  ): Result<string, SurrogateId> {
    return this.storage.setInstanceId(id, scope, forceInstanceId);
  }

  private getAnswerByNodeId(id: string, scope?: InstanceScope, skipLeafIdentifier?: boolean): any | undefined {
    return this.storage.getAnswer(id, scope, skipLeafIdentifier);
  }

  private getCollectionByNodeId(id: NodeId, scope?: InstanceScope): any[] | undefined {
    return this.storage.getCollection(id, scope);
  }

  private getRepeatedAnswersByNodeId<T extends string>(
    collectionId: NodeId,
    ids: T[],
    scope: InstanceScope,
  ): RepeatedAnswersBySurrogateId<T> | undefined {
    return this.storage.getRepeatedAnswers(collectionId, ids, scope);
  }

  private getRepetitionCountByNodeId(collectionId: NodeId, scope: InstanceScope): number | undefined {
    return this.storage.getRepetitionCount(collectionId, scope);
  }

  private setAnswerByNodeId(value: unknown, id: NodeId, scope?: InstanceScope, answersOverride?: Answers): void {
    this.storage.setAnswer(value, id, scope, answersOverride);
  }

  private unsetAnswerByNodeId(id: string, scope?: InstanceScope): boolean {
    return this.storage.unsetAnswer(id, scope);
  }

  private unsetAnswersByNodeId(nodeInstancesToRemove: NodeInstance[]): NodeInstance[] {
    const removedNodeInstances: NodeInstance[] = [];

    for (const nodeInstance of nodeInstancesToRemove) {
      const { id, scope } = nodeInstance;
      const wasRemoved = this.unsetAnswerByNodeId(id, scope);

      if (wasRemoved) {
        removedNodeInstances.push(nodeInstance);
      }
    }

    return removedNodeInstances;
  }

  private unsetAnswerSelectOptionIdByNodeId(id: NodeId, optionId: string, scope?: InstanceScope): boolean {
    return this.storage.unsetAnswerSelectOptionId(id, optionId, scope);
  }

  private withCollectionIdentifierByNodeId(
    identifiers: InstanceScope,
    collectionId: NodeId,
    collectionIdentifierIndex: InstanceIndex,
  ): InstanceScope {
    return this.storage.withCollectionIdentifier(identifiers, collectionIdentifierIndex, collectionId);
  }

  private removeUndefinedAnswersFromCollectionByNodeId(id: NodeId, scope?: InstanceScope): boolean {
    return this.storage.removeUndefinedAnswersFromCollection(id, scope);
  }

  private translateInstanceIdentifiers(
    blueprintIdInstanceIdentifiers: BlueprintIdInstanceScope,
  ): Result<TranslationError, NodeIdInstanceScope> {
    const translated: NodeIdInstanceScope = {};

    const keys = Object.keys(blueprintIdInstanceIdentifiers);

    for (const blueprintId of keys) {
      const nodeId = this.blueprintIdToNodeIdMap[blueprintId];

      if (!nodeId) {
        return failure(new TranslationError(blueprintId));
      }

      translated[nodeId] = blueprintIdInstanceIdentifiers[blueprintId];
    }

    return success(translated);
  }

  //#endregion

  usingNodeId(): ReturnType<IAnswerResolver['usingNodeId']> {
    return {
      setAnswers: (...args) => this.setAnswersByNodeId(...args),
      getInstanceId: (...args) => this.getInstanceIdByNodeId(...args),
      setInstanceId: (...args) => this.setInstanceIdByNodeId(...args),
      knowsId: (...args) => this.knowsIdByNodeId(...args),
      getAnswer: (...args) => this.getAnswerByNodeId(...args),
      getCollection: (...args) => this.getCollectionByNodeId(...args),
      getRepeatedAnswers: (...args) => this.getRepeatedAnswersByNodeId(...args),
      getRepetitionCount: (...args) => this.getRepetitionCountByNodeId(...args),
      setAnswer: (...args) => this.setAnswerByNodeId(...args),
      unsetAnswer: (...args) => this.unsetAnswerByNodeId(...args),
      unsetAnswers: (...args) => this.unsetAnswersByNodeId(...args),
      unsetAnswerSelectOptionId: (...args) => this.unsetAnswerSelectOptionIdByNodeId(...args),
      withCollectionIdentifier: (...args) => this.withCollectionIdentifierByNodeId(...args),
      removeUndefinedAnswersFromCollection: (...args) => this.removeUndefinedAnswersFromCollectionByNodeId(...args),
    };
  }
}
