import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import { Result, failure, success } from '@breathelife/result';
import {
  InstanceScope,
  RepeatedAnswersBySurrogateId,
  Answers,
  SurrogateId,
  InstanceIndex,
  NodeId,
  AnswersResolverLogger,
} from '@breathelife/types';

import { AnswerPath, isReferencePath } from './AnswerPath';

const ROOT_PATH_FOR_FIELDS_WITHOUT_PATH = '@fieldsWithoutPath';

const INSURED_PEOPLE_SECTION_GROUP_NAME = 'insured-people';

export type NodeInstance = {
  id: string;
  scope: InstanceScope;
};

export type RepeatedAnswers = {
  repeatedIndex: number;
  answersByNodeId: {
    [nodeId: string]: any;
  };
};

const isReachingTheHaltPoint = (
  answerPath: AnswerPath,
  collectionNodeId: string,
  nodeIdToAnswerPath: Map<string, AnswerPath>,
): boolean => {
  let currentParentPath = answerPath.parent;

  const collectionPath = nodeIdToAnswerPath.get(collectionNodeId);
  while (currentParentPath) {
    if (_.isEqual(collectionPath, currentParentPath)) {
      return true;
    }
    currentParentPath = currentParentPath.parent;
  }
  return false;
};

const lastPartIsCollectionInstanceIdentifier = (pathParts: string[]): boolean => {
  return pathParts.length >= 2 && !isNaN(parseInt(pathParts[pathParts.length - 1]));
};

export class TranslationError extends Error {
  readonly tag = 'TranslationError';
  readonly id: string;

  constructor(id: string, msg?: string) {
    super(msg);
    this.id = id;
  }
}

export class MissingAnswerPath extends Error {
  readonly tag = 'MissingAnswerPath';
}

export class AnswersStorage {
  // `nodeIdToAnswerPath` contents should never be modified outside of the constructor.
  private readonly nodeIdToAnswerPath: Map<string, AnswerPath>;

  // This could be removed if we are sure we do not want to prefix reference paths.
  private static readonly NODE_REFERENCE_PATH_PREFIX = '';

  private answers: Answers;

  private withNodeIdFallback: boolean;

  logger: AnswersResolverLogger | undefined;

  constructor(
    answers: Answers,
    nodeIdToAnswerPath: Map<string, AnswerPath>,
    withNodeIdFallback = false,
    logger?: AnswersResolverLogger,
  ) {
    this.answers = answers;
    this.nodeIdToAnswerPath = new Map<string, AnswerPath>(nodeIdToAnswerPath);
    this.withNodeIdFallback = withNodeIdFallback;
    this.logger = logger;
  }

  clone(withAnswers?: Answers): AnswersStorage {
    const answers = withAnswers ? withAnswers : _.cloneDeep(this.answers);

    return new AnswersStorage(answers, this.nodeIdToAnswerPath, this.withNodeIdFallback, this.logger);
  }

  dump(): Answers {
    return this.answers;
  }

  private buildPath(
    id: string,
    scope?: InstanceScope,
    skipLeafIdentifier?: boolean,
    haltAtAnswerPath?: AnswerPath,
    answersOverride?: Answers,
  ): string | undefined {
    //TODO: add scope check all nodeids for path
    if (this.withNodeIdFallback) {
      const existingPath = this.nodeIdToAnswerPath.get(id);
      if (existingPath) {
        const { qualifiedPath } = this.buildFullyQualifiedPath({
          answersOverride: answersOverride || this.answers,
          answerPath: existingPath,
          scope,
          skipLeafIdentifier,
          haltAtAnswerPath,
        });

        return qualifiedPath;
      }

      return AnswersStorage.buildPathFromNodeIdScope(id, scope, skipLeafIdentifier);
    } else {
      const answerPath = this.getAnswerPath(id);

      const { qualifiedPath } = this.buildFullyQualifiedPath({
        answerPath,
        scope,
        skipLeafIdentifier: false,
      });

      return qualifiedPath;
    }
  }

  private static buildPathFromNodeIdScope(
    id: string,
    scope?: InstanceScope,
    skipLeafIdentifier?: boolean,
  ): string | undefined {
    const keys = Object.keys(scope || {});

    if (keys.length < 1 || scope === undefined) {
      if (!id) {
        return undefined;
      }
      return `${ROOT_PATH_FOR_FIELDS_WITHOUT_PATH}.${id}`;
    }

    let path = `${ROOT_PATH_FOR_FIELDS_WITHOUT_PATH}`;
    for (let i = 0; i < keys.length; ++i) {
      const key = keys[i];
      path += `.${key}.${scope[key]}`;
    }

    if (id && !Object.keys(scope).includes(id)) {
      path += `.${id}`;
    }

    const stack = path.split('.');
    if (skipLeafIdentifier && lastPartIsCollectionInstanceIdentifier(stack)) {
      stack.pop();
      return stack.join('.');
    }

    return path;
  }

  // TODO: Resolver, only pass the scope. No need for id...
  getInstanceId(id: string, scope: InstanceScope): Result<string, SurrogateId | undefined> {
    const qualifiedPath = this.buildPath(id, scope, false);

    if (qualifiedPath) {
      const partsStack = qualifiedPath.split('.');

      if (!lastPartIsCollectionInstanceIdentifier(partsStack)) {
        return success(undefined);
      }

      const surrogateId = _.get(this.answers, qualifiedPath + '.surrogateId');
      return typeof surrogateId === 'string' && surrogateId.length > 0
        ? success(surrogateId)
        : failure(`Could not find the surrogateId for the "${id}" + ${JSON.stringify(scope)}.`);
    } else {
      return failure('Could not build the qualified path to get the surrogate id.');
    }
  }

  setInstanceId(id: string, scope: InstanceScope, forceInstanceId?: string | undefined): Result<string, SurrogateId> {
    const surrogateId = forceInstanceId || uuid();

    const qualifiedPath = this.buildPath(id, scope);

    if (qualifiedPath) {
      _.set(this.answers, qualifiedPath + '.surrogateId', surrogateId);
      return success(surrogateId);
    } else {
      return failure('Could not build the qualified path to set the surrogate id.');
    }
  }

  public static buildReferencePathKey(value: string | number): string {
    return `${AnswersStorage.NODE_REFERENCE_PATH_PREFIX}${value}`;
  }

  public knowsId(id: string): boolean {
    return this.nodeIdToAnswerPath.has(id);
  }

  public getAnswer(
    id: string,
    scope?: InstanceScope,
    skipLeafIdentifier?: boolean,
    answersOverride?: Answers,
  ): any | undefined {
    const qualifiedPath = this.buildPath(id, scope, !!skipLeafIdentifier);

    return qualifiedPath ? _.get(answersOverride || this.answers, qualifiedPath) : undefined;
  }

  public getCollection(nodeId: string, scope?: InstanceScope): any[] | undefined {
    const result = this.allNodeIdsHaveAPath(Object.keys(scope || {}).concat([nodeId]));
    if (result.success) {
      const answerPath = this.getAnswerPath(nodeId);
      if (!answerPath.isCollection) {
        throw Error(`${nodeId} does not reference a collection`);
      }

      return this.getAnswer(nodeId, scope, true);
    }

    if (this.withNodeIdFallback) {
      const path = this.buildPath(nodeId, scope, true);
      if (path) {
        const collection = _.get(this.answers, path) || []; // undefined if never written to or array. (Both are valid for repeatable question.)
        if (!Array.isArray(collection)) {
          throw Error(`${nodeId} does not reference a collection`);
        }
        return collection;
      } else {
        throw new Error(
          `Could note compute a valid fallback path for getCollection(${nodeId},${JSON.stringify(scope)}).`,
        );
      }
    } else {
      throw new MissingAnswerPath(`nodeId '${result.error}' not found`, { cause: 'nodeId' });
    }
  }

  /** Gets answers from within a collection. Returns those answers in an object keyed by the containing surrogateId */
  public getRepeatedAnswers<T extends string>(
    collectionNodeId: string,
    nodeIds: T[],
    scope: InstanceScope,
  ): RepeatedAnswersBySurrogateId<T> | undefined {
    const result = this.allNodeIdsHaveAPath(Object.keys(scope).concat([collectionNodeId]).concat(nodeIds));
    if (result.success) {
      const collectionAnswerPath = this.getAnswerPath(collectionNodeId);
      if (!collectionAnswerPath.isCollection) throw Error(`nodeId '${collectionNodeId}' is not a collection.`);

      const nodeIdsWithAnswerPaths: { nodeId: T; answerPath: AnswerPath }[] = nodeIds.map((nodeId) => {
        const answerPath = this.getAnswerPath(nodeId);
        return { answerPath, nodeId };
      });

      const { collectionAnswers } = this.getCollectionAnswers(this.answers, collectionAnswerPath, scope);
      if (typeof collectionAnswers === 'undefined') {
        return undefined;
      }

      const repeatedAnswersBySurrogateId: RepeatedAnswersBySurrogateId<T> = {};

      nodeIdsWithAnswerPaths.forEach(({ nodeId, answerPath }: { nodeId: T; answerPath: AnswerPath }) => {
        const shouldUseGlobalAnswers = isReachingTheHaltPoint(answerPath, collectionNodeId, this.nodeIdToAnswerPath);
        Object.values(collectionAnswers).forEach((repeatedAnswers, index) => {
          let surrogateId = _.get(repeatedAnswers, 'surrogateId');
          if (!surrogateId) {
            surrogateId = index;
          }

          const childFullyQualifiedAnswerPath = this.buildPath(
            nodeId,
            shouldUseGlobalAnswers ? scope : { ...scope, [collectionNodeId]: index },
            undefined,
            collectionAnswerPath,
          );

          const answer =
            typeof childFullyQualifiedAnswerPath !== 'undefined'
              ? _.get(shouldUseGlobalAnswers ? repeatedAnswers : this.answers, childFullyQualifiedAnswerPath)
              : undefined;

          if (!repeatedAnswersBySurrogateId[surrogateId]) {
            repeatedAnswersBySurrogateId[surrogateId] = { answersByNodeId: {}, repeatedIndex: index };
          }

          repeatedAnswersBySurrogateId[surrogateId].answersByNodeId[nodeId] = answer;
        });
      });

      return repeatedAnswersBySurrogateId;
    }

    if (this.withNodeIdFallback) {
      const path = this.buildPath(collectionNodeId, scope, true); // Skip the leaf we want an array
      if (path) {
        const collection = _.get(this.answers, path) || [];
        if (!Array.isArray(collection)) {
          throw new Error(`CollectionNodeId ${collectionNodeId} is not a collection.`);
        }

        const output: RepeatedAnswersBySurrogateId<T> = {};

        for (let i = 0; i < collection.length; ++i) {
          const instance = _.get(this.answers, path + `.${i}`);
          let surrogateId = _.get(instance, 'surrogateId');
          if (!surrogateId) {
            surrogateId = i;
          }

          const instanceAnswers: Partial<Record<NodeId, unknown>> = {};

          for (const nodeId of nodeIds) {
            const pathToAnswer = `${path}.${i}.${nodeId}`;
            const answer = _.get(this.answers, pathToAnswer);
            instanceAnswers[nodeId] = answer;
          }

          output[surrogateId] = {
            repeatedIndex: i,
            answersByNodeId: instanceAnswers,
          };
        }
        return output;
      } else {
        throw new Error(
          `Could note compute a valid fallback path for getRepeatedAnswers(${collectionNodeId}, nodeIds[], ${JSON.stringify(
            scope,
          )}).`,
        );
      }
    } else {
      throw new MissingAnswerPath(`nodeId '${result.error}' not found`, { cause: 'nodeId' });
    }
  }

  /** Gets the number of repetitions at a collection node id */
  public getRepetitionCount(collectionNodeId: string, scope: InstanceScope): number | undefined {
    const result = this.allNodeIdsHaveAPath(Object.keys(scope).concat([collectionNodeId]));
    if (result.success) {
      const collectionAnswerPath = this.getAnswerPath(collectionNodeId);
      if (!collectionAnswerPath.isCollection) throw Error(`nodeId '${collectionNodeId}' is not a collection.`);

      const { collectionAnswers } = this.getCollectionAnswers(this.answers, collectionAnswerPath, scope);

      return collectionAnswers?.length;
    }

    if (this.withNodeIdFallback) {
      const path = this.buildPath(collectionNodeId, scope, true); // Skip the leaf we want an array
      if (path) {
        return _.get(this.answers, path)?.length || 0;
      } else {
        throw new Error(
          `Could note compute a valid fallback path for getRepetitionCount(${collectionNodeId},${JSON.stringify(
            scope,
          )}).`,
        );
      }
    } else {
      throw new MissingAnswerPath(`nodeId '${result.error}' not found`, { cause: 'nodeId' });
    }
  }

  private allNodeIdsHaveAPath(nodeIds: string[]): Result<NodeId, true> {
    for (const nodeId of nodeIds) {
      const path = this.nodeIdToAnswerPath.get(nodeId);
      if (!path) {
        return failure(nodeId);
      }
    }
    return success(true);
  }

  public setAnswer(value: unknown, nodeId: string, scope?: InstanceScope, answersOverride?: Answers): void {
    const safeAnswer = _.cloneDeep(value); // Sometimes, the answer is an empty object and it gets set in both answerResolver. They must not share the same pointer.

    if (this.nodeIdToAnswerPath.has(nodeId)) {
      const answerPath = this.getAnswerPath(nodeId);
      if (answerPath.isCollection) {
        this.setRepeatedAnswer(safeAnswer, answerPath, scope);
        return;
      }
    }

    const qualifiedPath = this.buildPath(nodeId, scope, undefined, undefined, answersOverride);

    if (qualifiedPath) {
      if (typeof safeAnswer === 'undefined') {
        _.unset(this.answers, qualifiedPath);
      } else {
        _.set(this.answers, qualifiedPath, safeAnswer);
      }
    }
  }

  public setAnswers(items: { id: string; value: unknown }[], scope?: InstanceScope | undefined): void {
    for (const item of items) {
      this.setAnswer(item.value, item.id, scope);
    }
  }

  public unsetAnswer(nodeId: string, scope?: InstanceScope): boolean {
    const result = this.allNodeIdsHaveAPath(Object.keys(scope || {}).concat([nodeId]));

    if (result.success) {
      const answerPath = this.getAnswerPath(nodeId);

      const { qualifiedPath } = this.buildFullyQualifiedPath({
        answerPath,
        scope,
        skipLeafIdentifier: false,
      });

      if (qualifiedPath) {
        const currentValue = _.get(this.answers, qualifiedPath);
        if (typeof currentValue === 'undefined') {
          return false;
        }

        _.unset(this.answers, qualifiedPath);

        const surrogateIdsToBeDeleted: string[] = [];

        const stack = qualifiedPath.split('.');
        if (lastPartIsCollectionInstanceIdentifier(stack)) {
          for (const surrogateId of getAllSurrogateIdsUnderBranch(currentValue)) {
            surrogateIdsToBeDeleted.push(surrogateId);
          }

          stack.pop();
          const collectionPath = stack.join('.');
          _.set(this.answers, collectionPath, _.compact(_.get(this.answers, collectionPath)));
        }

        if (this.withNodeIdFallback && answerPath.isCollection) {
          const path = AnswersStorage.buildPathFromNodeIdScope(nodeId, scope);
          if (path) {
            const answersRemoved = _.get(this.answers, path);
            if (typeof answersRemoved !== 'undefined') {
              // We need to also do it here, because sometime the {insured-people:0} is in the map but the children are not so this will be a code path that we reach quite often.
              _.unset(this.answers, path);

              // If we are unsetting an instance of a collection, we need to remove the undefined in [undefined, {...}, {...}]
              const stack = path.split('.');
              if (lastPartIsCollectionInstanceIdentifier(stack)) {
                for (const surrogateId of getAllSurrogateIdsUnderBranch(answersRemoved)) {
                  surrogateIdsToBeDeleted.push(surrogateId);
                }

                stack.pop();
                const collectionPath = stack.join('.');
                _.set(this.answers, collectionPath, _.compact(_.get(this.answers, collectionPath)));
              }
            }
          }
        }

        // We cleanup after the branch deletion to not have to search within that branch of the tree.
        if (surrogateIdsToBeDeleted.length > 0) {
          replaceAllDestroyedSurrogateIdWithUndefined(this.answers, surrogateIdsToBeDeleted);
        }

        return true;
      }

      return false;
    }

    if (this.withNodeIdFallback) {
      const path = AnswersStorage.buildPathFromNodeIdScope(nodeId, scope);

      if (path) {
        const currentValue = _.get(this.answers, path);
        if (typeof currentValue === 'undefined') {
          return false;
        }

        const surrogateIdsToBeDeleted: string[] = [];

        _.unset(this.answers, path);

        // If we are unsetting an instance of a collection, we need to remove the undefined in [undefined, {...}, {...}]
        const stack = path.split('.');
        if (lastPartIsCollectionInstanceIdentifier(stack)) {
          for (const surrogateId of getAllSurrogateIdsUnderBranch(currentValue)) {
            surrogateIdsToBeDeleted.push(surrogateId);
          }
          stack.pop();
          const collectionPath = stack.join('.');
          _.set(this.answers, collectionPath, _.compact(_.get(this.answers, collectionPath)));
        }

        // We cleanup after the branch deletion to not have to search within that branch of the tree.
        if (surrogateIdsToBeDeleted.length > 0) {
          replaceAllDestroyedSurrogateIdWithUndefined(this.answers, surrogateIdsToBeDeleted);
        }

        return true;
      }

      return false;
    } else {
      throw new MissingAnswerPath(`nodeId '${result.error}' not found`, { cause: 'nodeId' });
    }
  }

  public unsetAnswers(nodeInstancesToRemove: NodeInstance[]): NodeInstance[] {
    const removedNodeInstances: NodeInstance[] = [];

    for (const nodeInstance of nodeInstancesToRemove) {
      const { id, scope } = nodeInstance;
      const wasRemoved = this.unsetAnswer(id, scope);

      if (wasRemoved) {
        removedNodeInstances.push(nodeInstance);
      }
    }

    return removedNodeInstances;
  }

  /** Remove an optionId from a given answer, for both single and multi-select. Returns `true` if answers were modified. */
  public unsetAnswerSelectOptionId(nodeId: string, optionId: string, scope?: InstanceScope): boolean {
    const qualifiedPath = this.buildPath(nodeId, scope);

    if (qualifiedPath) {
      const currentOptionOrOptions = _.get(this.answers, qualifiedPath);

      if (Array.isArray(currentOptionOrOptions)) {
        // Multi-select or checkbox.
        const wasOptionSet = currentOptionOrOptions.includes(optionId);

        if (wasOptionSet) {
          const newOptions = currentOptionOrOptions.filter((id) => optionId !== id);
          this.setAnswer(newOptions, nodeId, scope);
        }

        return wasOptionSet;
      } else if (currentOptionOrOptions === optionId) {
        // Single select or radio.
        return this.unsetAnswer(nodeId, scope);
      }
    }

    return false;
  }

  /** Duplicate `identifiers` with `collectionIdentifier` inserted at `collectionNodeId`'s position */
  public withCollectionIdentifier(
    identifiers: InstanceScope,
    collectionInstanceIndex: InstanceIndex,
    collectionNodeId: string,
  ): InstanceScope {
    const result = this.allNodeIdsHaveAPath(Object.keys(identifiers).concat([collectionNodeId]));
    if (result.success) {
      const collectionAnswerPath = this.getAnswerPath(collectionNodeId);

      if (!collectionAnswerPath.isCollection) {
        throw Error(`${collectionNodeId} does not reference a collection`);
      }

      const duplicatedIdentifiers = { ...identifiers };
      duplicatedIdentifiers[collectionNodeId] = collectionInstanceIndex;

      return duplicatedIdentifiers;
    }

    if (this.withNodeIdFallback) {
      const path = this.buildPath(collectionNodeId, identifiers, true); // Skip the leaf we want an array
      if (path) {
        const collection = _.get(this.answers, path) || []; // undefined if never written to or array. (Both are valid for repeatable question.)
        if (!Array.isArray(collection)) {
          throw Error(`${collectionNodeId} does not reference a collection`);
        }
        const duplicatedIdentifiers = { ...identifiers };
        duplicatedIdentifiers[collectionNodeId] = collectionInstanceIndex;
        return duplicatedIdentifiers;
      } else {
        throw new Error(
          `Could note compute a valid fallback path for withCollectionIdentifier(${collectionNodeId},${JSON.stringify(
            identifiers,
          )}).`,
        );
      }
    } else {
      throw new MissingAnswerPath(`nodeId '${result.error}' not found`, { cause: 'nodeId' });
    }
  }

  private getAnswerPath(nodeId: string): AnswerPath {
    const answerPath = this.nodeIdToAnswerPath.get(nodeId);
    if (!answerPath) {
      throw new MissingAnswerPath(`nodeId '${nodeId}' not found`, { cause: 'nodeId' });
    }

    return answerPath;
  }

  private buildFullyQualifiedPath({
    answerPath,
    answersOverride,
    scope,
    haltAtAnswerPath,
    skipLeafIdentifier,
  }: {
    answerPath: AnswerPath;
    answersOverride?: Answers;
    scope?: InstanceScope;
    haltAtAnswerPath?: AnswerPath;
    skipLeafIdentifier?: boolean;
  }): {
    qualifiedPath: string | undefined;
  } {
    let qualifiedPath: string = '';

    if (isReferencePath(answerPath.path)) {
      // This is a special case where the path is set by the value at another nodeId.
      // ~99% of the time the `else` block will be executed here.

      const valueAtNodeId = this.getAnswer(answerPath.path.fromValueAt, scope, undefined, answersOverride);
      if (typeof valueAtNodeId === 'undefined') {
        return { qualifiedPath: undefined };
      }

      const valueType = typeof valueAtNodeId;
      if (valueType === 'object' || valueType === 'function') {
        throw Error('`answerPath.path.fromValueAt` must reference a scalar value');
      }

      qualifiedPath = AnswersStorage.buildReferencePathKey(valueAtNodeId);
    } else if (answerPath.path) {
      qualifiedPath = answerPath.path;
    }

    if (answerPath.parent) {
      // The parent node will add to the qualified path.

      const stopTraversal = _.isEqual(answerPath.parent, haltAtAnswerPath);
      if (stopTraversal) {
        // This is a special case that can be ignored most of the time.
        return { qualifiedPath };
      }

      // Recursively visit the parent node (any consumed `scope` will be removed in `updatedInstanceScope`).
      const { qualifiedPath: parentQualifiedPath } = this.buildFullyQualifiedPath({
        answersOverride,
        answerPath: answerPath.parent,
        scope,
        haltAtAnswerPath,
        skipLeafIdentifier: false,
      });

      if (typeof parentQualifiedPath === 'undefined') {
        return { qualifiedPath: undefined };
      }

      qualifiedPath = `${parentQualifiedPath}.${qualifiedPath}`;
    }

    if (answerPath.isCollection && answerPath.nodeId && !skipLeafIdentifier) {
      // For collections we often need to know _which_ item in the collection we are building a path for.
      //
      // The data in `currentInstanceScope` comes from sources external to the engine.
      //  For instance maybe we are loading answers into a React component for a field in a repeated question...
      //  in that case the collection identifier would be set from the index in the loop containing that field.

      if (typeof scope?.[answerPath.nodeId] !== 'undefined') {
        const index = scope[answerPath.nodeId];
        qualifiedPath = `${qualifiedPath}.${index}`;
      } else {
        /*
         * MULTI-INSURED HACK
         *
         * This detects situations where a `multi-insured` collection index is not passed into the system.
         *
         * This is meant to prevent our systems from breaking while we add multi-insured capabilities to the system.
         *  (It will allow systems that do not provide indices for multi-insured to still work as though there was a single insured.)
         */
        if (answerPath.nodeId !== INSURED_PEOPLE_SECTION_GROUP_NAME && this.logger) {
          this.logger.warn(
            `Answers Storage: a qualified path was requested without passing appropriate score for a repeatable nodeId that was not "${INSURED_PEOPLE_SECTION_GROUP_NAME}": ${answerPath.nodeId}`,
          );
        }
        // TODO HOT-5201 - remove this hack
        const index = 0;
        qualifiedPath = `${qualifiedPath}.${index}`;
        // END MULTI-INSURED HACK
      }
    }
    return { qualifiedPath };
  }

  public removeUndefinedAnswersFromCollection(nodeId: string, scope?: InstanceScope): boolean {
    const result = this.allNodeIdsHaveAPath(Object.keys(scope || {}).concat([nodeId]));
    if (result.success) {
      const answerPath = this.nodeIdToAnswerPath.get(nodeId);

      if (!answerPath || !answerPath?.isCollection) {
        return false;
      }

      const collectionQualifiedPath = this.buildPath(nodeId, scope, true);

      if (collectionQualifiedPath) {
        const collection = _.get(this.answers, collectionQualifiedPath);
        if (Array.isArray(collection)) {
          if (collection.length === 0 || collection.every((e) => e === undefined)) {
            // Delete the keys if no items are left in it.
            _.unset(this.answers, collectionQualifiedPath);
          } else {
            // Remove all the undefined betweens items. (_.unset) creates undefined (which is good for removing without losing index validity).
            _.set(
              this.answers,
              collectionQualifiedPath,
              collection.filter((e: any) => !!e),
            );
          }
          return true;
        }
      }

      return false;
    }

    if (this.withNodeIdFallback) {
      const collectionQualifiedPath = this.buildPath(nodeId, scope, true);

      if (collectionQualifiedPath) {
        const collection = _.get(this.answers, collectionQualifiedPath);
        if (Array.isArray(collection)) {
          if (collection.length === 0 || collection.every((e) => e === undefined)) {
            // Delete the keys if no items are left in it.
            _.unset(this.answers, collectionQualifiedPath);
          } else {
            // Remove all the undefined betweens items. (_.unset) creates undefined (which is good for removing without losing index validity).
            _.set(
              this.answers,
              collectionQualifiedPath,
              collection.filter((e: any) => !!e),
            );
          }
          return true;
        }
      }

      return false;
    } else {
      throw new MissingAnswerPath(`nodeId '${result.error}' not found`, { cause: 'nodeId' });
    }
  }

  private getCollectionAnswers(
    answers: Answers,
    collectionAnswerPath: AnswerPath,
    scope: InstanceScope,
  ): {
    collectionAnswers: Answers[] | undefined;
  } {
    let collectionFullyQualifiedAnswerPath = undefined;
    const result = this.buildFullyQualifiedPath({
      answerPath: collectionAnswerPath,
      scope,
      skipLeafIdentifier: true, // Do not add an index to the collection's qualified path, we want an array.
    });
    collectionFullyQualifiedAnswerPath = result.qualifiedPath;

    const collectionAnswers: Answers[] = collectionFullyQualifiedAnswerPath
      ? _.get(answers, collectionFullyQualifiedAnswerPath)
      : undefined;

    if (typeof collectionAnswers === 'undefined') {
      return { collectionAnswers: undefined };
    }

    return { collectionAnswers };
  }

  private setRepeatedAnswer(value: any, answerPath: AnswerPath, scope?: InstanceScope): void {
    const isRepeatedInstanceRemoval = typeof value === 'undefined';
    const isSettingEntireCollection = Array.isArray(value);

    const { qualifiedPath } = this.buildFullyQualifiedPath({
      answerPath,
      scope,
      skipLeafIdentifier: isSettingEntireCollection || isRepeatedInstanceRemoval,
    });

    if (!qualifiedPath) return;

    if (isRepeatedInstanceRemoval) {
      if (!scope) {
        throw Error('Collection instance identifier(s) must be defined when removing repeated instances');
      }

      const repeatedAnswers = _.get(this.answers, qualifiedPath);
      const repeatedInstanceIdentifier = answerPath.nodeId ? scope?.[answerPath.nodeId] : undefined;

      if (repeatedInstanceIdentifier === undefined) {
        throw Error(
          `repeatedInstanceIdentifier could not be found in setRepeatedAnswer. (nodeId: ${answerPath.nodeId})`,
        );
      }

      if (Array.isArray(repeatedAnswers)) {
        repeatedAnswers?.splice(repeatedInstanceIdentifier as number, 1);
        _.set(this.answers, qualifiedPath, repeatedAnswers);
      } else {
        throw Error('Cannot remove a repeated instance from a non-array collection');
      }
    } else {
      _.set(this.answers, qualifiedPath, value);
    }
  }
}

// The normal answer nesting dept is at most 6.
// We can write the iterative version if we need to prevent stack overflow exceptions.
function getAllSurrogateIdsUnderBranch(data: unknown): string[] {
  const list = [];
  if (Array.isArray(data)) {
    data.forEach((item) => {
      const surrogateIds = getAllSurrogateIdsUnderBranch(item);
      for (let i = 0; i < surrogateIds.length; ++i) {
        list.push(surrogateIds[i]);
      }
    });
  } else if (typeof data === 'object' && data !== null) {
    const surrogateId = (data as any).surrogateId;
    if (surrogateId) {
      list.push(surrogateId);
    }

    Object.keys(data).forEach((key) => {
      const value = (data as any)[key];
      if (typeof value === 'object') {
        const surrogateIds = getAllSurrogateIdsUnderBranch(value);
        for (let i = 0; i < surrogateIds.length; ++i) {
          list.push(surrogateIds[i]);
        }
      }
    });
  }

  return list;
}

// The normal answer nesting dept is at most 6.
// We can write the iterative version if we need to prevent stack overflow exceptions.
function replaceAllDestroyedSurrogateIdWithUndefined(data: unknown, destroyedSurrogateIds: string[]): void {
  if (Array.isArray(data)) {
    data.forEach((item) => {
      replaceAllDestroyedSurrogateIdWithUndefined(item, destroyedSurrogateIds);
    });
  } else if (typeof data === 'object' && data !== null) {
    Object.keys(data).forEach((key) => {
      const value = (data as any)[key];
      if (typeof value === 'object') {
        replaceAllDestroyedSurrogateIdWithUndefined(value, destroyedSurrogateIds);
      } else if (destroyedSurrogateIds.includes(value)) {
        (data as any)[key] = undefined;
      }
    });
  }
}
