import {
  FieldBlueprint,
  QuestionBlueprint,
  QuestionnaireBlueprint,
  SectionBlueprint,
  SectionGroupBlueprint,
  SubsectionBlueprint,
} from '@breathelife/types';

import { AnswerPathTreeNode } from './makeNodeIdToAnswerPathMap';
import { isSectionGroupBlueprint } from '../validations';

export const makeAnswerPathTreeFromBlueprint = (blueprint: QuestionnaireBlueprint): AnswerPathTreeNode[] => {
  return createSectionGroupNodes(blueprint);
};

type MinimumRepeatableFields = {
  repeatable?: {
    repeatableAnswerNodeId?: string;
    minRepeatable: number;
    maxRepeatable: number;
  };
};

const isRepeatable = (data: MinimumRepeatableFields): boolean => {
  return 'repeatable' in data;
};

const isRepeatableSectionGroup = (sectionGroup: SectionGroupBlueprint): boolean => isRepeatable(sectionGroup);

const isRepeatableQuestion = (question: QuestionBlueprint): boolean => isRepeatable(question);

function createSectionGroupNodes(blueprint: QuestionnaireBlueprint): AnswerPathTreeNode[] {
  const sectionGroupNodes: AnswerPathTreeNode[] = [];

  Object.keys(blueprint.sectionGroupBlueprints).forEach((sectionGroupKey) => {
    const sectionGroupBlueprint = blueprint.sectionGroupBlueprints[sectionGroupKey];

    if (!sectionGroupBlueprint || !isSectionGroupBlueprint(sectionGroupBlueprint)) {
      return;
    }

    const sections: AnswerPathTreeNode[] = blueprint.sectionBlueprints
      .filter((sectionBlueprint) => sectionBlueprint.sectionGroupKey === sectionGroupKey)
      .map(createSectionNode);

    if (isRepeatableSectionGroup(sectionGroupBlueprint)) {
      sectionGroupNodes.push({
        nodeId: sectionGroupBlueprint.id,
        path: sectionGroupBlueprint.id,
        isCollection: true,
        children: sections,
      });
    } else {
      sectionGroupNodes.push({
        nodeId: sectionGroupBlueprint.id,
        path: sectionGroupBlueprint.id,
        children: sections,
      });
    }
  });

  return sectionGroupNodes;
}

function createSectionNode(sectionBlueprint: SectionBlueprint): AnswerPathTreeNode {
  const subsections: AnswerPathTreeNode[] = sectionBlueprint.subsections.map(createSubsectionNode);

  return {
    nodeId: sectionBlueprint.id,
    path: sectionBlueprint.id,
    children: subsections,
  };
}

function createSubsectionNode(subsectionBlueprint: SubsectionBlueprint): AnswerPathTreeNode {
  const questions: AnswerPathTreeNode[] = subsectionBlueprint.questions.map(createQuestionNode);

  return {
    nodeId: subsectionBlueprint.id,
    path: subsectionBlueprint.id,
    children: questions,
  };
}

function createQuestionNode(questionBlueprint: QuestionBlueprint): AnswerPathTreeNode {
  const fields: AnswerPathTreeNode[] = questionBlueprint.fields.map(createFieldNode);

  if (isRepeatableQuestion(questionBlueprint)) {
    return {
      nodeId: questionBlueprint.id,
      path: questionBlueprint.id,
      isCollection: true,
      children: fields,
    };
  }

  return {
    nodeId: questionBlueprint.id,
    path: questionBlueprint.id,
    children: fields,
  };
}

function createFieldNode(fieldBlueprint: FieldBlueprint): AnswerPathTreeNode {
  return {
    nodeId: fieldBlueprint.id,
    path: fieldBlueprint.id,
  };
}
