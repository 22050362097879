import _ from 'lodash';

import {
  BlueprintConditionValue,
  Localizable,
  SectionBlueprint,
  SectionGroupBlueprint,
  SectionGroupKey,
  isSectionBlueprint,
} from '@breathelife/types';

import NodeBuilder from './NodeBuilder';
import { SeedBlueprintProviders } from './SeedProviders';
import { v4 as UUID } from 'uuid';

export default class SectionGroupBuilder extends NodeBuilder<SectionGroupBlueprint> {
  private sectionGroupKey: SectionGroupKey | null = null;
  public constructor(seedProviders: SeedBlueprintProviders) {
    super(seedProviders.forSectionGroup());
    this.node.id = UUID();
  }

  public withProperties(properties: Partial<SectionGroupBlueprint>): this {
    Object.assign(this.node, properties);
    return this.clone();
  }

  public withSectionGroupKey(sectionGroupKey: SectionGroupKey): this {
    this.sectionGroupKey = sectionGroupKey;
    return this.clone();
  }

  public getSectionGroupKey(): SectionGroupKey | null {
    return this.sectionGroupKey;
  }

  public withPartName(partname: string): this {
    this.node.partName = partname;
    return this.clone();
  }

  public withTitle(title: Localizable): this {
    this.node.title = title;
    return this.clone();
  }

  public visibleIf(condition: BlueprintConditionValue): this {
    this.node.visible = condition;

    return this.clone();
  }

  public repeatable(options: { repeatableAnswerNodeId: string }): this {
    this.node.repeatable = {
      repeatableAnswerNodeId: options.repeatableAnswerNodeId,
      minRepeatable: 1,
      maxRepeatable: 6,
    };
    return this.clone();
  }

  public validate(section: SectionBlueprint): section is SectionBlueprint {
    return isSectionBlueprint(section);
  }
}
