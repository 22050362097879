import { BlueprintId, NodeId, QuestionnaireBlueprint } from '@breathelife/types';
import { isSectionGroupBlueprint } from '../validations';

export const translateBlueprintIdToNodeId = (
  blueprint: QuestionnaireBlueprint,
): Record<BlueprintId, NodeId | undefined> => {
  const blueprintIdToNodeId: Record<BlueprintId, NodeId | undefined> = {};

  Object.keys(blueprint.sectionGroupBlueprints).forEach((key: string) => {
    const sectionGroup = blueprint.sectionGroupBlueprints[key];

    if (!sectionGroup || !isSectionGroupBlueprint(sectionGroup)) {
      return;
    }

    const repeatableAnswerNodeId = sectionGroup.repeatable?.repeatableAnswerNodeId;
    if (repeatableAnswerNodeId) {
      blueprintIdToNodeId[sectionGroup.id] = repeatableAnswerNodeId;
    }
  });

  // We grab the first one we find, it does not matter, the linkedBlueprintsIds will propagate the answers.
  blueprint.sectionBlueprints.forEach((section) => {
    section.subsections.forEach((subsection) => {
      subsection.questions.forEach((question) => {
        const repeatableAnswerNodeId = question.repeatable?.repeatableAnswerNodeId;
        if (repeatableAnswerNodeId) {
          blueprintIdToNodeId[question.id] = repeatableAnswerNodeId;
        }
        question.fields.forEach((field) => {
          blueprintIdToNodeId[field.id] = field.answerNodeId;
        });
      });
    });
  });

  return blueprintIdToNodeId;
};
