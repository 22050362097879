import _ from 'lodash';

import {
  BlueprintConditionValue,
  FieldBlueprint,
  isQuestionBlueprint,
  Localizable,
  QuestionBlueprint,
} from '@breathelife/types';

import FieldBuilder, { TextFieldBuilder } from './FieldBuilder';
import NodeBuilder from './NodeBuilder';
import { SeedBlueprintProviders } from './SeedProviders';
import { v4 as UUID } from 'uuid';

export default class QuestionBuilder extends NodeBuilder<QuestionBlueprint> {
  private readonly seedProviders: SeedBlueprintProviders;

  public constructor(seedProviders: SeedBlueprintProviders) {
    super(seedProviders.forQuestion());
    this.seedProviders = seedProviders;
    this.node.id = UUID();
  }

  public with(nodeBuilder: FieldBuilder): this {
    return this.withFields(nodeBuilder);
  }

  public withFields(fieldBuilders?: FieldBuilder[] | FieldBuilder): this {
    const builders = fieldBuilders ? _.castArray(fieldBuilders) : [];

    if (builders.length === 0) {
      builders.push(new TextFieldBuilder(this.seedProviders));
    }

    const fields = builders.map((builder) => builder.build());
    this.addFields(fields);

    return this.clone();
  }

  private addFields(fields: FieldBlueprint | FieldBlueprint[]): void {
    if (!this.node.fields) this.node.fields = [];

    const fieldList = _.castArray(fields);
    this.node.fields.push(...fieldList);
  }

  public seedWith(seed: QuestionBlueprint): this {
    this.node = _.cloneDeep(seed);
    return this.clone();
  }

  public makeRepeatable({ nodeId }: { nodeId: string }): this {
    Object.assign(this.node, this.seedProviders.forRepeatableQuestionOptions());

    const repeatableQuestion = this.node as QuestionBlueprint;
    repeatableQuestion.repeatable = {
      repeatableAnswerNodeId: nodeId,
      minRepeatable: 0,
      maxRepeatable: 6,
      addButtonText: { en: 'Add' },
      removeButtonText: { en: 'Remove' },
    };

    return this.clone();
  }

  public withPartName(partname: string): this {
    this.node.partName = partname;
    return this.clone();
  }

  public withMinRepetitions(minRepetitions: number): this {
    if (!this.node.repeatable) {
      Object.assign(this.node, this.seedProviders.forRepeatableQuestionOptions());
      Object.assign(this.node.repeatable ?? {}, { minRepetitions });
    } else {
      Object.assign(this.node.repeatable, { minRepetitions });
    }

    return this.clone();
  }

  public withTitle(title: Localizable): this {
    this.node.title = title;
    return this.clone();
  }

  public withText(text: Localizable): this {
    this.node.text = text;
    return this.clone();
  }

  public visible(condition: BlueprintConditionValue): this {
    this.node.visible = condition;

    return this.clone();
  }

  public withProperties(properties: Partial<QuestionBlueprint>): this {
    Object.assign(this.node, properties);
    return this.clone();
  }

  public seedFieldsWith(fields: FieldBlueprint[]): this {
    this.node.fields = _.cloneDeep(fields);
    return this.clone();
  }

  public validate(question: QuestionBlueprint): question is QuestionBlueprint {
    return isQuestionBlueprint(question);
  }
}
