import _ from 'lodash';

import {
  EngineEffects,
  Localizable,
  ParticipantRoles,
  FieldTypes,
  FieldBlueprint,
  isFieldBlueprint,
  InputFieldBlueprint,
  ButtonFieldBlueprint,
  SelectOptionBlueprint,
  MoneyFieldBlueprint,
  InformationFieldBlueprint,
  PhoneFieldBlueprint,
  NumberFieldBlueprint,
  AgreeFieldBlueprint,
  RadioFieldBlueprint,
  SignatureFieldBlueprint,
  DynamicOptionsBlueprint,
  BooleanFieldValidation,
  NumberFieldValidation,
  PhoneFieldValidation,
  StringFieldValidation,
  MoneyFieldValidation,
  SelectOptionFieldBlueprint,
  CheckBoxGroupFieldBlueprint,
  DateFieldBlueprint,
  DateFieldValidation,
  InputFieldValidation,
  BlueprintConditionValue,
} from '@breathelife/types';

import NodeBuilder from './NodeBuilder';
import { SeedBlueprintProviders } from './SeedProviders';
import { v4 as UUID } from 'uuid';

export default abstract class FieldBlueprintBuilder<
  T extends FieldBlueprint = FieldBlueprint,
> extends NodeBuilder<FieldBlueprint> {
  protected constructor(nodeSeed: Partial<T>) {
    super(nodeSeed);
    this.node.id = UUID();
  }

  public withProperties(properties: Partial<T>): this {
    Object.assign(this.node, properties);
    return this.clone();
  }

  public withPartName(partname: string): this {
    this.node.partName = partname;
    return this.clone();
  }

  public withNodeId(nodeId: string): this {
    this.node.answerNodeId = nodeId;
    return this.clone();
  }

  public withTitle(title: Localizable): this {
    this.node.title = title;
    return this.clone();
  }

  public withEffects(effects: EngineEffects): this {
    this.node.effects = effects;
    return this.clone();
  }

  public withDynamicOptions(dynamicOptions: DynamicOptionsBlueprint) {
    this.node.dynamicOptions = dynamicOptions;
    return this.clone();
  }

  public makeDisabled(): this {
    this.node.disabled = true;
    return this.clone();
  }

  public visible(condition: BlueprintConditionValue): this {
    this.node.visible = condition;

    return this.clone();
  }

  public validate(field: T): field is T {
    return isFieldBlueprint(field);
  }
}

export class TextFieldBuilder extends FieldBlueprintBuilder<InputFieldBlueprint> {
  constructor(seedProviders: SeedBlueprintProviders) {
    const seed: Partial<InputFieldBlueprint> = {
      ...seedProviders.forInputField(),
      fieldType: FieldTypes.input,
      validateAs: InputFieldValidation.string,
    };
    super(seed);
  }
}

export class DateFieldBuilder extends FieldBlueprintBuilder {
  constructor(seedProviders: SeedBlueprintProviders) {
    const seed: Partial<DateFieldBlueprint> = {
      ...seedProviders.forInputField(),
      fieldType: FieldTypes.date,
      validateAs: DateFieldValidation.date,
    };
    super(seed);
  }
}

export class YearMonthFieldBuilder extends FieldBlueprintBuilder {
  constructor(seedProviders: SeedBlueprintProviders) {
    const seed: Partial<DateFieldBlueprint> = {
      ...seedProviders.forInputField(),
      fieldType: FieldTypes.date,
      validateAs: DateFieldValidation.yearMonth,
    };
    super(seed);
  }
}

// TODO @QuestionnaireBuilder : Should this be an AgreeField type?
export class ConsentFieldBuilder extends FieldBlueprintBuilder<AgreeFieldBlueprint> {
  constructor(seedProviders: SeedBlueprintProviders) {
    const seed: Partial<AgreeFieldBlueprint> = {
      ...seedProviders.forInputField(),
      fieldType: FieldTypes.agree,
      validateAs: BooleanFieldValidation.booleanTrue,
    } as const;

    super(seed);
  }
}

export class NumberFieldBuilder extends FieldBlueprintBuilder<NumberFieldBlueprint> {
  constructor(seedProviders: SeedBlueprintProviders) {
    const seed: Partial<NumberFieldBlueprint> = {
      ...seedProviders.forInputField(),
      fieldType: FieldTypes.number,
      validateAs: NumberFieldValidation.integer,
      defaultValue: undefined,
    };
    super(seed);
  }
}

export class PhoneFieldBuilder extends FieldBlueprintBuilder<PhoneFieldBlueprint> {
  constructor(seedProviders: SeedBlueprintProviders) {
    const seed: Partial<PhoneFieldBlueprint> = {
      ...seedProviders.forInputField(),
      fieldType: FieldTypes.phone,
      validateAs: PhoneFieldValidation.phone,
    };
    super(seed);
  }
}

export class InformationFieldBuilder extends FieldBlueprintBuilder<InformationFieldBlueprint> {
  constructor(seedProviders: SeedBlueprintProviders) {
    const seed: Partial<InformationFieldBlueprint> = {
      ...seedProviders.forInputField(),
      fieldType: FieldTypes.information,
      validateAs: StringFieldValidation.string,
    };
    super(seed);
  }
}
export class ButtonFieldBuilder extends FieldBlueprintBuilder<ButtonFieldBlueprint> {
  constructor(seedProviders: SeedBlueprintProviders) {
    const seed: Partial<ButtonFieldBlueprint> = {
      ...seedProviders.forInputField(),
      fieldType: FieldTypes.button,
      validateAs: BooleanFieldValidation.boolean,
    };
    super(seed);
  }
}

export class MoneyFieldBuilder extends FieldBlueprintBuilder<MoneyFieldBlueprint> {
  constructor(seedProviders: SeedBlueprintProviders) {
    const seed: Partial<MoneyFieldBlueprint> = {
      ...seedProviders.forInputField(),
      fieldType: FieldTypes.money,
      validateAs: MoneyFieldValidation.integer,
      defaultValue: undefined,
    };
    super(seed);
  }
}

export class RadioFieldBuilder extends FieldBlueprintBuilder<RadioFieldBlueprint> {
  constructor(seedProviders: SeedBlueprintProviders) {
    super(seedProviders.forRadioField());
  }

  public withOptions(options: SelectOptionBlueprint[]): this {
    (this.node as RadioFieldBlueprint).selectOptions = options;
    return this.clone();
  }
}

export class DropdownFieldBuilder extends FieldBlueprintBuilder<SelectOptionFieldBlueprint> {
  constructor(seedProviders: SeedBlueprintProviders) {
    super(seedProviders.forDropdownField());
  }

  public withOptions(options: SelectOptionBlueprint[]): this {
    (this.node as SelectOptionFieldBlueprint).selectOptions = options;
    return this.clone();
  }
}

export class DynamicDropdownFieldBuilder extends FieldBlueprintBuilder<SelectOptionFieldBlueprint> {
  constructor(seedProviders: SeedBlueprintProviders) {
    super(seedProviders.forDropdownField());
  }
}

export class CheckboxGroupFieldBuilder extends FieldBlueprintBuilder<CheckBoxGroupFieldBlueprint> {
  constructor(seedProviders: SeedBlueprintProviders) {
    const seed: Partial<CheckBoxGroupFieldBlueprint> = {
      ...seedProviders.forCheckboxField(),
      fieldType: FieldTypes.checkboxGroup,
      validateAs: StringFieldValidation.string,
      defaultValue: undefined,
    };
    super(seed);
  }

  public withOptions(options: SelectOptionBlueprint[]): this {
    (this.node as CheckBoxGroupFieldBlueprint).selectOptions = options;
    return this.clone();
  }
}

export class SignatureFieldBuilder extends FieldBlueprintBuilder<SignatureFieldBlueprint> {
  constructor(seedProviders: SeedBlueprintProviders, participantRole: ParticipantRoles) {
    super(seedProviders.forSignatureField(participantRole));
  }
}
