import { NodeIdAnswersResolver } from '../answersResolver';
import { QuestionnaireDefinition } from '../structure';
import { EvaluationVisitorAdapter, EvaluationVisitor } from './EvaluationVisitor';
import { Localized } from '../locale';

type Options<TOutput> = {
  evaluationVisitor: EvaluationVisitor<any, TOutput>;
  ruleName: string;
};

function evaluateRulesOn<TOutput>(
  questionnaire: Localized<QuestionnaireDefinition>,
  answersResolver: NodeIdAnswersResolver,
  { evaluationVisitor, ruleName }: Options<TOutput>,
): TOutput {
  const evaluationTraversalVisitor = new EvaluationVisitorAdapter(
    answersResolver,
    evaluationVisitor,
    (node: any) => node[ruleName],
  );

  evaluationTraversalVisitor.visitQuestionnaire(questionnaire);

  return evaluationVisitor.complete();
}

export { evaluateRulesOn };
