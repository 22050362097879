import { v4 as uuid } from 'uuid';

import {
  ApplicationContext,
  BlueprintId,
  InstanceScope,
  DEFAULT_TIMEZONE,
  FieldTypes,
  IAnswerResolver,
  Language,
  RenderingType,
  SubsectionVariant,
  Timezone,
  BothInstanceScope,
} from '@breathelife/types';
import _ from 'lodash';
import {
  IncompleteFieldIdentifier,
  RenderingField,
  RenderingFieldOption,
  RenderingOptionField,
  RenderingQuestion,
  RenderingQuestionnaire,
  RenderingSection,
  RenderingSectionGroup,
  RenderingSubsection,
} from './RenderingQuestionnaire';
import {
  DynamicOptionField,
  Field,
  Question,
  QuestionnaireDefinition,
  RepeatableOptions,
  RepeatableOptionsWithLimits,
  RepeatableQuestion,
  RepeatableQuestionnaireNode,
  RepeatableSectionGroup,
  Section,
  SectionGroup,
  SelectOption,
  Subsection,
  isRepeatableOptionsBasedOnCollection,
  isDynamicOptionField,
  OptionField,
} from '../structure';
import { Localized, TextGetter } from '../locale';
import { FieldValidationSchemas, Validations } from '../validations';
import { buildValidationErrorMessage, evaluateRules, evaluateVisibility } from '../nodeEvaluation';
import { appendRepeatableInstancesToId, formatRepeatableQuestionTitle } from './RepeatableExpansion';
import { hasBeenAnswered } from '../answers';
import { getDynamicOptions } from './populateDynamicOptions';
import {
  FieldWithValue,
  getAppendToKeyValue,
  getAppendToKeyValueForFieldWithDefaultIf,
} from './QuestionnaireTransforms';
import { getInitialFieldValue } from '../questionnaire';
import { isRenderingRepeatedQuestion } from './Structure';

export type RenderingQuestionnaireGeneratorConfig = {
  logUnexpectedAnswers: boolean;
  logValidationErrors: boolean;
};

export const DEFAULT_CONFIG: RenderingQuestionnaireGeneratorConfig = {
  logUnexpectedAnswers: false,
  logValidationErrors: false,
};

type Pointer =
  | RenderingSectionGroup
  | Localized<SectionGroup>
  | RenderingSection
  | Localized<Section>
  | RenderingSubsection
  | Localized<Subsection>
  | RenderingQuestion
  | Localized<Question>
  | RenderingField
  | Localized<Field>;

export function getQuestionnaireNodes(
  questionnaire: Localized<QuestionnaireDefinition>,
): Map<BlueprintId, QuestionnaireNodeItem> {
  const map = new Map<BlueprintId, QuestionnaireNodeItem>();

  for (const sectionGroup of questionnaire) {
    map.set(sectionGroup.blueprintId, { pointer: sectionGroup, parent: null });
    for (const section of sectionGroup.sections) {
      map.set(section.blueprintId, { pointer: section, parent: sectionGroup.blueprintId });
      for (const subsection of section.subsections) {
        map.set(subsection.blueprintId, { pointer: subsection, parent: section.blueprintId });
        for (const question of subsection.questions) {
          map.set(question.blueprintId, { pointer: question, parent: subsection.blueprintId });
          for (const field of question.fields) {
            map.set(field.blueprintId, { pointer: field, parent: question.blueprintId });
          }
        }
      }
    }
  }

  return map;
}

function partialClone(questionnaire: Localized<QuestionnaireDefinition>): Localized<QuestionnaireDefinition> {
  const newQuestionnaire = [];

  for (const sectionGroup of questionnaire) {
    const newSectionGroup = { ...sectionGroup };

    const newSections: Localized<Section[]> = [];
    for (const section of newSectionGroup.sections) {
      const newSection = { ...section };

      const newSubsections: Localized<Subsection[]> = [];
      for (const subsection of newSection.subsections) {
        const newSubsection = { ...subsection };

        const newQuestions: Localized<Question[]> = [];
        for (const question of subsection.questions) {
          const newQuestion = { ...question };

          const newFields: Localized<Field[]> = [];
          for (const field of question.fields) {
            const newField = { ...field };

            if ((field as Localized<OptionField>).options) {
              const newOptions: Localized<SelectOption[]> = [];

              for (const option of (field as Localized<OptionField>).options) {
                const newOption = { ...option };
                newOptions.push(newOption);
              }

              (newField as Localized<OptionField>).options = newOptions;
            }
            newFields.push(newField);
          }
          newQuestion.fields = newFields;
          newQuestions.push(newQuestion);
        }

        newSubsection.questions = newQuestions;
        newSubsections.push(newSubsection);
      }

      newSection.subsections = newSubsections;
      newSections.push(newSection);
    }
    newSectionGroup.sections = newSections;
    newQuestionnaire.push(newSectionGroup);
  }

  return newQuestionnaire;
}

export type QuestionnaireNodeItem = {
  pointer:
    | Localized<SectionGroup>
    | Localized<Section>
    | Localized<Subsection>
    | Localized<Question>
    | Localized<Field>;
  parent: BlueprintId | null;
};

type Counters = {
  sectionGroup: { oneSectionIsNotCompleted: boolean };
  section: { oneSubsectionIsNotCompleted: boolean; numberOfIncompleteFields: number; numberOfInvalidFields: number };
  subsection: { oneQuestionIsNotCompleted: boolean; numberOfIncompleteFields: number; numberOfInvalidFields: number };
  question: {
    oneFieldIsVisible: boolean;
    oneFieldIsInvalid: boolean;
    numberOfIncompleteFields: number;
    numberOfInvalidFields: number;
  };
};

type Env = {
  questionnaire: Localized<QuestionnaireDefinition>;
  questionnaireNodes: Map<BlueprintId, QuestionnaireNodeItem>;
  answersResolver: IAnswerResolver;
  renderingType: RenderingType;
  timezone: Timezone;
  applicationContext: ApplicationContext;
  language: Language;
  readonly: boolean;
  text: TextGetter;
  displayErrors: boolean;
  fieldSchemas: FieldValidationSchemas;
  isLoadingFields: boolean;
  cloningStrategy: 'CacheSafe' | 'Fast';
  currentDateOverride: string | null;
  counters: Counters;
};

type Dependencies = {
  questionnaire: Localized<QuestionnaireDefinition>;
  questionnaireNodes: Map<BlueprintId, QuestionnaireNodeItem>;
  answersResolver: IAnswerResolver;
  applicationContext: ApplicationContext;
  language: Language;
  displayErrors: boolean;
  fieldValidationSchemas: FieldValidationSchemas;
  text?: TextGetter;
  readonly?: boolean;
  renderingType?: RenderingType;
  timezone?: Timezone;
  isLoadingFields?: boolean;
  currentDateOverride: string | null;
};

function resetSectionGroupCounters(counters: Counters): void {
  counters.sectionGroup.oneSectionIsNotCompleted = false;
}

function resetSectionCounters(counters: Counters): void {
  counters.section.oneSubsectionIsNotCompleted = false;
  counters.section.numberOfIncompleteFields = 0;
  counters.section.numberOfInvalidFields = 0;
}

function resetSubsectionCounters(counters: Counters): void {
  counters.subsection.oneQuestionIsNotCompleted = false;
  counters.subsection.numberOfIncompleteFields = 0;
  counters.subsection.numberOfInvalidFields = 0;
}

function resetQuestionCounters(counters: Counters): void {
  counters.question.oneFieldIsVisible = false;
  counters.question.oneFieldIsInvalid = false;
  counters.question.numberOfIncompleteFields = 0;
  counters.question.numberOfInvalidFields = 0;
}

function resetAllCounters(counters: Counters): void {
  resetSectionGroupCounters(counters);
  resetSectionCounters(counters);
  resetSubsectionCounters(counters);
  resetQuestionCounters(counters);
}

export class RenderingQuestionnaireGenerator {
  private env: Env;
  private renderingQuestionnaire: RenderingQuestionnaire;

  constructor(deps: Dependencies, cloningStrategy: 'CacheSafe' | 'Fast' = 'CacheSafe') {
    this.env = {
      questionnaireNodes: deps.questionnaireNodes,
      answersResolver: deps.answersResolver,
      questionnaire: deps.questionnaire,
      renderingType: deps.renderingType || RenderingType.web,
      timezone: deps.timezone || DEFAULT_TIMEZONE,
      applicationContext: deps.applicationContext,
      language: deps.language,
      readonly: deps.readonly || false,
      text: deps.text || ((e) => e),
      displayErrors: deps.displayErrors,
      fieldSchemas: deps.fieldValidationSchemas,
      isLoadingFields: deps.isLoadingFields || false,
      cloningStrategy: cloningStrategy,
      currentDateOverride: deps.currentDateOverride, // This is an old hack to disable the greencheck marks (completed) while the questionnaire is loading.
      counters: {
        sectionGroup: { oneSectionIsNotCompleted: false },
        section: { oneSubsectionIsNotCompleted: false, numberOfIncompleteFields: 0, numberOfInvalidFields: 0 },
        subsection: { oneQuestionIsNotCompleted: false, numberOfIncompleteFields: 0, numberOfInvalidFields: 0 },
        question: {
          oneFieldIsVisible: false,
          oneFieldIsInvalid: false,
          numberOfIncompleteFields: 0,
          numberOfInvalidFields: 0,
        },
      },
    };

    this.renderingQuestionnaire = mainLoop(this.env);
  }

  get(): RenderingQuestionnaire {
    return this.renderingQuestionnaire;
  }

  generate(): RenderingQuestionnaire {
    this.renderingQuestionnaire = mainLoop(this.env);
    return this.renderingQuestionnaire;
  }
}

function mainLoop(env: Env): RenderingQuestionnaire {
  resetAllCounters(env.counters);

  let clonedQuestionnaire: Localized<QuestionnaireDefinition>;
  if (env.cloningStrategy === 'Fast') {
    clonedQuestionnaire = partialClone(env.questionnaire);
  } else {
    clonedQuestionnaire = _.cloneDeep(env.questionnaire);
  }

  const rootScope = {
    byBlueprintId: {} as InstanceScope,
    byNodeId: {} as InstanceScope,
  };

  const sectionGroups = clonedQuestionnaire;
  const [expandedSectionGroups, sectionGroupTracking] = expandChildren(sectionGroups, rootScope.byBlueprintId, env);

  let indexSectionGroup = 0;
  let previousSectionGroupId: string | null = null;

  for (const sectionGroup of expandedSectionGroups) {
    resetSectionGroupCounters(env.counters);
    if (previousSectionGroupId && sectionGroup.blueprintId !== previousSectionGroupId) {
      indexSectionGroup = 0;
    }
    const sectionGroupScopes = {
      byBlueprintId: { ...rootScope.byBlueprintId },
      byNodeId: { ...rootScope.byNodeId },
    };

    if (sectionGroup.options?.repeatable && (sectionGroup as Localized<RepeatableSectionGroup>).nodeId) {
      sectionGroupScopes.byBlueprintId[sectionGroup.blueprintId] = indexSectionGroup;
      if ((sectionGroup as Localized<RepeatableSectionGroup>).nodeId) {
        sectionGroupScopes.byNodeId[(sectionGroup as Localized<RepeatableSectionGroup>).nodeId] = indexSectionGroup;
      }
    }

    updateSectionGroup(
      sectionGroup,
      sectionGroupScopes,
      indexSectionGroup,
      sectionGroupTracking[sectionGroup.blueprintId] || 1,
      env,
    );

    if ((sectionGroup as any).visible) {
      const sections = sectionGroup.sections;
      const [expandedSections] = expandChildren(sections, sectionGroupScopes.byBlueprintId, env);

      for (const section of expandedSections) {
        const sectionIncompleteFieldIdentifiers: IncompleteFieldIdentifier[] = [];

        resetSectionCounters(env.counters);
        const sectionScopes = sectionGroupScopes; // Sections are not repeatable, so the scope is the same as sectionGroup.

        updateSection(section, sectionScopes, env);

        if ((section as any).visible) {
          const subsections = section.subsections;
          const [expandedSubsections] = expandChildren(subsections, sectionScopes.byBlueprintId, env);
          (section as any).numberOfInvalidFields = 0;
          (section as any).numberOfIncompleteFields = 0;
          for (const subsection of expandedSubsections) {
            resetSubsectionCounters(env.counters);
            const subsectionScope = sectionScopes; // Subsections are not repeatable, so the scope is the same as section.

            updateSubsection(subsection, subsectionScope, env);

            if ((subsection as any).visible) {
              const questions = subsection.questions;
              const [expandedQuestions, questionTracking] = expandChildren(
                questions,
                subsectionScope.byBlueprintId,
                env,
              );

              let indexQuestion = 0;
              let previousQuestionId: string | null = null;
              for (const question of expandedQuestions) {
                resetQuestionCounters(env.counters);
                if (previousQuestionId && question.blueprintId !== previousQuestionId) {
                  indexQuestion = 0;
                }

                const questionScope = {
                  byBlueprintId: { ...subsectionScope.byBlueprintId },
                  byNodeId: { ...subsectionScope.byNodeId },
                };

                if (question.options?.repeatable && (question as RepeatableQuestion).nodeId) {
                  questionScope.byBlueprintId[question.blueprintId] = indexQuestion;
                  if ((question as RepeatableQuestion).nodeId) {
                    questionScope.byNodeId[(question as RepeatableQuestion).nodeId] = indexQuestion;
                  }
                }

                const total = questionTracking[question.blueprintId];
                updateQuestion(question, questionScope, indexQuestion, total, env);

                if ((question as any).visible) {
                  let firstIncompleteFieldIdentifierOfCardHasBeenAdded = false;
                  const fields = question.fields;
                  const [expandedFields] = expandChildren(fields, questionScope.byBlueprintId, env);

                  if (!env.isLoadingFields) {
                    for (const field of expandedFields) {
                      const { fieldIsIncomplete } = updateField(field, questionScope, env);
                      if ((field as any).visible === true) {
                        if ((field as any).options) {
                          for (const option of (field as any).options) {
                            updateOption(option, questionScope, env);
                          }
                        }
                      }
                      if (fieldIsIncomplete) {
                        if (!firstIncompleteFieldIdentifierOfCardHasBeenAdded) {
                          sectionIncompleteFieldIdentifiers.push({
                            sectionGroupId: sectionGroup.id,
                            sectionId: section.blueprintId,
                            fieldId: field.blueprintId,
                          });
                        }
                        if (question.displayAsCard) {
                          firstIncompleteFieldIdentifierOfCardHasBeenAdded = true;
                        }
                      }
                    }
                  }
                  question.fields = expandedFields;

                  if (question.fields?.length > 0 && env.counters.question.oneFieldIsVisible === false) {
                    (question as any).visible = false;
                  }

                  (question as any).completed = env.isLoadingFields
                    ? false
                    : env.counters.question.oneFieldIsInvalid === false;

                  if ((question as any).completed === false) {
                    env.counters.subsection.oneQuestionIsNotCompleted = true;
                  }
                  env.counters.subsection.numberOfIncompleteFields += env.counters.question.numberOfIncompleteFields;
                  env.counters.subsection.numberOfInvalidFields += env.counters.question.numberOfInvalidFields;
                }

                indexQuestion++;
                previousQuestionId = question.blueprintId;
              }
              subsection.questions = expandedQuestions;

              (subsection as any).completed = env.isLoadingFields
                ? false
                : env.counters.subsection.oneQuestionIsNotCompleted === false;

              env.counters.section.numberOfIncompleteFields += env.counters.subsection.numberOfIncompleteFields;
              env.counters.section.numberOfInvalidFields += env.counters.subsection.numberOfInvalidFields;

              if ((subsection as any).completed === false) {
                env.counters.section.oneSubsectionIsNotCompleted = true;
              }
            }
          }
          section.subsections = expandedSubsections;

          (section as any).completed = env.isLoadingFields
            ? false
            : env.counters.section.oneSubsectionIsNotCompleted === false;

          if ((section as any).completed === false) {
            env.counters.sectionGroup.oneSectionIsNotCompleted = true;
          }
          (section as any).numberOfInvalidFields = env.counters.section.numberOfInvalidFields;
          (section as any).numberOfIncompleteFields = env.counters.section.numberOfIncompleteFields;
          (section as any).incompleteFieldIdentifiers = sectionIncompleteFieldIdentifiers;
        }
      }
      sectionGroup.sections = expandedSections;

      (sectionGroup as any).completed = env.isLoadingFields
        ? false
        : env.counters.sectionGroup.oneSectionIsNotCompleted === false;
    }

    indexSectionGroup++;
    previousSectionGroupId = sectionGroup.blueprintId;
  }

  return expandedSectionGroups as any as RenderingQuestionnaire;
}

export function expandChildren<T extends Pointer>(
  original: T[] | Localized<QuestionnaireDefinition>,
  scope: InstanceScope,
  env: Env,
): [T[], Record<BlueprintId, number>] {
  const tracking: Record<BlueprintId, number> = {};
  const totalWantedByBlueprintId: Record<BlueprintId, number> = {};

  const newChildren = [];

  for (let elementIndex = 0; elementIndex < original.length; ++elementIndex) {
    const currentElement = original[elementIndex];
    tracking[currentElement.blueprintId] = (tracking[currentElement.blueprintId] || 0) + 1;
    newChildren.push(currentElement); // Push/reuse the original to save memory/performance.

    const options: RepeatableOptions | undefined = (currentElement as any as SectionGroup).options;
    if (options && options.repeatable) {
      // Calculate total wanted if its the first time we find that node.
      let totalWanted = totalWantedByBlueprintId[currentElement.blueprintId];
      if (!totalWanted) {
        totalWanted = getTotalWanted(currentElement, scope, env);
        totalWantedByBlueprintId[currentElement.blueprintId] = totalWanted;
      }

      const nextIndex = elementIndex + 1;

      // If we are at the end of the array or about to switch another blueprint ID.
      if (nextIndex >= original.length || original[nextIndex].blueprintId !== currentElement.blueprintId) {
        const existingSiblings = tracking[currentElement.blueprintId];

        for (let i = existingSiblings; i < totalWanted; ++i) {
          const definition = env.questionnaireNodes.get(currentElement.blueprintId);
          if (!definition) {
            throw new Error(`Can find the blueprintId "${currentElement.blueprintId}" in the questionnaire nodes map.`);
          }
          const newSibling = JSON.parse(JSON.stringify(definition.pointer)); // Algorithm to generate the sub-tree.
          newChildren.push(newSibling); // Clone the element or take it from the blueprintMap in the diagram (the later for final solution)
          tracking[currentElement.blueprintId] = tracking[currentElement.blueprintId] + 1;
        }
      }
    }
  }
  return [newChildren, tracking];
}

function getTotalWanted(pointer: Pointer, scope: InstanceScope, env: Env): number {
  const currentElement = pointer as any as RepeatableQuestionnaireNode;

  if ((currentElement.options as RepeatableOptionsWithLimits).minRepetitions) {
    return env.answersResolver.getRepetitionCount(currentElement.blueprintId, scope) || 1;
  }

  // TODO: Total Wanted based on another collection.

  // totalWanted = howManyBased on XYZ collection.
  return 1;
}

function updateRepetitionMetadataWithAdditionalScope(
  pointer: Pointer,
  scopes: BothInstanceScope,
  index: number,
  total: number,
  env: Env,
): void {
  const node: RepeatableQuestionnaireNode = pointer as any as RepeatableQuestionnaireNode;

  const surrogateId = surrogateIdFor(node.blueprintId, scopes.byBlueprintId, env);

  (pointer as any).surrogateId = surrogateId;
  (pointer as any).metadata = {};
  (pointer as any).metadata.repetitionIndex = index;
  (pointer as any).metadata.repetitionCount = total;
  (pointer as any).metadata.parentId = node.id;
  pointer.id = `${pointer.id}.${index}`;
  (pointer as any).metadata.repeatedInstanceIdentifierContext = scopes; // Maybe removed ? Its also done in updateRepetitionMetadataWithParentData
}

function updateSubsection(subsection: Localized<Subsection>, scopes: BothInstanceScope, env: Env): void {
  (subsection as any).metadata = { repeatedInstanceIdentifierContext: scopes };

  (subsection as any).visible = evaluateVisibility(
    subsection,
    env.renderingType,
    env.answersResolver,
    scopes.byNodeId, // Conditions are using only node ids right now.
    env.timezone,
    env.currentDateOverride,
    subsection.visibleIf,
  );

  if (!subsection.variant) {
    subsection.variant = SubsectionVariant.form;
  }
}

function updateSection(section: Localized<Section>, scopes: BothInstanceScope, env: Env): void {
  (section as any).metadata = { repeatedInstanceIdentifierContext: scopes };

  (section as any).visible = evaluateVisibility(
    section,
    env.renderingType,
    env.answersResolver,
    scopes.byNodeId, // Conditions are using only node ids right now.
    env.timezone,
    env.currentDateOverride,
    section.visibleIf,
  );
}

function updateField(field: Localized<Field>, scopes: BothInstanceScope, env: Env): { fieldIsIncomplete: boolean } {
  let fieldIsIncomplete = false;

  (field as any).metadata = { repeatedInstanceIdentifierContext: scopes };
  if (Object.keys(scopes.byBlueprintId).length > 0) {
    field.id = appendRepeatableInstancesToId(field.id, scopes.byBlueprintId);
  }

  if ((field as any).options) {
    const f = field as RenderingOptionField;
    for (const option of f.options) {
      (option as any).metadata = {
        repeatedInstanceIdentifierContext: f.metadata.repeatedInstanceIdentifierContext,
      };
    }
  }

  (field as any).visible = evaluateVisibility(
    field,
    env.renderingType,
    env.answersResolver,
    scopes.byNodeId, // Conditions are using only node ids right now.
    env.timezone,
    env.currentDateOverride,
    field.visibleIf,
  );

  if (!(field as any).visible) {
    return { fieldIsIncomplete };
  }

  env.counters.question.oneFieldIsVisible = true;

  populateDynamicOptions(field, scopes, env); // This feature uses NodeId references to define where to fetch the stuff, we would need to migrate it to blueprintID in the blueprints.

  populateApplicationContext(field, scopes, env);

  setFieldValues(field, scopes, env); // The relatesTo uses nodeIds

  setFieldSchemaValidations(field, scopes.byBlueprintId, env);

  evaluateValidityConditions(field, scopes, env); // Conditions are using only node ids right now.

  if ((field as any).valid === false) {
    env.counters.question.oneFieldIsInvalid = true;
  }

  if (env.readonly) {
    field.disabled = true;
  }

  if (
    !(field as any).value &&
    (field as any).value !== 0 &&
    !(field as any).optional &&
    field.type !== FieldTypes.information
  ) {
    env.counters.question.numberOfIncompleteFields += 1;
    fieldIsIncomplete = true;
    (field as any).incomplete = true;
  }
  if (((field as any).value || field.type === FieldTypes.information) && !(field as any).valid) {
    env.counters.question.numberOfInvalidFields += 1;
    fieldIsIncomplete = true;
  }

  return { fieldIsIncomplete };
}

function updateOption(option: SelectOption, scopes: BothInstanceScope, env: Env): void {
  (option as any).visible = evaluateVisibility(
    option,
    env.renderingType,
    env.answersResolver,
    (option as any).metadata?.repeatedInstanceIdentifierContext.byNodeId || scopes.byNodeId,
    env.timezone,
    env.currentDateOverride,
    option.visibleIf,
  );
}

function setFieldSchemaValidations(field: Localized<Field>, scope: InstanceScope, env: Env): void {
  if (env.readonly) {
    return;
  }

  // Fields explicitly marked optional:false are always required
  const isRequired =
    field.optional === false || (!field.optional && !field.disabled && field.type !== FieldTypes.information);

  const fieldValidationType: Validations = field.validation.type;
  const fieldSchema = isRequired
    ? env.fieldSchemas.required[fieldValidationType]
    : env.fieldSchemas.optional[fieldValidationType];

  const fieldWithEvaluatedValidation = field as any;
  try {
    fieldSchema.validateSync((field as any).value);
    fieldWithEvaluatedValidation.valid = true;
  } catch (error: any) {
    fieldWithEvaluatedValidation.valid = false;
    if (env.displayErrors || hasBeenAnswered((field as any).value)) {
      fieldWithEvaluatedValidation.validationError = { message: error.message };
    }
  }
}

function evaluateValidityConditions(field: Localized<Field>, scopes: BothInstanceScope, env: Env): void {
  const { invalidRules, validationData } = evaluateRules(
    field.validIf ?? [],
    env.answersResolver,
    scopes.byNodeId, // Conditions are using only node ids right now.
    env.timezone,
    env.currentDateOverride,
    field?.validation?.type,
  );

  const fieldAnswer = env.answersResolver.getAnswer(field.blueprintId, scopes.byBlueprintId);

  const isValid = invalidRules.length === 0;
  (field as any).valid = (field as any).valid && isValid;
  (field as any).validationData = validationData;

  const shouldSetValidationMessage = env.displayErrors || hasBeenAnswered(fieldAnswer);
  if (!isValid && shouldSetValidationMessage && !(field as any).validationError) {
    const firstBrokenRule = invalidRules[0];
    (field as any).validationError = { message: buildValidationErrorMessage(firstBrokenRule) };
  }
}

function populateDynamicOptions(field: Localized<Field>, scopes: BothInstanceScope, env: Env): void {
  if (isDynamicOptionField(field)) {
    const node = field as DynamicOptionField;

    const dynamicOptions = getDynamicOptions(node.dynamicOptions, scopes.byNodeId, env.answersResolver);

    const transitionDynamicOptions: SelectOption[] = dynamicOptions.map(
      (option: any) =>
        ({
          ...option,
          // TODO: When` fr/en can possibly differ, localize this. (copied from the visitor dunno why its english only) (Maybe -> env.text(env.language))
          text: option.text.en,
          metadata: { repeatedInstanceIdentifierContext: option.metadata?.repeatedInstanceIdentifierContext || scopes },
        }) as any as SelectOption,
    );

    node.options = [...transitionDynamicOptions, ...(node.options || [])];
  }
}

function setFieldValues(field: Localized<Field>, scopes: BothInstanceScope, env: Env): void {
  const answer = env.answersResolver.getAnswer(field.blueprintId, scopes.byBlueprintId);
  const fieldWithValue = field as unknown as FieldWithValue;

  fieldWithValue.value = typeof answer === 'undefined' ? getInitialFieldValue(field as RenderingField) : answer;

  if (field.defaultIf !== undefined) {
    (field as any).appendToKeyValue = getAppendToKeyValueForFieldWithDefaultIf(
      field as RenderingField,
      env.answersResolver,
    );
  }

  if (field.relatesTo !== undefined) {
    const relatesToValue = env.answersResolver.usingNodeId().getAnswer(field.relatesTo, scopes.byNodeId);

    if (typeof relatesToValue !== 'undefined') {
      const appendToKeyValue = fieldWithValue.appendToKeyValue ?? '';
      fieldWithValue.appendToKeyValue = getAppendToKeyValue(appendToKeyValue, relatesToValue);
    }
  }
}

function populateApplicationContext(field: Localized<Field>, scopes: BothInstanceScope, env: Env): void {
  const node = field as any;
  if (node.optionsFromApplicationContext) {
    const { tag, labelKey, valuePath } = node.optionsFromApplicationContext;

    const applicationContextData = env.applicationContext[tag];

    if (!applicationContextData) {
      return;
    }

    if (!Array.isArray(applicationContextData)) {
      return;
    }

    const renderingFieldOptions: RenderingFieldOption[] = applicationContextData.map((acd) => {
      return {
        id: _.get(acd, valuePath, ''),
        text: _.get(acd, labelKey[env.language], ''),
        disabled: node.disabled,
        iconName: node.iconName,
        info: node.info,
        metadata: { repeatedInstanceIdentifierContext: scopes },
        title: node.title,
        visible: (node as any).visible || false, // The default should not happen.
      };
    });

    node.options = renderingFieldOptions;
  }
}

function updateSectionGroup(
  sectionGroup: Localized<SectionGroup>,
  scopes: BothInstanceScope,
  index: number,
  repetitionCount: number,
  env: Env,
): void {
  if ((sectionGroup as any).options?.repeatable) {
    // setSurrogateIdIfNonePresent(env, scopes.byBlueprintId, sectionGroup.blueprintId, index);
    updateRepetitionMetadataWithAdditionalScope(sectionGroup, scopes, index, repetitionCount, env);
  } else {
    (sectionGroup as any).metadata = {
      repeatedInstanceIdentifierContext: scopes,
    };
  }

  (sectionGroup as any).visible = evaluateVisibility(
    sectionGroup,
    env.renderingType,
    env.answersResolver,
    scopes.byNodeId, // Conditions are using only node ids right now.
    env.timezone,
    env.currentDateOverride,
    sectionGroup.visibleIf,
  );
}

function updateQuestion(
  question: Localized<Question>,
  scopes: BothInstanceScope,
  index: number,
  repetitionCount: number,
  env: Env,
): void {
  if ((question as any).options?.repeatable) {
    // setSurrogateIdIfNonePresent(env, scopes.byBlueprintId, question.blueprintId, index);
    updateRepetitionMetadataWithAdditionalScope(question, scopes, index, repetitionCount, env);
    const expandedQuestion = question as any;

    const repetitions = repetitionCount;

    if (isRepeatableOptionsBasedOnCollection(expandedQuestion.options)) {
      // nosemgrep: insecure-object-assign
      Object.assign(expandedQuestion, {
        showRemoveQuestionButton: false,
        showAddQuestionButton: false,
      });
      return;
    }

    const hasReachedMinimumRepetitions: boolean = repetitions === expandedQuestion.options.minRepetitions;
    const isLastRepetition: boolean = index === repetitions - 1;
    const hasRepetitionsLeft: boolean = index < expandedQuestion.options.maxRepetitions - 1;

    // nosemgrep: insecure-object-assign
    Object.assign(expandedQuestion, {
      title: formatRepeatableQuestionTitle(expandedQuestion.title, index),
      showRemoveQuestionButton: !hasReachedMinimumRepetitions,
      showAddQuestionButton: isLastRepetition && hasRepetitionsLeft,
    });
  } else {
    (question as any).metadata = { repeatedInstanceIdentifierContext: scopes };
  }

  (question as any).visible = evaluateVisibility(
    question,
    env.renderingType,
    env.answersResolver,
    scopes.byNodeId, // Conditions are using only node ids right now.
    env.timezone,
    env.currentDateOverride,
    question.visibleIf,
  );

  if (env.readonly && isRenderingRepeatedQuestion(question as any)) {
    (question as any).showRemoveQuestionButton = false;
    (question as any).showAddQuestionButton = false;
  }
}

function surrogateIdFor(
  blueprintId: string,
  repeatedInstanceIdentifierContext: InstanceScope,
  env: Env,
): string | undefined {
  const existingSurrogateId = env.answersResolver.getAnswer(
    blueprintId,
    repeatedInstanceIdentifierContext,
  )?.surrogateId;

  return existingSurrogateId || uuid();
}

// function setSurrogateIdIfNonePresent(
//   env: Env,
//   scope: BlueprintIdInstanceScope,
//   blueprintId: string,
//   index: number,
//   collectionBlueprintId?: string,
// ): void {
//   if (typeof scope[blueprintId] === 'undefined') {
//     // If no index is provided we cannot create a surrogateId for a collection item.
//     return;
//   }

//   const result = env.answersResolver.getInstanceId(blueprintId, scope);

//   if (!result.success) {
//     let surrogateId: string | undefined = undefined;
//     if (scope && collectionBlueprintId) {
//       //If we have an index and a collection ID, use the surrogateId from the same index in the collection.
//       const correspondingItemInAnotherCollectionItem = env.answersResolver.getAnswer(collectionBlueprintId, {
//         [collectionBlueprintId]: index,
//       });
//       surrogateId = correspondingItemInAnotherCollectionItem?.surrogateId || uuid();
//     } else {
//       surrogateId = uuid();
//     }

//     env.answersResolver.setInstanceId(blueprintId, scope, surrogateId);
//   }
// }
