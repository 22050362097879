import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import {
  IAnswerResolver,
  QuestionnaireBlueprintCopyableOption,
  InstanceScope,
  QuestionnaireBlueprint,
  BlueprintId,
  NodeId,
} from '@breathelife/types';

import { ExpandedRepetitionsVisitor } from '../expandedContext/ExpandedRepetitionsVisitor';
import {
  isRepeatableOptionsBasedOnCollection,
  QuestionnaireDefinition,
  RepeatableQuestion,
  RepeatableSectionGroup,
  Field,
  DynamicOptionField,
  DynamicOptions,
} from '../structure';
import { Localized } from '../locale';
import { translateNodeIdToBlueprintId } from '../answersResolver';

export class CopyAnswersVisitor extends ExpandedRepetitionsVisitor {
  private copyableOptions: QuestionnaireBlueprintCopyableOption[];
  private blueprintIdsToUnset: { blueprintId: string; nodeId?: string; scope: InstanceScope }[];

  private nodeIdsSetAsCopyable: string[];
  private readonly destinationAnswersResolver: IAnswerResolver;
  private fieldsWithDynamicOptions: {
    nodeId: string;
    answer: any;
    dynamicOptions: DynamicOptions;
    scope: InstanceScope;
  }[];
  private readonly nodeIdToBlueprintId: Record<NodeId, BlueprintId>;

  constructor(
    destinationAnswersResolver: IAnswerResolver,
    copyableOptions: QuestionnaireBlueprintCopyableOption[],
    blueprint: QuestionnaireBlueprint,
  ) {
    super(destinationAnswersResolver);
    this.copyableOptions = copyableOptions;
    this.blueprintIdsToUnset = [];
    this.nodeIdsSetAsCopyable = [];
    this.fieldsWithDynamicOptions = [];
    this.destinationAnswersResolver = destinationAnswersResolver;
    this.nodeIdToBlueprintId = translateNodeIdToBlueprintId(blueprint);
  }

  public visitQuestionnaire(questionnaire: Localized<QuestionnaireDefinition>): void {
    super.visitQuestionnaire(questionnaire);
    this.unsetNonCopyableAnswers();
    this.updateDynamicOptionsValues();
  }

  protected visitRepeatedSectionGroup(sectionGroup: Localized<RepeatableSectionGroup>): void {
    if (!sectionGroup.readOnly) {
      this.overrideDefaultSurrogateIds(sectionGroup.blueprintId);
      super.visitRepeatedSectionGroup(sectionGroup);
    }
  }

  protected visitRepeatedQuestion(question: Localized<RepeatableQuestion>, repetitionIndex: number): void {
    if (!question.readOnly) {
      if (isRepeatableOptionsBasedOnCollection(question.options)) {
        this.overrideDefaultSurrogateIds(
          question.blueprintId,
          question.options.expandToCollectionLength,
          repetitionIndex,
        );
      } else {
        this.overrideDefaultSurrogateIds(question.blueprintId);
      }

      super.visitRepeatedQuestion(question, repetitionIndex);
    }
  }

  protected visitField(field: Localized<Field>): void {
    super.visitField(field);

    const fieldIsCopyable = field.copyable && this.copyableOptions.includes(field.copyable);
    if (!fieldIsCopyable) {
      // Add field answer to be unset
      this.blueprintIdsToUnset.push({
        blueprintId: field.blueprintId,
        nodeId: field.nodeId,
        scope: this.repeatedInstanceIdentifiers().byBlueprintId,
      });
    }

    // If there's a node id for the copyable blueprint id, we add it to nodeIdsSetAsCopyableto to filter later
    if (field.nodeId && fieldIsCopyable && !this.nodeIdsSetAsCopyable.includes(field.nodeId)) {
      this.nodeIdsSetAsCopyable.push(field.nodeId);
    }

    // If field uses dynamic options, add to fieldsWithDynamicOptions
    const fieldWithDynamicOptions = (field as DynamicOptionField).dynamicOptions;
    if (fieldWithDynamicOptions) {
      const currentAnswer = this.answersResolver.getAnswer(
        field.blueprintId,
        this.repeatedInstanceIdentifiers().byBlueprintId,
      );
      if (currentAnswer && field.nodeId) {
        this.fieldsWithDynamicOptions.push({
          nodeId: field.nodeId,
          answer: currentAnswer,
          dynamicOptions: (field as DynamicOptionField).dynamicOptions,
          scope: this.repeatedInstanceIdentifiers().byNodeId,
        });
      }
    }
  }

  private overrideDefaultSurrogateIds(
    blueprintId: string,
    collectionblueprintId?: string,
    repetitionIndex?: number,
  ): void {
    const repeatedInstanceIdentifiers = this.repeatedInstanceIdentifiers().byBlueprintId;

    if (typeof repeatedInstanceIdentifiers[blueprintId] === 'undefined') {
      // If no index is provided we cannot create a surrogateId for a collection item.
      return;
    }

    let identifiedAnswerItem = this.destinationAnswersResolver.getAnswer(blueprintId, repeatedInstanceIdentifiers);

    if (typeof identifiedAnswerItem === 'undefined') {
      identifiedAnswerItem = {};
      this.destinationAnswersResolver.setAnswer(identifiedAnswerItem, blueprintId, repeatedInstanceIdentifiers);
    }

    if (repeatedInstanceIdentifiers.byBlueprintId && collectionblueprintId && repetitionIndex !== undefined) {
      //If we have an index and a collection blueprintId, use the surrogateId from the same index in the collection.
      const correspondingItemInAnotherCollectionItem = this.destinationAnswersResolver.getAnswer(
        collectionblueprintId,
        {
          [collectionblueprintId]: repetitionIndex,
        },
      );
      identifiedAnswerItem.surrogateId = correspondingItemInAnotherCollectionItem?.surrogateId || uuid();
    } else {
      const surrogateId = uuid();
      identifiedAnswerItem.surrogateId = surrogateId;
    }
  }

  // Unsets all answers that don't link to a node id that is set as copyable
  private unsetNonCopyableAnswers(): void {
    const collectionArray: string[] = [];
    for (const { blueprintId, scope, nodeId } of this.blueprintIdsToUnset) {
      if (nodeId && this.nodeIdsSetAsCopyable.includes(nodeId)) {
        continue;
      }

      this.destinationAnswersResolver.unsetAnswer(blueprintId, scope);
      for (const blueprintId in scope) {
        if (!collectionArray.includes(blueprintId)) {
          collectionArray.push(blueprintId);
        }
      }
    }

    collectionArray.forEach((blueprintId) => {
      const collection = this.destinationAnswersResolver.getCollection(blueprintId);
      if (collection) {
        collection.forEach((value, index) => {
          const remainingAnswers = Object.keys(value).filter((key) => key !== 'surrogateId');
          if (!remainingAnswers.length && index > 0) {
            this.destinationAnswersResolver.unsetAnswer(blueprintId, {
              [blueprintId]: index,
            });
          }
        });
        this.destinationAnswersResolver.removeUndefinedAnswersFromCollection(blueprintId);
      }
    });
  }

  // Update the dynamic option values to match the new surrogateIds after answers being copied
  private updateDynamicOptionsValues(): void {
    this.fieldsWithDynamicOptions.forEach((value) => {
      // Check if all of the dynamic options nodeIds are copyable
      const dynamicOptionsCollectionNodeId = value.dynamicOptions.collection; // collection nodeId
      const dynamicOptionsCollectionBlueprintId = this.nodeIdToBlueprintId[dynamicOptionsCollectionNodeId];
      const isCollectionCopyable = !this.blueprintIdsToUnset.find(
        (item) => item.blueprintId === dynamicOptionsCollectionBlueprintId,
      );
      if (isCollectionCopyable) {
        const dynamicOptionsNodeIds = value.dynamicOptions.select; // dynamic options nodeIds
        const nonCopyableDynamicOptionsNodeIds = dynamicOptionsNodeIds.filter((nodeId) => {
          const optionBlueprintId = this.nodeIdToBlueprintId[nodeId];
          const isOptionCopyable = !this.blueprintIdsToUnset.find((item) => item.blueprintId === optionBlueprintId);
          return isOptionCopyable;
        });

        if (nonCopyableDynamicOptionsNodeIds.length) return;
      }
      // Get the dynamic options collection with the OLD surrogateIds
      const existingDynamicOptionsCollection = this.answersResolver
        .usingNodeId()
        .getCollection(value.dynamicOptions.collection, value.scope);

      // Find the index of the current answer (surrogateid) in the old collection
      const existingAnswerKey = existingDynamicOptionsCollection?.findIndex(
        (item) => value.answer === item.surrogateId,
      );

      // Get the dynamic options collection with the NEW surrogateIds
      const newDynamicOptionsCollection = this.answersResolver
        .usingNodeId()
        .getCollection(value.dynamicOptions.collection, value.scope);

      // Update the answer for the field with a dynamic option
      if (newDynamicOptionsCollection && existingAnswerKey !== undefined && existingAnswerKey !== -1) {
        this.answersResolver
          .usingNodeId()
          .setAnswer(newDynamicOptionsCollection[existingAnswerKey].surrogateId, value.nodeId, value.scope);
      }
    });
  }
}

export function copyAnswers(
  questionnaire: Localized<QuestionnaireDefinition>,
  destinationAnswersResolver: IAnswerResolver,
  copyableOptions: QuestionnaireBlueprintCopyableOption[],
  blueprint: QuestionnaireBlueprint,
): void {
  const copyAnswersVisitor = new CopyAnswersVisitor(destinationAnswersResolver, copyableOptions, blueprint);
  copyAnswersVisitor.visitQuestionnaire(questionnaire);
}
