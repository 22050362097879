import { failure, Result, success } from '@breathelife/result';
import {
  IAnswerResolver,
  InstanceIndex,
  InstanceScope,
  RepeatedAnswersBySurrogateId,
  Answers,
  QuestionnaireBlueprint,
  NodeInstance,
  SurrogateId,
  NodeId,
  BlueprintId,
  BlueprintIdInstanceScope,
  VersionedAnswers,
  AnswersResolverLogger,
} from '@breathelife/types';

import { generateOrphanNodeIdsFromBlueprint } from './generateOrphanNodeIdsFromBlueprint';
import { translateNodeIdToBlueprintId } from './translateNodeIdToBlueprintId';
import { makeAnswerPathTreeFromBlueprint } from './makeAnswerPathTreeFromBlueprint';
import { makeNodeIdToAnswerPathMap } from './makeNodeIdToAnswerPathMap';
import { LinkedBlueprintIds, makeLinkedBlueprintIdMap } from './makeLinkedBlueprintIdMap';
import { AnswersStorage, TranslationError } from './AnswersStorage';

export class BlueprintIdAnswersResolver implements IAnswerResolver {
  private readonly storage: AnswersStorage;
  private readonly orphanNodeIds: Set<string>;
  private readonly nodeIdToBlueprintIdMap: Record<NodeId, BlueprintId>;
  private readonly linkedBlueprintIds: LinkedBlueprintIds;

  private static readonly NODE_REFERENCE_PATH_PREFIX = '';
  private logger: AnswersResolverLogger | undefined;

  private constructor(
    answersStorage: AnswersStorage,
    linkedBlueprintIds: LinkedBlueprintIds,
    orphanNodeIds: Set<string>,
    nodeIdToBlueprintIdMap: Record<string, string>,
    logger?: AnswersResolverLogger,
  ) {
    this.storage = answersStorage;
    this.orphanNodeIds = orphanNodeIds;
    this.linkedBlueprintIds = linkedBlueprintIds;
    this.nodeIdToBlueprintIdMap = nodeIdToBlueprintIdMap;
    this.logger = logger;
  }

  static from(
    blueprint: QuestionnaireBlueprint,
    answers: Answers,
    logger?: AnswersResolverLogger,
  ): BlueprintIdAnswersResolver {
    const nodeIdToBlueprintIdMap = translateNodeIdToBlueprintId(blueprint);
    const orphanNodeIds = generateOrphanNodeIdsFromBlueprint(blueprint);

    const tree = makeAnswerPathTreeFromBlueprint(blueprint);

    const idToAnswerPath = makeNodeIdToAnswerPathMap(tree);
    const linkedBlueprintIds = makeLinkedBlueprintIdMap(blueprint);

    const answersStorage = new AnswersStorage(answers, idToAnswerPath, false, logger);

    return new BlueprintIdAnswersResolver(
      answersStorage,
      linkedBlueprintIds,
      orphanNodeIds,
      nodeIdToBlueprintIdMap,
      logger,
    );
  }

  clone(withAnswers?: VersionedAnswers): BlueprintIdAnswersResolver {
    const clonedStorage = this.storage.clone(withAnswers?.v2);

    return new BlueprintIdAnswersResolver(
      clonedStorage,
      this.linkedBlueprintIds,
      this.orphanNodeIds,
      this.nodeIdToBlueprintIdMap,
      this.logger,
    );
  }

  dump(): VersionedAnswers {
    return new VersionedAnswers({ v1: {}, v2: this.storage.dump() });
  }

  public setAnswers(
    items: { blueprintId: BlueprintId; value: unknown }[],
    blueprintIdInstanceIdentifiers?: BlueprintIdInstanceScope | undefined,
  ): void {
    for (const item of items) {
      this.setAnswer(item.value, item.blueprintId, blueprintIdInstanceIdentifiers);
    }
  }

  public static buildReferencePathKey(value: string | number): string {
    return `${BlueprintIdAnswersResolver.NODE_REFERENCE_PATH_PREFIX}${value}`;
  }

  public knowsId(blueprintId: BlueprintId): boolean {
    return this.storage.knowsId(blueprintId);
  }

  getInstanceId(blueprintId: BlueprintId, scope: InstanceScope): Result<string, SurrogateId | undefined> {
    return this.storage.getInstanceId(blueprintId, scope);
  }

  setInstanceId(
    blueprintId: BlueprintId,
    scope: InstanceScope,
    forceInstanceId?: string | undefined,
  ): Result<string, SurrogateId> {
    return this.storage.setInstanceId(blueprintId, scope, forceInstanceId);
  }

  public getAnswer(blueprintId: BlueprintId, scope?: InstanceScope, skipLeafIdentifier?: boolean): any | undefined {
    return this.storage.getAnswer(blueprintId, scope, skipLeafIdentifier);
  }

  public getCollection(blueprintId: BlueprintId, scope?: InstanceScope): any[] | undefined {
    return this.storage.getCollection(blueprintId, scope);
  }

  public getRepeatedAnswers<T extends string>(
    collectionBlueprintId: BlueprintId,
    blueprintIds: T[],
    scope: InstanceScope,
  ): RepeatedAnswersBySurrogateId<T> | undefined {
    return this.storage.getRepeatedAnswers(collectionBlueprintId, blueprintIds, scope);
  }

  public getRepetitionCount(collectionBlueprintId: BlueprintId, scope: InstanceScope): number | undefined {
    return this.storage.getRepetitionCount(collectionBlueprintId, scope);
  }

  public setAnswer(value: unknown, blueprintId: BlueprintId, scope?: InstanceScope, answersOverride?: Answers): void {
    this.storage.setAnswer(value, blueprintId, scope);

    const linkedIds = this.linkedBlueprintIds.get(blueprintId) || [];
    linkedIds.forEach((linkedId) => {
      this.storage.setAnswer(value, linkedId, scope, answersOverride);
    });
  }

  public unsetAnswer(id: string, scope?: InstanceScope): boolean {
    const linkedIds = this.linkedBlueprintIds.get(id) || [];

    linkedIds.forEach((linkedId) => this.storage.unsetAnswer(linkedId, scope));

    return this.storage.unsetAnswer(id, scope);
  }

  unsetAnswers(nodeInstancesToRemove: NodeInstance[]): NodeInstance[] {
    const removedNodeInstances: NodeInstance[] = [];

    for (const nodeInstance of nodeInstancesToRemove) {
      const { id, scope } = nodeInstance;
      const wasRemoved = this.unsetAnswer(id, scope);

      if (wasRemoved) {
        removedNodeInstances.push(nodeInstance);
      }
    }

    return removedNodeInstances;
  }

  public unsetAnswerSelectOptionId(id: string, optionId: string, scope?: InstanceScope): boolean {
    const linkedIds = this.linkedBlueprintIds.get(id) || [];

    linkedIds.forEach((linkedId) => this.storage.unsetAnswerSelectOptionId(linkedId, optionId, scope));

    return this.storage.unsetAnswerSelectOptionId(id, optionId, scope);
  }

  public withCollectionIdentifier(
    identifiers: InstanceScope,
    collectionId: string,
    collectionInstanceIndex: InstanceIndex,
  ): InstanceScope {
    return this.storage.withCollectionIdentifier(identifiers, collectionInstanceIndex, collectionId);
  }

  public removeUndefinedAnswersFromCollection(id: string, scope?: InstanceScope): boolean {
    return this.storage.removeUndefinedAnswersFromCollection(id, scope);
  }

  //#region  usingNodeId
  private translateInstanceScope(scope: InstanceScope): Result<TranslationError, InstanceScope> {
    const translated: InstanceScope = {};

    const keys = Object.keys(scope);

    for (const nodeId of keys) {
      const blueprintId = this.nodeIdToBlueprintIdMap[nodeId];

      if (!blueprintId) {
        return failure(new TranslationError(nodeId));
      }

      translated[blueprintId] = scope[nodeId];
    }

    return success(translated);
  }

  private setAnswersByNodeId(items: { nodeId: NodeId; value: unknown }[], scope?: InstanceScope | undefined): void {
    for (const item of items) {
      this.setAnswerByNodeId(item.value, item.nodeId, scope);
    }
  }

  private getInstanceIdByNodeId(nodeId: NodeId, scope: InstanceScope): Result<string, SurrogateId | undefined> {
    if (this.orphanNodeIds.has(nodeId)) {
      return failure(`Won't get the surrogate id for "${nodeId} because it's an orphan nodeId.`);
    }

    const blueprintId = this.nodeIdToBlueprintIdMap[nodeId];

    if (!blueprintId) {
      return failure(`Could not find a valid blueprint id for the "${nodeId}" while trying to get the surrogate id.`);
    }

    let translatedCollectionIdentifiers: InstanceScope = {};

    if (scope) {
      const result = this.translateInstanceScope(scope);
      if (result.success === false) {
        return failure(
          `Could not translate the id "${result.error.id}" from the collection instance identifiers while trying to get the surrogate id.`,
        );
      }
      translatedCollectionIdentifiers = result.value;
    }

    return this.storage.getInstanceId(blueprintId, translatedCollectionIdentifiers);
  }

  private setInstanceIdByNodeId(
    nodeId: NodeId,
    scope: InstanceScope,
    forceInstanceId?: SurrogateId | undefined,
  ): Result<string, SurrogateId> {
    if (this.orphanNodeIds.has(nodeId)) {
      return failure(`Won't set the surrogate id for "${nodeId} because it's an orphan nodeId.`);
    }

    const blueprintId = this.nodeIdToBlueprintIdMap[nodeId];

    if (!blueprintId) {
      return failure(`Could not find a valid blueprint id for the "${nodeId}" while trying to set the surrogate id.`);
    }

    let translatedCollectionIdentifiers: InstanceScope = {};

    if (scope) {
      const result = this.translateInstanceScope(scope);
      if (result.success === false) {
        return failure(
          `Could not translate the id "${result.error.id}" from the collection instance identifiers while trying to set the surrogate id.`,
        );
      }
      translatedCollectionIdentifiers = result.value;
    }

    return this.storage.setInstanceId(blueprintId, translatedCollectionIdentifiers, forceInstanceId);
  }

  private knowsIdByNodeId(nodeId: NodeId): boolean {
    if (this.orphanNodeIds.has(nodeId)) {
      return false;
    }

    const blueprintId = this.nodeIdToBlueprintIdMap[nodeId];

    if (!blueprintId) {
      return false;
    }

    return this.storage.knowsId(blueprintId);
  }

  private getAnswerByNodeId(nodeId: NodeId, scope?: InstanceScope, skipLeafIdentifier?: boolean): any | undefined {
    if (this.orphanNodeIds.has(nodeId)) {
      return undefined;
    }

    const blueprintId = this.nodeIdToBlueprintIdMap[nodeId];

    if (!blueprintId) {
      return undefined;
    }

    let translatedCollectionIdentifiers: InstanceScope | undefined;

    if (scope) {
      const result = this.translateInstanceScope(scope);
      if (result.success === false) {
        return undefined;
      }
      translatedCollectionIdentifiers = result.value;
    }

    return this.storage.getAnswer(blueprintId, translatedCollectionIdentifiers, skipLeafIdentifier);
  }

  private getCollectionByNodeId(nodeId: NodeId, scope?: InstanceScope): any[] | undefined {
    if (this.orphanNodeIds.has(nodeId)) {
      return undefined;
    }

    const blueprintId = this.nodeIdToBlueprintIdMap[nodeId];

    if (!blueprintId) {
      return undefined;
    }

    let translatedCollectionIdentifiers: InstanceScope | undefined;

    if (scope) {
      const result = this.translateInstanceScope(scope);
      if (result.success === false) {
        return undefined;
      }
      translatedCollectionIdentifiers = result.value;
    }

    return this.storage.getCollection(blueprintId, translatedCollectionIdentifiers);
  }

  private getRepeatedAnswersByNodeId<T extends string>(
    collectionNodeId: NodeId,
    ids: T[],
    scope: InstanceScope,
  ): RepeatedAnswersBySurrogateId<T> | undefined {
    for (const id of ids) {
      if (this.orphanNodeIds.has(id)) {
        return undefined;
      }
    }

    const collectionBlueprintId = this.nodeIdToBlueprintIdMap[collectionNodeId];

    const translatedCollectionIdentifiers = this.translateInstanceScope(scope);

    const blueprintIdToNodeId: Record<BlueprintId, NodeId> = ids.reduce(
      (acc: Record<string, string>, nodeId: NodeId) => {
        acc[this.nodeIdToBlueprintIdMap[nodeId]] = nodeId;
        return acc;
      },
      {},
    );

    const translatedIds = Object.keys(blueprintIdToNodeId);

    if (
      !collectionBlueprintId ||
      !translatedCollectionIdentifiers.success ||
      translatedIds.some((e) => e === undefined)
    ) {
      return undefined;
    }

    const blueprintIdRepeatedAnswers = this.storage.getRepeatedAnswers(
      collectionBlueprintId,
      translatedIds,
      translatedCollectionIdentifiers.value,
    );

    if (!blueprintIdRepeatedAnswers) {
      return undefined;
    }

    const nodeIdRepeatedAnswers: RepeatedAnswersBySurrogateId<T> = {};
    Object.keys(blueprintIdRepeatedAnswers).forEach((surrogateId: string) => {
      const current = blueprintIdRepeatedAnswers[surrogateId];

      const newAnswers: Partial<Record<string, any>> = {};
      Object.keys(current.answersByNodeId).forEach((blueprintId: string) => {
        const nodeId = blueprintIdToNodeId[blueprintId];
        newAnswers[nodeId] = current.answersByNodeId[blueprintId];
      });

      nodeIdRepeatedAnswers[surrogateId] = {
        answersByNodeId: newAnswers,
        repeatedIndex: current.repeatedIndex,
      };
    });

    return nodeIdRepeatedAnswers;
  }

  private getRepetitionCountByNodeId(collectionNodeId: NodeId, scope: InstanceScope): number | undefined {
    if (this.orphanNodeIds.has(collectionNodeId)) {
      return undefined;
    }

    const collectionBlueprintId = this.nodeIdToBlueprintIdMap[collectionNodeId];

    const translatedCollectionIdentifiers = this.translateInstanceScope(scope);

    if (collectionBlueprintId && translatedCollectionIdentifiers.success) {
      return this.storage.getRepetitionCount(collectionBlueprintId, translatedCollectionIdentifiers.value);
    }
  }

  private setAnswerByNodeId(value: unknown, nodeId: NodeId, scope?: InstanceScope, answersOverride?: Answers): void {
    if (this.orphanNodeIds.has(nodeId)) {
      return;
    }

    const blueprintId = this.nodeIdToBlueprintIdMap[nodeId];

    if (!blueprintId) {
      return;
    }

    let translatedCollectionIdentifiers: InstanceScope | undefined;

    if (scope) {
      const result = this.translateInstanceScope(scope);
      if (result.success === false) {
        return undefined;
      }
      translatedCollectionIdentifiers = result.value;
    }

    this.storage.setAnswer(value, blueprintId, translatedCollectionIdentifiers, answersOverride);
  }

  private unsetAnswerByNodeId(nodeId: NodeId, scope?: InstanceScope): boolean {
    if (this.orphanNodeIds.has(nodeId)) {
      return false;
    }

    const blueprintId = this.nodeIdToBlueprintIdMap[nodeId];

    if (!blueprintId) {
      return false;
    }

    let translatedCollectionIdentifiers: InstanceScope | undefined;

    if (scope) {
      const result = this.translateInstanceScope(scope);
      if (result.success === false) {
        return false;
      }
      translatedCollectionIdentifiers = result.value;
    }

    return this.storage.unsetAnswer(blueprintId, translatedCollectionIdentifiers);
  }

  private unsetAnswersByNodeId(nodeInstancesToRemove: NodeInstance[]): NodeInstance[] {
    const removedNodeInstances: NodeInstance[] = [];

    for (const nodeInstance of nodeInstancesToRemove) {
      const { id, scope } = nodeInstance;
      const wasRemoved = this.unsetAnswerByNodeId(id, scope);

      if (wasRemoved) {
        removedNodeInstances.push(nodeInstance);
      }
    }

    return removedNodeInstances;
  }

  private unsetAnswerSelectOptionIdByNodeId(nodeId: string, optionId: string, scope?: InstanceScope): boolean {
    if (this.orphanNodeIds.has(nodeId)) {
      return false;
    }

    const blueprintId = this.nodeIdToBlueprintIdMap[nodeId];

    if (!blueprintId) {
      return false;
    }

    let translatedCollectionIdentifiers: InstanceScope | undefined;

    if (scope) {
      const result = this.translateInstanceScope(scope);
      if (result.success === false) {
        return false;
      }
      translatedCollectionIdentifiers = result.value;
    }

    return this.storage.unsetAnswerSelectOptionId(blueprintId, optionId, translatedCollectionIdentifiers);
  }

  private withCollectionIdentifierByNodeId(
    identifiers: InstanceScope,
    collectionNodeId: string,
    collectionInstanceIndex: InstanceIndex,
  ): InstanceScope {
    const translatedCollectionNodeId = this.nodeIdToBlueprintIdMap[collectionNodeId];
    const translatedIdentifiers = this.translateInstanceScope(identifiers);

    const blueprintIdToNodeId: Record<string, string> = [...Object.keys(identifiers), collectionNodeId].reduce(
      (acc: Record<string, string>, nodeId: string) => {
        acc[this.nodeIdToBlueprintIdMap[nodeId]] = nodeId;
        return acc;
      },
      {},
    );

    if (!translatedCollectionNodeId || translatedIdentifiers.success === false) {
      throw new Error(`Could not find a blueprintId corresponding to the nodeId ${collectionNodeId}`);
    }

    const blueprintIdCollectionIdentifiers = this.storage.withCollectionIdentifier(
      translatedIdentifiers.value,
      collectionInstanceIndex,
      translatedCollectionNodeId,
    );

    const nodeIdCollectionIdentifiers: Record<string, number> = {};
    Object.keys(blueprintIdCollectionIdentifiers).forEach((blueprintId) => {
      nodeIdCollectionIdentifiers[blueprintIdToNodeId[blueprintId]] = blueprintIdCollectionIdentifiers[blueprintId];
    });

    return nodeIdCollectionIdentifiers;
  }

  private removeUndefinedAnswersFromCollectionByNodeId(nodeId: string, scope?: InstanceScope): boolean {
    if (this.orphanNodeIds.has(nodeId)) {
      return true;
    }

    const blueprintId = this.nodeIdToBlueprintIdMap[nodeId];

    if (!blueprintId) {
      return false;
    }

    let translatedCollectionIdentifiers: InstanceScope | undefined;

    if (scope) {
      const result = this.translateInstanceScope(scope);
      if (result.success === false) {
        return false;
      }
      translatedCollectionIdentifiers = result.value;
    }

    return this.storage.removeUndefinedAnswersFromCollection(blueprintId, translatedCollectionIdentifiers);
  }
  //#endregion

  usingNodeId(): ReturnType<IAnswerResolver['usingNodeId']> {
    return {
      setAnswers: (...args) => this.setAnswersByNodeId(...args),
      getInstanceId: (...args) => this.getInstanceIdByNodeId(...args),
      setInstanceId: (...args) => this.setInstanceIdByNodeId(...args),
      knowsId: (...args) => this.knowsIdByNodeId(...args),
      getAnswer: (...args) => this.getAnswerByNodeId(...args),
      getCollection: (...args) => this.getCollectionByNodeId(...args),
      getRepeatedAnswers: (...args) => this.getRepeatedAnswersByNodeId(...args),
      getRepetitionCount: (...args) => this.getRepetitionCountByNodeId(...args),
      setAnswer: (...args) => this.setAnswerByNodeId(...args),
      unsetAnswer: (...args) => this.unsetAnswerByNodeId(...args),
      unsetAnswers: (...args) => this.unsetAnswersByNodeId(...args),
      unsetAnswerSelectOptionId: (...args) => this.unsetAnswerSelectOptionIdByNodeId(...args),
      withCollectionIdentifier: (...args) => this.withCollectionIdentifierByNodeId(...args),
      removeUndefinedAnswersFromCollection: (...args) => this.removeUndefinedAnswersFromCollectionByNodeId(...args),
    };
  }
}
