import { BlueprintId, NodeId, QuestionnaireBlueprint } from '@breathelife/types';

export type LinkedBlueprintIds = Map<BlueprintId, BlueprintId[]>;

export const makeLinkedBlueprintIdMap = (blueprint: QuestionnaireBlueprint): LinkedBlueprintIds => {
  const nodeIdMap: Map<NodeId, BlueprintId[]> = new Map();

  // First pass we grab keep the blueprintId associated to a single node id.
  blueprint.sectionBlueprints.forEach((section) => {
    section.subsections.forEach((subsection) => {
      subsection.questions.forEach((question) => {
        question.fields.forEach((field) => {
          const nodeId: NodeId | undefined = field.answerNodeId;

          if (nodeId) {
            const blueprintId: BlueprintId = field.id;

            const blueprintIds: BlueprintId[] = nodeIdMap.get(nodeId) || [];

            blueprintIds.push(blueprintId);

            nodeIdMap.set(nodeId, blueprintIds);
          }
        });
      });
    });
  });

  const linkedFields: LinkedBlueprintIds = new Map();

  // Second pass for each node-id we found, that has more than one (not just itself) blueprint id, we generate the linkedFields
  for (const item of nodeIdMap) {
    const blueprintIdsWithSameNodeId = item[1];

    for (let i = 0; i < blueprintIdsWithSameNodeId.length; i++) {
      const linkedWith = [];
      for (let j = 0; j < blueprintIdsWithSameNodeId.length; j++) {
        if (j === i) {
          continue;
        }
        linkedWith.push(blueprintIdsWithSameNodeId[j]);
      }

      if (linkedWith.length > 0) {
        linkedFields.set(blueprintIdsWithSameNodeId[i], linkedWith);
      }
    }
  }

  return linkedFields;
};
