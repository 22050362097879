import { combineLabels, DataDescriptor, DataLabel } from '@breathelife/meta-cruncher';
import { IAnswerResolver } from '@breathelife/types';

import { ExpandedContextVisitor, RepetitionIntervalBoundary } from '../expandedContext/ExpandedContextVisitor';
import {
  BaseNode,
  Field,
  Question,
  QuestionnaireDefinition,
  RepeatableQuestionnaireNode,
  Section,
  SectionGroup,
  Subsection,
} from '../structure';
import { createStack, Stack } from '../utils';
import { Localized } from '../locale';

class DataDescriptorVisitor extends ExpandedContextVisitor {
  private readonly descriptorsResolver: IAnswerResolver;
  private readonly answersResolver: IAnswerResolver;
  private labelStack: Stack<DataLabel>;
  private descriptor: DataDescriptor;

  public constructor(descriptorsResolver: IAnswerResolver, answersResolver: IAnswerResolver) {
    super(RepetitionIntervalBoundary.maxRepetitions);
    this.descriptorsResolver = descriptorsResolver;
    this.answersResolver = answersResolver;
    this.labelStack = createStack();
    this.descriptor = {};
  }

  public visitQuestionnaire(questionnaire: Localized<QuestionnaireDefinition>): DataDescriptor {
    this.descriptor = {};
    this.labelStack.push(DataLabel.Unknown);
    super.visitQuestionnaire(questionnaire);
    this.labelStack.pop();
    return this.descriptor;
  }
  protected visitSectionGroup(sectionGroup: Localized<SectionGroup>): void {
    this.visitBaseNode(sectionGroup, super.visitSectionGroup.bind(this));
  }
  protected visitSection(section: Localized<Section>): void {
    this.visitBaseNode(section, super.visitSection.bind(this));
  }
  protected visitSubsection(subsection: Localized<Subsection>): void {
    this.visitBaseNode(subsection, super.visitSubsection.bind(this));
  }
  protected visitQuestion(question: Localized<Question>): void {
    this.visitBaseNode(question, super.visitQuestion.bind(this));
  }
  protected visitField(field: Localized<Field>): void {
    this.visitBaseNode(field, (field) => {
      if (!this.answersResolver.knowsId(field.blueprintId)) {
        // If there is a missing blueprintId, gracefully degrade instead of failing. Continue building the DataDescriptor
        return;
      }
      const scope = this.repeatedInstanceIdentifiers();

      // TODO: Maybe its broken now, 1st arg was answers=this.descriptor and 4th was answerForPath=this.answers.
      const existingLabel = this.descriptorsResolver.getAnswer(field.blueprintId, scope.byBlueprintId);

      const dataLabels =
        existingLabel && existingLabel !== this.currentActiveDataLabel
          ? combineLabels(existingLabel, this.currentActiveDataLabel)
          : this.labelStack.peek();

      this.descriptorsResolver.setAnswer(
        dataLabels,
        field.blueprintId,
        scope.byBlueprintId,
        this.answersResolver.dump().v1,
      );
    });
  }

  private get currentActiveDataLabel(): DataLabel {
    return this.labelStack.peek() ?? DataLabel.Unknown;
  }

  private visitBaseNode<T extends Localized<BaseNode>>(node: T, continueVisit: (node: T) => void): void {
    if (node.dataLabel) {
      this.labelStack.push(node.dataLabel);
      continueVisit(node);
      this.labelStack.pop();
    } else {
      continueVisit(node);
    }
  }

  protected numberOfRepetitions(repeatableNode: RepeatableQuestionnaireNode): number {
    return (
      this.answersResolver.getRepetitionCount(
        repeatableNode.blueprintId,
        this.repeatedInstanceIdentifiers().byBlueprintId,
      ) ?? 1
    );
  }
}

export function generateAnswersDataDescriptor(
  questionnaire: Localized<QuestionnaireDefinition>,
  descriptorsResolver: IAnswerResolver,
  answersResolver: IAnswerResolver,
): DataDescriptor {
  const visitor = new DataDescriptorVisitor(descriptorsResolver, answersResolver);
  visitor.visitQuestionnaire(questionnaire);

  return descriptorsResolver.dump().v1;
}
