import _ from 'lodash';

import {
  BlueprintConditionValue,
  Localizable,
  SectionBlueprint,
  SectionGroupKey,
  SubsectionBlueprint,
  isSectionBlueprint,
} from '@breathelife/types';

import FieldBuilder from './FieldBuilder';
import NodeBuilder from './NodeBuilder';
import QuestionBuilder from './QuestionBuilder';
import { SeedBlueprintProviders } from './SeedProviders';
import SubsectionBuilder from './SubsectionBuilder';
import { v4 as UUID } from 'uuid';

export default class SectionBuilder extends NodeBuilder<SectionBlueprint> {
  private readonly seedProviders: SeedBlueprintProviders;

  public constructor(seedProviders: SeedBlueprintProviders) {
    super(seedProviders.forSection());
    this.node.sectionGroupKey = 'insuredPeople';
    this.seedProviders = seedProviders;
    this.node.id = UUID();
  }

  public with(nodeBuilder: SubsectionBuilder | QuestionBuilder | FieldBuilder): this {
    if (nodeBuilder instanceof SubsectionBuilder) {
      return this.withSubsections(nodeBuilder);
    }
    return this.withSubsections(this.childBuilder().with(nodeBuilder));
  }

  public withSubsections(subsectionBuilders?: SubsectionBuilder[] | SubsectionBuilder): this {
    const builders = subsectionBuilders ? _.castArray(subsectionBuilders) : [];

    if (builders.length === 0) {
      builders.push(this.childBuilder());
    }

    const subsections = builders.map((builder) => builder.build());
    this.addSubsections(subsections);

    return this.clone();
  }

  public withQuestions(questionBuilders?: QuestionBuilder[] | QuestionBuilder): this {
    this.addSubsections(this.childBuilder().withQuestions(questionBuilders).build());

    return this.clone();
  }

  public withFields(fieldBuilders?: FieldBuilder[] | FieldBuilder): this {
    this.addSubsections(this.childBuilder().withFields(fieldBuilders).build());

    return this.clone();
  }

  private addSubsections(subsections: SubsectionBlueprint | SubsectionBlueprint[]): void {
    if (!this.node.subsections) this.node.subsections = [];

    const subsectionList = _.castArray(subsections);
    this.node.subsections.push(...subsectionList);
  }

  private childBuilder(): SubsectionBuilder {
    return new SubsectionBuilder(this.seedProviders);
  }

  public withProperties(properties: Partial<SectionBlueprint>): this {
    Object.assign(this.node, properties);
    return this.clone();
  }

  public withPartName(partname: string): this {
    this.node.partName = partname;
    return this.clone();
  }

  public withSectionGroupKey(sectionGroupKey: SectionGroupKey): this {
    this.node.sectionGroupKey = sectionGroupKey;
    return this.clone();
  }

  public withTitle(title: Localizable): this {
    this.node.title = title;
    return this.clone();
  }

  public visibleIf(condition: BlueprintConditionValue): this {
    this.node.visible = condition;

    return this.clone();
  }

  public validate(section: SectionBlueprint): section is SectionBlueprint {
    return isSectionBlueprint(section);
  }
}
