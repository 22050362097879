import _ from 'lodash';

import { BlueprintId, BlueprintIdInstanceScope, NodeId, NodeIdInstanceScope } from '@breathelife/types';

import {
  QuestionnaireDefinition,
  SectionGroup,
  Section,
  Subsection,
  Question,
  Field,
  RepeatableQuestionnaireNode,
  isSectionGroupRepeatable,
  isQuestionRepeatable,
  RepeatableSectionGroup,
  RepeatableQuestion,
  isOptionField,
  SelectOption,
  isRepeatableOptionsWithLimits,
} from '../structure';
import { assertUnreachable } from '../utils';
import { Localized } from '../locale';

export enum RepetitionIntervalBoundary {
  minRepetitions = 'minRepetitions',
  maxRepetitions = 'maxRepetitions',
}

interface AnswerPathContext {
  // Repeatable instance paths to this node (most of the time array indices).
  byBlueprintId: BlueprintIdInstanceScope;
  byNodeId: NodeIdInstanceScope;
}

export abstract class ExpandedContextVisitor {
  private readonly repetitionIntervalBoundary: RepetitionIntervalBoundary;
  private answerPathContext: AnswerPathContext[] = [];

  protected constructor(repetitionIntervalBoundary: RepetitionIntervalBoundary) {
    this.repetitionIntervalBoundary = repetitionIntervalBoundary;
  }

  public visitQuestionnaire(questionnaire: Localized<QuestionnaireDefinition>): void {
    if (!questionnaire?.length) return;

    for (const sectionGroup of questionnaire) {
      this.visitSectionGroup(sectionGroup);
    }
  }

  protected visitSectionGroup(sectionGroup: Localized<SectionGroup>): void {
    if (!sectionGroup.sections?.length) return;

    if (isSectionGroupRepeatable(sectionGroup) && isRepeatableOptionsWithLimits(sectionGroup.options)) {
      const numberOfRepetitions: number = this.numberOfRepetitions(sectionGroup);
      _.times(numberOfRepetitions, (repetitionIndex: number) => {
        this.withAnswerPathContextFor(sectionGroup.blueprintId, sectionGroup.nodeId, repetitionIndex, () => {
          this.visitRepeatedSectionGroup(sectionGroup);
        });
      });
    } else {
      for (const section of sectionGroup.sections) {
        this.visitSection(section);
      }
    }
  }

  protected visitRepeatedSectionGroup(sectionGroup: Localized<RepeatableSectionGroup>): void {
    for (const section of sectionGroup.sections) {
      this.visitSection(section);
    }
  }

  protected visitSection(section: Localized<Section>): void {
    if (!section.subsections?.length) return;

    for (const subsection of section.subsections) {
      this.visitSubsection(subsection);
    }
  }

  protected visitSubsection(subsection: Localized<Subsection>): void {
    if (!subsection.questions?.length) return;

    for (const question of subsection.questions) {
      this.visitQuestion(question);
    }
  }

  protected visitQuestion(question: Localized<Question>): void {
    if (!question.fields?.length) return;

    if (isQuestionRepeatable(question)) {
      const numberOfRepetitions: number = this.numberOfRepetitions(question);
      _.times(numberOfRepetitions, (repetitionIndex: number) => {
        this.withAnswerPathContextFor(question.blueprintId, question.nodeId, repetitionIndex, () => {
          this.visitRepeatedQuestion(question, repetitionIndex);
        });
      });
    } else {
      for (const field of question.fields) {
        this.visitField(field);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected visitRepeatedQuestion(question: Localized<RepeatableQuestion>, repetitionIndex?: number): void {
    for (const field of question.fields) {
      this.visitField(field);
    }
  }

  protected visitField(field: Localized<Field>): void {
    if (isOptionField(field)) {
      for (const option of field.options) {
        this.visitOption(option);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected visitOption(option: Localized<SelectOption>): void {}

  protected answerPathContextFor(
    blueprintId: BlueprintId,
    nodeId: NodeId,
    repetitionIndex?: number,
  ): AnswerPathContext {
    const activeContext = this.activeContext();

    let byBlueprintId: BlueprintIdInstanceScope = activeContext ? activeContext.byBlueprintId : {};

    if (typeof repetitionIndex !== 'undefined') {
      byBlueprintId = {
        ...byBlueprintId,
        [blueprintId]: repetitionIndex,
      };
    }

    let byNodeId: NodeIdInstanceScope = activeContext ? activeContext.byNodeId : {};

    if (typeof repetitionIndex !== 'undefined') {
      byNodeId = {
        ...byNodeId,
        [nodeId]: repetitionIndex,
      };
    }

    return {
      byBlueprintId,
      byNodeId,
    };
  }

  private withAnswerPathContextFor(
    blueprintId: BlueprintId,
    nodeId: NodeId,
    repetitionIndex: number,
    callback: () => void,
  ): void {
    this.enterAnswerPathContext(blueprintId, nodeId, repetitionIndex);
    callback();
    this.exitAnswerPathContext();
  }

  private enterAnswerPathContext(blueprintId: BlueprintId, nodeId: NodeId, repetitionIndex: number): void {
    this.answerPathContext.push(this.answerPathContextFor(blueprintId, nodeId, repetitionIndex));
  }

  private exitAnswerPathContext(): void {
    if (this.answerPathContext.length === 0) {
      throw new Error('Invalid questionnaire expansion: Tried to exit an undefined answerPathContext');
    }
    this.answerPathContext.pop();
  }

  protected activeContext(): AnswerPathContext | undefined {
    return this.answerPathContext[this.answerPathContext.length - 1] ?? undefined;
  }

  protected previousContext(): AnswerPathContext | undefined {
    return this.answerPathContext[this.answerPathContext.length - 2] ?? undefined;
  }

  protected numberOfRepetitions(
    repeatableNode: RepeatableQuestionnaireNode | Localized<RepeatableQuestionnaireNode>,
  ): number {
    if (isRepeatableOptionsWithLimits(repeatableNode.options)) {
      switch (this.repetitionIntervalBoundary) {
        case RepetitionIntervalBoundary.minRepetitions:
          return repeatableNode.options.minRepetitions;
        case RepetitionIntervalBoundary.maxRepetitions:
          return repeatableNode.options.maxRepetitions;
      }
      return assertUnreachable(this.repetitionIntervalBoundary);
    }

    return 0;
  }

  protected repeatedInstanceIdentifiers(): { byBlueprintId: BlueprintIdInstanceScope; byNodeId: NodeIdInstanceScope } {
    return this.activeContext() ?? { byBlueprintId: {}, byNodeId: {} };
  }
}
